export const amenityCameraData = {
  outdoorpool_002: {
    cameraTarget: [-0.365399, -0.007, 0.365869],
    cameraPosition: [-0.537999, 0.262, 0.606973],
    test: "Outdoor Pool",
  },
  billiardroom_001: {
    cameraTarget: [-0.124427, -0.007, -0.183661],
    cameraPosition: [-0.07463, 0.262, -0.003883],
    test: "Billiard Room",
  },
  cardroom_002: {
    cameraTarget: [0.217997, -0.007, -0.185635],
    cameraPosition: [0.145145, 0.262, -0.037551],
    test: "Card Room",
  },
  clublounge_001: {
    cameraTarget: [-0.050483, -0.007, -0.190682],
    cameraPosition: [-0.050483, 0.262, -0.004996],
    test: "Club Lounge",
  },
  coworkingspace_001: {
    cameraTarget: [-0.283725, -0.007, -0.192862],
    cameraPosition: [-0.441782, 0.262, -0.060433],
    test: "Coworking Space",
  },
  diningroom_001: {
    cameraTarget: [0.016525, -0.007, -0.165092],
    cameraPosition: [-0.01386, 0.262, -0.005571],
    test: "Dining Room",
  },
  fitnesscentre_002: {
    cameraTarget: [-0.324462, -0.007, -0.018232],
    cameraPosition: [-0.586954, 0.262, -0.218266],
    test: "Fitness Centre",
  },
  generalmain_005: {
    cameraTarget: [-0.050483, -0.007, -0.190682],
    cameraPosition: [-0.050483, 0.262, -0.004996],
    test: "General",
  },
  generalmain_003: {
    cameraTarget: [-0.283725, -0.007, -0.192862],
    cameraPosition: [-0.441782, 0.262, -0.060433],
    test: "General",
  },
  generalmain_004: {
    cameraTarget: [-0.324462, -0.007, -0.018232],
    cameraPosition: [-0.586954, 0.262, -0.218266],
    test: "General",
  },
  lobbylounge_001: {
    cameraTarget: [-0.062813, -0.007, -0.04102],
    cameraPosition: [-0.160721, 0.262, 0.126941],
    test: "Lobby Lounge",
  },
  mainentrance_001: {
    cameraTarget: [-0.052685, -0.007, 0.226536],
    cameraPosition: [0.112744, 0.262, 0.418974],
    test: "Porte Cochere",
  },
  outdoorkitchen_003: {
    cameraTarget: [0.02378, -0.007, -0.298735],
    cameraPosition: [0.146668, 0.262, -0.211864],
    test: "Outdoor Kitchen",
  },
  outdoorlobby_001: {
    cameraTarget: [-0.103707, -0.007, 0.204298],
    cameraPosition: [-0.16237, 0.262, 0.383079],
    test: "Outdoor Lobby",
  },
  cinemascreen_006: {
    cameraTarget: [-0.103707, -0.007, 0.204298],
    cameraPosition: [-0.16237, 0.262, 0.383079],
    test: "Outdoor Lobby",
  },
}
