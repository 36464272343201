//General

import { Link } from "react-router-dom"
import { useRef } from "react"
import styled from "styled-components"

//Components
import LocationTitle from "@components/SVG/LocationTitle"
import { useAnimate } from "@utils/hooks"
import { setTimelineAnimation, timelineAnimation } from "@animations/Location"
import useSocketStore from "@state/useSocketStore"

//Types
interface LocationTypes {}

export default function Location() {
  const gsapRef = useRef()
  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })
  const room = useSocketStore((s) => s.room)
  const emitSocketEvent = useSocketStore((s) => s.emitSocketEvent)
  return (
    <StyledLink
      to="/location/map"
      ref={gsapRef}
      onClick={() =>
        emitSocketEvent("navigation", {
          path: "/location/map",
          room: room,
        })
      }
    >
      <LocationSection>
        <LeftSide id="LeftSide">
          <LeftImageContainer
            className="LeftSide"
            style={{ visibility: "hidden" }}
          >
            <LeftImage
              src="/media/location/location--2.webp"
              alt="The Boardwalk"
            />
          </LeftImageContainer>
          <LeftTopLeft />
          <LeftTopRight />
          <TitleContainer className="LeftSide">
            <LocationTitle />
          </TitleContainer>
          <Subtitle className="LeftSide">Explore Now</Subtitle>
        </LeftSide>

        <RightSide id="RightSide" style={{ visibility: "hidden" }}>
          <RightTopImageContainer>
            <RightTopImage
              src="/media/location/location--1.webp"
              alt="The Boardwalk"
            />
          </RightTopImageContainer>
          <RightBottomVideo
            autoPlay={true}
            loop={true}
            playsInline={true}
            muted={true}
          >
            <source
              src="/media/landing/landing--video--no-audio.mp4"
              type="video/mp4"
            />
          </RightBottomVideo>
        </RightSide>
      </LocationSection>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  height: 100%;
  width: 100%;
  pointer-events: auto;
  cursor: cursor;
`
const LocationSection = styled.section<LocationTypes>`
  .FadeIn,
  .LeftSide {
    visibility: hidden;
  }
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
  /* background-color: ${(props) => props.theme.colors.grey}; */

  & a {
    text-decoration: none;
    color: inherit;
  }

  /* pointer-events: none; */
`

const LeftSide = styled.div<LocationTypes>`
  background-color: ${(props) => props.theme.colors.gold};
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(20, minmax(0, 1fr));
  grid-template-rows: repeat(20, minmax(0, 1fr));
`

const LeftTopLeft = styled.div<LocationTypes>`
  grid-column: 1 / 11;
  grid-row: 1 / 11;
  background-color: ${(props) => props.theme.colors.pink};
`

const LeftTopRight = styled.div<LocationTypes>`
  grid-column: 11 / -1;
  grid-row: 1 / 11;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.grey};
`

const LeftImageContainer = styled.div<LocationTypes>`
  grid-column: 1 / -1;
  grid-row: 4 / 14;
  z-index: 2;
`

const LeftImage = styled.img<LocationTypes>`
  height: 100%;
  width: 100%;
  object-fit: contain;
  place-self: center;
  z-index: 2;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`

const TitleContainer = styled.div<LocationTypes>`
  height: 100%;
  width: 100%;
  grid-column: 8 / -8;
  grid-row: 14 / 18;
  place-self: center;
  z-index: 2;
  display: grid;
  place-items: center;
  & svg {
    height: 100%;
    width: 100%;
  }
`

const Subtitle = styled.div<LocationTypes>`
  grid-column: 1 / -1;
  grid-row: 17 / 18;
  z-index: 2;
  place-self: center;
  /* font-family: "Gilmer Medium"; */
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSizes.sm};
  text-decoration: underline;
  color: ${(props) => props.theme.colors.white};
`

const RightSide = styled.div<LocationTypes>`
  grid-column: 2;
  grid-row: 1;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: repeat(2, minmax(0, 1fr));
`

const RightTopImageContainer = styled.div<LocationTypes>`
  grid-row: 1;
  grid-column: 1;
  overflow: hidden;
  z-index: 10;
`

const RightTopImage = styled.img<LocationTypes>`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const RightBottomVideo = styled.video<LocationTypes>`
  grid-row: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
`
