// import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {}

export const timelineAnimation = ({ timeline, index }) => {
  timeline.fromTo(
    ".SVGTitle path",
    {
      scale: 0.9,
      duration: 0.4,
    },
    {
      scale: 1,
      autoAlpha: 1,
    },
    "<"
  )
  timeline.to(
    ".AccordionCategory",
    {
      autoAlpha: 1,
      duration: 0.4,
      stagger: 0.1,
    },
    "<"
  )
  timeline.to(
    ".FadeIn3",
    {
      autoAlpha: 1,
      duration: 1,
      ease: "power.in",
    },
    "<"
  )
  timeline.to(
    [".FadeIn1", ".FadeIn2"],
    {
      autoAlpha: 1,
      duration: 0.4,
      stagger: 0.1,
    },
    "<"
  )
}
