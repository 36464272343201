import { amenityCameraData } from "@data/amenityCameraData"
import { useGLTF } from "@lib/useGLTF"
import { useTexture } from "@react-three/drei"
import { useThree } from "@react-three/fiber"
import { build } from "vite"

const buildingFileNames = [
  "mesh_006",
  "mesh_004",
  "mesh_005",
  "mesh_000",
  "mesh_001",
  "mesh_003",
  "mesh_007",
]

export const useAssets = () => {
  // AMENITIES ---

  const files = Object.keys(amenityCameraData).map(
    (fileName) => `/models/Amenities/${fileName}.glb`
  ) as any

  const keys = Object.keys(amenityCameraData)
  const elementArray = useGLTF(files)
  const nodes = elementArray.map((element) => element.nodes)

  nodes.forEach((n, i) => {
    n.cameraSettings = amenityCameraData[keys[i]]
    n.name = keys[i]
  })

  // BUIDLING ---

  let buildingFiles = buildingFileNames.map(
    (fileName) => `/models/building/${fileName}.glb`
  ) as any

  const buildingElementArray = useGLTF(buildingFiles)
  let buildingNodes = buildingElementArray.map((element) => element.nodes)

  buildingNodes.forEach((n, i) => {
    // quick hack. we've compressed mesh_000 using Blender so it has a different object structure.
    // this is a quick hack to make it work.
    if (n.Mesh_0) {
      buildingNodes.push({
        mesh_0: n.Mesh_0,
        name: "mesh_000",
      })
      buildingNodes = buildingNodes.filter(
        (node: any) => !node.hasOwnProperty("Mesh_0")
      )
    } else {
      n.name = buildingFileNames[i]
    }
  })

  // Residences ---

  const { nodes: residences } = useGLTF(
    "/models/residencesmodels/residences.glb"
  )

  // SCENE ---

  const gl = useThree((state) => state.gl)
  const scene = useThree((state) => state.scene)
  const camera = useThree((state) => state.camera)

  gl.compile(scene, camera)

  return {
    amenityNodes: nodes,
    buildingNodes: buildingNodes,
    residences: residences,
  }
}
