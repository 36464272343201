import { Vector3 } from "three"
import { create } from "zustand"
import useSocketStore from "./useSocketStore"

export const defaultCameraSettings = {
  position: [10, 20, 10],
  target: [0, 0, 0],
  fov: 75,
  delay: 100,
  duration: 2500,
}

interface InitialState {
  //ViewBoxTypes
  x?: number
  y?: number
  w?: number
  h?: number
  w_?: number
  h_?: number

  mapExpanded: boolean
  setMapExpanded: (v: boolean) => void

  timeline: any
  setTimeline: (v: any) => void

  showShareFavorites: boolean
  setShowShareFavorites: (v: boolean) => void

  viewBox: any
  setViewBox: (v: Partial<InitialState>) => void

  controlsCatcher: any
  setcontrolsCatcher: (v: any) => void

  activePOIName: string
  setActivePOIName: (v: string) => void

  activePOICategory: string
  setActivePOICategory: (v: string) => void

  amenitiesExpanded: boolean
  setAmenitiesExpanded: (v: boolean) => void

  currentAmenityIndex: number
  setCurrentAmenityIndex: (v: number) => void

  favorites: string[]
  setFavorites: (v: string[]) => void

  filteredResults: string[]
  setFilteredResults: (v: string[]) => void

  filterOptions: any
  setFilterOptions: (v: Partial<InitialState>) => void
  setFilterOptionsReset: () => void

  //FilterOptions
  dropModelOpacity?: boolean
  floorFilterOpen?: boolean
  priceFilterOpen?: boolean
  floor?: string[]
  beds?: string[]
  exposure?: string[]
  priceMin?: number
  priceMax?: number
  initialPriceMin?: number
  initialPriceMax?: number

  //Residence Popups
  activeResidence?: string
  residenceContainerOpen?: boolean
  residenceFloorPlanOpen?: boolean
  residenceViewOpen?: boolean
  residencePopups: any
  setResidencePopups: (v: Partial<InitialState>) => void
  receiveMessageFromSocketStore: (message: any) => void
  setResidencePopupsReset: () => void

  formName: string
  setFormName: (v: string) => void
  formEmail: string
  setFormEmail: (v: string) => void
  formBCC: string
  setFormBCC: (v: string) => void

  view360?: any
  show?: boolean
  material?: string | null
  setView360?: (v: Partial<InitialState>) => void
}

export const useStore = create<InitialState>()((set, get) => {
  return {
    viewBox: {
      x: 0,
      y: 0,
      w: 3225,
      h: 2030,
      w_: 3225,
      h_: 2030,
    },

    setViewBox: (v: Partial<InitialState>) =>
      set((state) => ({
        viewBox: {
          ...state.viewBox,
          ...v,
        },
      })),

    controlsCatcher: null,
    setcontrolsCatcher: (v) => set({ controlsCatcher: v }),

    cameraSettings: defaultCameraSettings,

    activePOIName: "",
    setActivePOIName: (v) => set({ activePOIName: v }),

    activePOICategory: "",
    setActivePOICategory: (v) => set({ activePOICategory: v }),

    amenitiesExpanded: false,
    setAmenitiesExpanded: (v) => {
      set({ amenitiesExpanded: v })
    },

    currentAmenityIndex: 0,
    setCurrentAmenityIndex: (v) => set({ currentAmenityIndex: v }),

    filteredResults: [],
    setFilteredResults: (v) => set({ filteredResults: v }),

    favorites: [],
    setFavorites: (v) => set({ favorites: v }),

    timeline: null,
    setTimeline: (v) => set({ timeline: v }),
    mapExpanded: null,
    setMapExpanded: (v) => set({ mapExpanded: v }),

    //Residences
    filterOptions: {
      dropModelOpacity: true,
      floorFilterOpen: false,
      priceFilterOpen: false,
      floor: [],
      beds: [],
      exposure: [],
      priceMin: 800000,
      priceMax: 2000000,
      initialPriceMin: 800000,
      initialPriceMax: 2000000,
    },
    setFilterOptions: (v: Partial<InitialState>) =>
      set((state) => ({
        filterOptions: {
          ...state.filterOptions,
          ...v,
        },
      })),

    setFilterOptionsReset: () =>
      set((state) => ({
        filterOptions: {
          ...state.filterOptions,
          dropModelOpacity: false,
          floorFilterOpen: false,
          priceFilterOpen: false,
          floor: [],
          beds: [],
          exposure: [],
          priceMin: state.filterOptions.initialPriceMin,
          priceMax: state.filterOptions.initialPriceMax,
        },
      })),

    residencePopups: {
      activeResidence: null,
      residenceContainerOpen: false,
      residenceFloorPlanOpen: false,
      residenceViewOpen: false,
    },

    setResidencePopups: (v: Partial<InitialState>) =>
      set((state) => ({
        residencePopups: {
          ...state.residencePopups,
          ...v,
        },
      })),

    setResidencePopupsReset: () =>
      set((state) => ({
        residencePopups: {
          ...state.residencePopups,
          // activeResidence: null,
          residenceContainerOpen: false,
          residenceFloorPlanOpen: false,
          residenceViewOpen: false,
        },
      })),

    // State and actions for Store B
    receiveMessageFromSocketStore: (message) => {
      // Handle the message from Store A
      console.log("STORE NESSAGE")
    },
    showShareFavorites: false,
    setShowShareFavorites: (v) => set({ showShareFavorites: v }),

    formName: "",
    setFormName: (v) => set({ formName: v }),
    formEmail: "",
    setFormEmail: (v) => set({ formEmail: v }),
    formBCC: "",
    setFormBCC: (v) => set({ formBCC: v }),

    view360: {
      show: false,
      material: "Pool",
    },
    setView360: (v: Partial<InitialState>) =>
      set((state) => ({
        view360: {
          ...state.view360,
          ...v,
        },
      })),
  }
})
