export const categoryColors = {
  Restaurants: "blue",
  "Food & Drink": "red",
  Fitness: "green",
  Activities: "pink",
  Transportation: "navy",
  Shopping: "gold",
  Hotels: "purple",
  Beauty: "orange",
}
