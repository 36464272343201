export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="90"
      height="41"
      viewBox="0 0 90 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id="FavoritesLeftArrow"
      {...props}
    >
      <path
        d="M68.9546 40L20.5 40C9.73049 40 1.00005 31.2696 1.00005 20.5C1.00005 9.73045 9.7305 1 20.5001 1L68.9546 1C79.7242 1 88.4546 9.73045 88.4546 20.5C88.4546 31.2696 79.7241 40 68.9546 40Z"
        fill="#799B90"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M23.2929 19.2929C22.9024 19.6834 22.9024 20.3166 23.2929 20.7071L29.6569 27.0711C30.0474 27.4616 30.6805 27.4616 31.0711 27.0711C31.4616 26.6805 31.4616 26.0474 31.0711 25.6569L25.4142 20L31.0711 14.3431C31.4616 13.9526 31.4616 13.3195 31.0711 12.9289C30.6805 12.5384 30.0474 12.5384 29.6569 12.9289L23.2929 19.2929ZM67 19L24 19V21L67 21V19Z"
        fill="white"
      />
    </svg>
  )
}
