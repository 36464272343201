import { DoubleSide, MathUtils } from "three"
import { range } from "lodash-es"
import { Text3D } from "@react-three/drei"
import { useLocation } from "react-router-dom"
import { useTransition } from "@react-spring/core"
import { a } from "@react-spring/three"

function StaticCompass() {
  const { pathname } = useLocation()

  const angles = range(0, 360, 11.25)
  const markerAngles = range(0, 360, 90)

  const offset = Math.PI / 2

  const markers = ["N", "W", "S", "E"]

  const radius = 48
  const thickness = 2

  const transition = useTransition(pathname === "/residences", {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,

      config: { duration: 500, clamp: true },
    },
    leave: {
      opacity: 0,

      config: { duration: 500, clamp: true },
    },
  })

  return transition(
    ({ opacity }, show) =>
      show && (
        <group scale={0.019} position={[0, -0, -0.06]}>
          {angles.map((angle) => (
            <group key={angle} rotation-y={MathUtils.degToRad(angle) + offset}>
              <mesh
                position={[
                  angle % 45 === 0 ? radius + thickness : radius,
                  0,
                  0,
                ]}
                rotation-x={Math.PI / 2}
              >
                <planeGeometry
                  args={[
                    angle % 45 === 0 ? thickness * 2 : thickness,
                    0.5,
                    10,
                    10,
                  ]}
                />
                <a.meshBasicMaterial
                  opacity={opacity}
                  color={angle % 45 === 0 ? "#5e5e5e" : "#7b7b7b"}
                  transparent={true}
                  side={DoubleSide}
                />
              </mesh>
            </group>
          ))}
          {markerAngles.map((angle, i) => (
            <group key={angle} rotation-y={MathUtils.degToRad(angle) + offset}>
              <group
                scale={[2, 2, 0.2]}
                position={[radius + 8, 0, 0.8]}
                rotation-x={-Math.PI / 2}
                rotation-z={Math.PI / 2}
              >
                <Text3D font={"/media/Gilmer_Regular.json"}>
                  {markers[i]}
                  <a.meshBasicMaterial
                    opacity={opacity}
                    color={angle % 45 === 0 ? "#343434" : "#747474"}
                    transparent={true}
                    side={DoubleSide}
                  />
                </Text3D>
              </group>
            </group>
          ))}
          <group
            scale={[2, 2, 0.2]}
            rotation-x={-Math.PI / 2}
            position={[-5, 0, 42]}
          >
            <Text3D font={"/media/Gilmer_Regular.json"}>
              BEACH
              <a.meshBasicMaterial
                color="#5e5e5e"
                opacity={opacity}
                transparent={true}
                side={DoubleSide}
              />
            </Text3D>
          </group>
        </group>
      )
  )
}

export default StaticCompass
