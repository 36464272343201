import AmenityModel from "@canvas_components/Amenities/AmenityModel/AmenityModel"
import ApartmentFinderModel from "@canvas_components/Residences/ApartmentFinderModel/ApartmentFinderModel"
import { useAssets } from "@utils/useAssets"
import Labels from "@canvas_components/Labels/Labels"
import ApartmentFinderBlocks from "@canvas_components/Residences/ApartmentFinderBlocks"

const Assetmanager = () => {
  const { amenityNodes, buildingNodes, residences } = useAssets()

  return (
    <group>
      <AmenityModel nodes={amenityNodes} />
      <Labels />
      <ApartmentFinderModel nodes={buildingNodes} />
      <ApartmentFinderBlocks residences={residences} />
    </group>
  )
}

export default Assetmanager
