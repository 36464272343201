export default function SVG({ className }: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10 0C4.48568 0 0 4.48568 0 10C0 15.5143 4.48568 20 10 20C15.5143 20 20 15.5143 20 10C20 4.48568 15.5143 0 10 0ZM10 1.66667C14.6126 1.66667 18.3333 5.38737 18.3333 10C18.3333 14.6126 14.6126 18.3333 10 18.3333C5.38737 18.3333 1.66667 14.6126 1.66667 10C1.66667 5.38737 5.38737 1.66667 10 1.66667ZM10 7.5C8.61979 7.5 7.5 8.61979 7.5 10C7.5 11.3802 8.61979 12.5 10 12.5C11.3802 12.5 12.5 11.3802 12.5 10C12.5 8.61979 11.3802 7.5 10 7.5Z"
        fill="#D0CBBC"
      />
    </svg>
  )
}
