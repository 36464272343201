import { Link } from "react-router-dom"
import styled from "styled-components"
import PrimaryLogo from "@components/SVG/PrimaryLogo"
import { useRef } from "react"
import { useAnimate } from "@utils/hooks"
import { setTimelineAnimation, timelineAnimation } from "@animations/Error"
import useSocketStore from "@state/useSocketStore"

export default function Landing() {
  const gsapRef = useRef()
  const room = useSocketStore((s) => s.room)
  const emitSocketEvent = useSocketStore((s) => s.emitSocketEvent)

  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })

  return (
    <>
      <LandingWrapper
        id="BGWrapper"
        to="/home"
        ref={gsapRef}
        onClick={() =>
          emitSocketEvent("navigation", {
            path: "/home",
            room: room,
          })
        }
      >
        <Logo>
          <SVGLogo className="SVGLogo" />
        </Logo>
        <SubtitleWrapper>
          <p className="Subtitle">LUXURIOUS OCEANFRONT CONDOMINIUMS</p>
        </SubtitleWrapper>
      </LandingWrapper>
    </>
  )
}

const LandingWrapper = styled(Link)`
  position: absolute;
  height: 110vh;
  height: 110dvh;
  width: 100%;
  margin-top: -10vh;
  margin-top: -10dvh;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows:
    minmax(0, 4fr) minmax(0, 2fr) minmax(0, 0.2fr) minmax(0, 0.5fr)
    minmax(0, 0.2fr) minmax(0, 0.5fr) minmax(0, 3fr);
  text-decoration: none;
  z-index: 12;
  background-color: ${(props) => props.theme.colors.green};
  visibility: hidden;
`

const Video = styled.video`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
  height: 100%;
  place-self: center;
  object-fit: cover;
  z-index: 13;
  opacity: 0.8;
`

const Logo = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  place-self: center;
  z-index: 13;
  display: grid;
  place-items: center;
  /* padding: 2rem; */

  & svg {
    height: auto;
    width: 60%;
  }

  & path {
    fill: ${(props) => props.theme.colors.white};
    /* visibility: hidden; */
  }
`

const SVGLogo = styled(PrimaryLogo)``

const SubtitleWrapper = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Gilmer", sans-serif;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.sm};
  grid-column: 1 / -1;
  grid-row: 4;
  display: grid;
  place-items: center;
  place-self: center;
  z-index: 13;
`

const Subtitle = styled.h1`
  .char {
    /* visibility: hidden; */
    margin: 0 0.1rem;
  }
`

const EnterButton = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: clamp(1rem, 0.8vw, 2rem);
  grid-column: 1 / -1;
  grid-row: 6;
  place-self: center;
  z-index: 13;
  font-family: "Gilmer", sans-serif;
  font-weight: 400;
  .char {
    /* visibility: hidden; */
  }
`
