export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
      style={{ pointerEvents: "none" }}
    >
      <path
        d="M11.3926 6.42399C11.6961 6.12056 11.6961 5.63135 11.3926 5.32792L6.24667 0.181959C6.00517 -0.0595482 5.61504 -0.0595481 5.37353 0.181959L0.227574 5.32792C-0.0758581 5.63135 -0.075858 6.12056 0.227574 6.42399C0.531006 6.72742 1.02021 6.72742 1.32364 6.42399L5.8132 1.94063L10.3028 6.43018C10.6 6.72742 11.0954 6.72742 11.3926 6.42399Z"
        fill="#CEAF24"
      />
      <path
        d="M0.232364 14.2284C-0.0710678 14.5318 -0.0710679 15.021 0.232364 15.3244L5.37832 20.4704C5.61983 20.7119 6.00996 20.7119 6.25147 20.4704L11.3974 15.3244C11.7009 15.021 11.7009 14.5318 11.3974 14.2284C11.094 13.9249 10.6048 13.9249 10.3014 14.2284L5.8118 18.7117L1.32224 14.2222C1.025 13.9249 0.529604 13.9249 0.232364 14.2284Z"
        fill="#CEAF24"
      />
    </svg>
  )
}
