//General Imports
import styled from "styled-components"
import { useStore } from "@state/store"

//Components
import * as Dialog from "@radix-ui/react-dialog"
import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"

//SVGs
import Close from "@components/SVG/Residences/SideBarIcons/Cross"
import View from "@components/SVG/Residences/SideBarIcons/View"
import LeftArrow from "@components/SVG/LeftArrow"
import RightArrow from "@components/SVG/RightArrow"
import Favourite from "@components/SVG/Residences/SideBarIcons/Favourite"
import FloorPlanPopupCross from "@components/SVG/Residences/FloorPlanPopup/Close"
import { floorPlanIndex } from "@data/floorPlanIndex"

//SVGs - Floor Plans
// import * as FloorPlans from "@components/SVG/Residences/FloorPlans"

//SVGs - Floor Plans
import * as FloorPlates from "@components/SVG/Residences/FloorPlates"

//Data
import residenceFloorPlanColors from "@data/residenceFloorPlanColors.json"
import exposureFacing from "@data/exposureFacing.json"
import { useEffect, useRef, useState } from "react"

//Types
type FloorPlanTypeComponentProps = {
  uid?: string
}

type ResidencePopupTypes = {
  residencecontaineropen?: boolean
  residencefloorplanopen?: boolean
  residenceviewopen?: boolean
}

interface Apartment {
  floorPlanType: string
  id: string
  buildingNumber: string
  price: number
  floor: string
  exposure: string[]
  interiorSquareFootage: number
  terraceSquareFootage: number
  bed: string
  bath: string
  realEstateTax?: number
  commonCharges?: number
}

export default function ResidencePopup({ uid }: FloorPlanTypeComponentProps) {
  const LeftContainerRef = useRef(null)
  const RightContainerRef = useRef(null)
  const isIpad = useDeviceStore((state) => state.isIpad)
  const viewsSrc = `/media/residences/views/${uid}.webp`
  //State imports
  const apartments = useDeviceStore((state) => state.apartments)
  const residencePopups = useSocketStore((state) => state.residencePopups)
  const syncState = useSocketStore((state) => state.syncState)
  const favorites = useSocketStore((state) => state.favorites)
  const socket = useSocketStore((state) => state.socket)
  const room = useSocketStore((state) => state.room)
  const emitSocketEvent = useSocketStore((state) => state.emitSocketEvent)
  const timeline = useStore((state) => state.timeline)
  const hasAux = useDeviceStore((s) => s.hasAux)
  const showPrice = useSocketStore((s) => s.showPrice)

  const matchedApartment = apartments.find((residence) => {
    const residenceUid =
      residence["building"] === "Tower A"
        ? `${residence["id"]}A`
        : residence["building"] === "Tower B"
        ? `${residence["id"]}B`
        : null

    return residenceUid === uid
  })

  const isAvailable = matchedApartment["isAvailable"] || false
  // const showPrice = isAvailable || isIpad
  const floorPlanType = matchedApartment["floorPlanType"] || "A-01"
  const id = matchedApartment["id"] || "Example"
  const buildingNumber = matchedApartment["buildingNumber"] || "100"
  const price = matchedApartment["price"] || 1000000
  let floor = matchedApartment["floor"] || "4"
  if (floor === "3") {
    floor = "Lanai"
  }
  const exposure = matchedApartment["exposure"] || []
  const interiorSquareFootage =
    matchedApartment["interiorSquareFootage"] || 1000
  const terraceSquareFootage = matchedApartment["terraceSquareFootage"] || 200
  const bed = matchedApartment["bed"] || "1"
  const bath = matchedApartment["bath"] || "1"
  const realEstateTax = matchedApartment["realEstateTax"] || null
  const commonCharges = matchedApartment["commonCharges"] || null

  //Initialise a color variable
  let color: string

  //Find out color branding for unit's number of beds
  residenceFloorPlanColors.some((obj) => {
    // Loop through the array of objects
    if (String(bed) in obj) {
      // Check if String(bed) is a key in the current object
      color = obj[String(bed)] // Assign the corresponding color value to the color variable
      return true // Return true to exit the loop
    } else {
      color = "green"
      return false
    }
  })

  //If Penthouse, set to grey and override bedroom colors
  if (String(floor) === "PH") {
    color = "grey"
  }

  //Get names for floorplan + floorplate components
  // const getFloorPlanName = (uid) => {
  //   const floorPlanName = Object.keys(floorPlanIndex).find((plan) =>
  //     floorPlanIndex[plan].includes(uid)
  //   )
  //   return floorPlanName
  // }

  // let FloorPlanComponent = FloorPlans[getFloorPlanName(uid)]

  const matchingKey = Object.keys(floorPlanIndex).find((key) =>
    floorPlanIndex[key].includes(uid)
  )
  const floorplanSrc = `/media/residences/FloorPlanWEBPs/${floorPlanIndex[matchingKey]}.webp`

  // let FloorPlanName = String(floorPlanType.replace("-", ""))
  // let FloorPlanComponent = FloorPlans[FloorPlanName]
  // let FloorPlateName = String(floorPlanType.replace("-", ""))
  // let FloorPlateComponent = FloorPlates[FloorPlateName]

  //Map through the exposure to pull out North, East etc from [N, E]
  const facingDirections = exposure.map((letter) => exposureFacing[0][letter])
  const facingString = facingDirections.join(", ")

  //Toggle Popup
  function toggleResidencePopup() {
    updatePopupOptions({
      residenceContainerOpen: !residencePopups.residenceContainerOpen,
    })
  }
  function handleClose() {
    syncState("activeUID", null)
    if (LeftContainerRef) {
      LeftContainerRef.current.style.opacity = 0
    }
    if (RightContainerRef) {
      RightContainerRef.current.style.opacity = 0
    }
  }
  useEffect(() => {
    !residencePopups.residenceContainerOpen &&
      updatePopupOptions({
        // activeResidence: null,
        residenceContainerOpen: false,
        residenceFloorPlanOpen: false,
        residenceViewOpen: false,
      })
  }, [residencePopups.residenceContainerOpen])

  useEffect(() => {
    if (!residencePopups.residenceContainerOpen) {
      setTimeout(() => syncState("showPrice", false), 2000)
    }
  }, [residencePopups.residenceContainerOpen])

  useEffect(() => {
    return () => {
      syncState("residencePoupups", {
        // activeResidence: null,
        residenceContainerOpen: false,
        residenceFloorPlanOpen: false,
        residenceViewOpen: false,
      })
      syncState("activeUID", null)
    }
  }, [])

  useEffect(() => {
    if (!residencePopups.residenceContainerOpen) {
      updatePopupOptions({
        residenceViewOpen: false,
        residenceFloorPlanOpen: false,
      })
    }
  }, [residencePopups.residenceContainerOpen])

  const [svgContent, setSvgContent] = useState(null)

  function updatePopupOptions(newValues) {
    const updatedPopupOptions = { ...residencePopups, ...newValues }
    syncState("residencePopups", updatedPopupOptions)
  }

  function handleGalleryChange(dir, e) {
    e.stopPropagation()
    if (socket) {
      emitSocketEvent("galleryChange", dir)
    }
  }

  return (
    <DialogRoot
      open={residencePopups.residenceContainerOpen}
      onOpenChange={toggleResidencePopup}
    >
      <Dialog.Trigger />
      <Dialog.Overlay />
      <DialogContent asChild>
        <ResidenceContainer
          color={color}
          residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
          residenceviewopen={residencePopups.residenceViewOpen}
          id="ResidenceContainer"
        >
          <LeftContainer
            ref={LeftContainerRef}
            id="LeftContainer"
            onClick={() => {
              !residencePopups.residenceViewOpen &&
                updatePopupOptions({
                  residenceFloorPlanOpen:
                    !residencePopups.residenceFloorPlanOpen,
                })
            }}
            residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
            residenceviewopen={residencePopups.residenceViewOpen}
          >
            <FloorPlanImageContainer
              residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
            >
              <img alt="The Boardwalk" src={floorplanSrc} />
            </FloorPlanImageContainer>
            {/* <ResidenceLocationImageContainer
              residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
            >
              <FloorPlateComponent
                bed={String(bed)}
                floor={String(floor)}
                color={color}
                // click={() => void 0}
              />
            </ResidenceLocationImageContainer> */}
          </LeftContainer>
          <ViewImageContainer
            residenceviewopen={residencePopups.residenceViewOpen}
            onClick={() => {
              // alert("click")
              residencePopups.residenceViewOpen &&
                updatePopupOptions({
                  residenceViewOpen: !residencePopups.residenceViewOpen,
                })
            }}
          >
            <img alt="The Boardwalk" src={viewsSrc} />
          </ViewImageContainer>
          <RightContainer
            ref={RightContainerRef}
            id="RightContainer"
            residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
          >
            <TopLeftColour
              residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
              residenceviewopen={residencePopups.residenceViewOpen}
              color={color}
            />
            <TopRightColour
              residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
              residenceviewopen={residencePopups.residenceViewOpen}
              color={color}
            />
            <ApartmentImageContainer
              onClick={() => {
                !residencePopups.residenceFloorPlanOpen &&
                  updatePopupOptions({
                    residenceViewOpen: !residencePopups.residenceViewOpen,
                  })
              }}
              residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
              residenceviewopen={residencePopups.residenceViewOpen}
            >
              <img
                alt="The Boardwalk"
                src="/media/residences/placeholder.webp"
              />
            </ApartmentImageContainer>
            <SidebarContainer
              residenceviewopen={residencePopups.residenceViewOpen}
              residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
            >
              <Dialog.Close asChild>
                <CloseContainer>
                  <Close onClick={() => handleClose()} />
                </CloseContainer>
              </Dialog.Close>

              <ViewsContainer>
                <View
                  onClick={() => {
                    updatePopupOptions({
                      residenceViewOpen: !residencePopups.residenceViewOpen,
                    })
                    // setResidencePopups({
                    //   residenceViewOpen: !residencePopups.residenceViewOpen,
                    // })
                  }}
                />
              </ViewsContainer>

              <FavouriteContainer>
                <Favourite
                  active={favorites.includes(uid) ? true : false}
                  onClick={() => {
                    syncState(
                      "favorites",
                      favorites.includes(uid)
                        ? favorites.filter((item) => item !== uid)
                        : [...favorites, uid]
                    )
                    // setFavorites(
                    //   favorites.includes(uid)
                    //     ? favorites.filter((item) => item !== uid)
                    //     : [...favorites, uid]
                    // )
                    //
                  }}
                />
              </FavouriteContainer>
            </SidebarContainer>
            <ResidenceDetailsContainer
              onClick={() => syncState("showPrice", !showPrice)}
              residenceviewopen={residencePopups.residenceViewOpen}
              residencefloorplanopen={residencePopups.residenceFloorPlanOpen}
            >
              <ApartmentNumber>Residence {id}</ApartmentNumber>
              <AddressPrice>
                {Number(buildingNumber)} Boardwalk{" "}
                {(showPrice || isIpad) &&
                  `• $${Number(price / 100).toLocaleString("en-US")}`}
                <br />
                {!isIpad
                  ? ""
                  : showPrice
                  ? `(tap to hide price on TV)`
                  : `(tap to show price on TV)`}
                <br />
              </AddressPrice>
              <LeftDetailsContainer>
                <p>Floor {floor}</p>
                <p>
                  {interiorSquareFootage.toLocaleString("en-US")} interior sq.
                  ft
                </p>
                <p>
                  {terraceSquareFootage.toLocaleString("en-US")} terrace sq. ft
                </p>
              </LeftDetailsContainer>
              <RightDetailsContainer>
                <p>{facingString} Facing</p>
                <p>
                  {Number(bed).toLocaleString("en-US")} Bed
                  {Number(bed) > 1 ? "s" : ""}
                </p>
                <p>
                  {Number(bath) % 1 === 0
                    ? `${Number(bath).toLocaleString("en-US")} Bath${
                        Number(bath) > 1 ? "s" : ""
                      }`
                    : `${Math.floor(Number(bath))} Bath${
                        Number(bath) > 1 ? "s" : ""
                      }, 1 Powder`}
                </p>
              </RightDetailsContainer>
            </ResidenceDetailsContainer>
          </RightContainer>
          <FloorPlanPopupCross
            className="FloorPlanPopupCross"
            onClick={() => {
              residencePopups.residenceFloorPlanOpen
                ? updatePopupOptions({
                    residenceFloorPlanOpen:
                      !residencePopups.residenceFloorPlanOpen,
                  })
                : residencePopups.residenceViewOpen
                ? updatePopupOptions({
                    residenceViewOpen: !residencePopups.residenceViewOpen,
                  })
                : null
            }}
          />
          {isIpad &&
            location.pathname === "/residences" &&
            room === "room1" && (
              <Controls>
                <button
                  onClick={(e) => {
                    handleGalleryChange(false, e)
                  }}
                >
                  <LeftArrow click={() => null} />
                </button>
                <button
                  onClick={(e) => {
                    handleGalleryChange(true, e)
                  }}
                >
                  <RightArrow click={() => null} />
                </button>
              </Controls>
            )}
        </ResidenceContainer>
      </DialogContent>
    </DialogRoot>
  )
}

const DialogRoot = styled(Dialog.Root)`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

const DialogContent = styled(Dialog.Content)`
  &[data-state="open"] {
    animation: fadeIn 1s cubic-bezier(1, -0.09, 0.46, 1);
  }
  &[data-state="closed"] {
    animation: fadeOut 0.3s 1s;
  }
`

const ResidenceContainer = styled.div<ResidencePopupTypes>`
  /* grid-column: 1 / -1;
  grid-row: 1 / -1; */
  z-index: 14;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  /* display: grid; */
  /* grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); */
  /* ${(props) =>
    props.residencefloorplanopen
      ? "minmax(0, 1fr) minmax(0, 0fr)"
      : props.residenceviewopen
      ? "minmax(0, 0fr) minmax(0, 1fr)"
      : "minmax(0, 1fr) minmax(0, 1fr)"}; */
  /* transition: grid-template-columns 1000ms; */
  background-color: ${(props) => props.theme.colors[props.color]};
  overflow: hidden;
  font-family: "Gilmer Light", sans-serif;
  font-size: 1vw;
  color: ${(props) => props.theme.colors.white};

  .FloorPlanPopupCross {
    transform: scale(1.3);
    position: absolute;
    right: 8rem;
    top: 8rem;
    width: 3rem;
    height: 3rem;
    z-index: 12;
    opacity: ${(props) =>
      props.residencefloorplanopen ? "1" : props.residenceviewopen ? "1" : "0"};
    pointer-events: ${(props) =>
      props.residencefloorplanopen
        ? "auto"
        : props.residenceviewopen
        ? "auto"
        : "none"};
    transition: 0.4s cubic-bezier(0.57, 0.04, 0.49, 1.01);
    transform: ${(props) =>
      props.residencefloorplanopen
        ? "translateY(0px)"
        : props.residenceviewopen
        ? "translateY(0px)"
        : "translateY(30px)"};
    cursor: pointer;
    svg {
      height: 100%;
      width: 100%;
    }
  }
`
const LeftContainer = styled.div<ResidencePopupTypes>`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => (props.residencefloorplanopen ? "100%" : "50%")};
  /* transition: width 0.1s ease-in; */
  opacity: ${(props) =>
    props.residencefloorplanopen ? "1" : props.residenceviewopen ? "0" : "1"};
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  pointer-events: ${(props) => (props.residenceviewopen ? "none" : "auto")};
  grid-template-rows: 
  /* minmax(0, 1fr) minmax(0, 4fr) minmax(0, 1fr) minmax(
      0,
      0.1fr
    ); */ ${(props) =>
    props.residencefloorplanopen
      ? "minmax(0, 0fr) minmax(0, 1fr) minmax(0, 0fr) minmax(0, 0fr)"
      : "minmax(0, 1fr) minmax(0, 4fr) minmax(0, 1fr) minmax(0, 0.1fr)"};
  /* /* transition: 0.6s cubic-bezier(0.69, 0.07, 0.2, 0.98); */
  row-gap: 2vw;
  overflow: hidden;
  color: ${(props) => props.theme.colors.green};
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  transition: opacity 1s ease-in;
`
const FloorPlanImageContainer = styled.div<ResidencePopupTypes>`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 10;
  background-color: white;
  overflow: hidden;
  z-index: 11;

  & img {
    z-index: 12;
    object-fit: contain;
    height: 90%;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
    touch-action: pinch-zoom !important;
    -webkit-touch-action: pinch-zoom !important;
    pointer-events: auto;
  }
`
const ResidenceLocationImageContainer = styled.div<ResidencePopupTypes>`
  grid-row: 3;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
  }
`
const RightContainer = styled.div<ResidencePopupTypes>`
  /* grid-column: 2;
  grid-row: 1 / -1; */
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: ${(props) => (props.residenceviewopen ? "100%" : "50%")};
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-template-rows: repeat(10, minmax(0, 1fr));
  row-gap: 2vw;
  overflow: hidden;
  z-index: 10;
  pointer-events: ${(props) =>
    props.residencefloorplanopen || props.residenceviewopen ? "none" : "auto"};
  transition: opacity 1s ease-in;
`
const TopLeftColour = styled.div<ResidencePopupTypes>`
  grid-column: 1 / 6;
  grid-row: 1 / 6;
  background-color: ${(props) => props.theme.colors[props.color]};
  mix-blend-mode: color-burn;
  opacity: 0.2;
  opacity: ${(props) =>
    props.residencefloorplanopen ? "0" : props.residenceviewopen ? "0" : "0.2"};
  /* transition: ${(props) =>
    props.residencefloorplanopen
      ? "0.2s ease-in"
      : props.residenceviewopen
      ? "0.2s ease-in"
      : "0.8s ease-out"}; */
`
const TopRightColour = styled.div<ResidencePopupTypes>`
  grid-column: 6 / -1;
  grid-row: 1 / 6;
  mix-blend-mode: color-burn;
  background-color: ${(props) => props.theme.colors[props.color]};
  opacity: ${(props) =>
    props.residencefloorplanopen ? "0" : props.residenceviewopen ? "0" : "0.4"};
  /* transition: ${(props) =>
    props.residencefloorplanopen
      ? "0.2s ease-in"
      : props.residenceviewopen
      ? "0.2s ease-in"
      : "0.8s ease-out"}; */
`

const ApartmentImageContainer = styled.div<ResidencePopupTypes>`
  grid-row: 3 / -4;
  grid-column: 4 / -4;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 10;
  overflow: hidden;
  opacity: ${(props) =>
    props.residencefloorplanopen ? "0" : props.residenceviewopen ? "0" : "1"};
  /* transition: ${(props) =>
    props.residencefloorplanopen
      ? "0.2s"
      : props.residenceviewopen
      ? "0.1s"
      : "0.1s"}; */

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }
`

const SidebarContainer = styled.div<ResidencePopupTypes>`
  grid-column: 8;
  grid-row: 3 / 5;
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  z-index: 14;
  justify-items: left;
  align-items: center;
  opacity: ${(props) =>
    props.residencefloorplanopen ? "0" : props.residenceviewopen ? "0" : "1"};
  /* transition: ${(props) =>
    props.residencefloorplanopen
      ? "0.2s ease-in"
      : props.residenceviewopen
      ? "0.2s ease-in"
      : "0.8s ease-out"}; */
  svg {
    height: 80%;
    width: 80%;
    cursor: pointer;
    z-index: 14;
  }
`

const CloseContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-row: 1;
  display: grid;
  place-items: center;
  svg {
    height: 30%;
    width: 30%;
  }
`

const ViewsContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-row: 2;
  display: grid;
  place-items: center;
`

const FavouriteContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-row: 3;
  display: grid;
  place-items: center;
`
const ResidenceDetailsContainer = styled.div<ResidencePopupTypes>`
  grid-column: 3 / -3;
  grid-row: -4 / -1;
  display: grid;
  grid-template-columns: minmax(0, 0.3fr) repeat(2, minmax(0, 1fr)) minmax(
      0,
      0.3fr
    );
  grid-template-rows:
    minmax(0, 2fr) minmax(0, 2fr) minmax(0, 0.3fr) minmax(0, 2.5fr)
    minmax(0, 2fr);
  row-gap: 0.3vw;

  text-align: center;
  opacity: ${(props) =>
    props.residencefloorplanopen ? "0" : props.residenceviewopen ? "0" : "1"};
  /* transition: ${(props) =>
    props.residencefloorplanopen
      ? "0.05s"
      : props.residenceviewopen
      ? "0.05s"
      : "1.2s cubic-bezier(.95,.01,.95,.62)"}; */
  /* transition-delay: ${(props) =>
    props.residencefloorplanopen
      ? "0s"
      : props.residenceviewopen
      ? "0"
      : "0.5s"}; */
`
const ApartmentNumber = styled.p`
  font-family: "Dreamer Bold", serif;
  font-weight: 400;
  font-size: 2.8vw;
  grid-column: 1 / -1;
  grid-row: 1;
  place-self: center;
`
const AddressPrice = styled.p`
  text-transform: uppercase;
  grid-column: 1 / -1;
  grid-row: 2;
  font-family: "Gilmer", sans-serif;
  letter-spacing: 0.2vw;
  place-self: center;
  text-align: center;
`
const LeftDetailsContainer = styled.div`
  grid-column: 1 / 3;
  grid-row: 4;
  place-self: center;
  font-size: 1vw;
  line-height: 1.4vw;
  margin-right: 1vw;
`
const RightDetailsContainer = styled.div`
  place-self: center;
  grid-column: 3 / -1;
  grid-row: 4;
  font-size: 1vw;
  line-height: 1.4vw;
  margin-left: 1vw;
`

const ViewImageContainer = styled.div<ResidencePopupTypes>`
  /* grid-row: ${(props) => (props.residenceviewopen ? "1 / -1" : "3 / -4")}; */
  /* grid-column: ${(props) =>
    props.residenceviewopen ? "1 / -1" : "4 / -4"}; */
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 12;
  overflow: hidden;
  opacity: ${(props) => (props.residenceviewopen ? 1 : 0)};
  /* transition: ${(props) => (props.residenceviewopen ? "0.5s" : "0.2s")}; */
  pointer-events: ${(props) => (props.residenceviewopen ? "auto" : "none")};

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    cursor: pointer;
    pointer-events: ${(props) => (props.residenceviewopen ? "auto" : "none")};
    z-index: 12;
  }
`

const Controls = styled.div`
  width: 15%;
  height: 5%;
  position: absolute;
  z-index: 12;
  bottom: 2%;
  right: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10%;

  & button {
    cursor: pointer;
  }

  & svg {
    transform: scale(0.8);
    & path:nth-of-type(1) {
      fill: none;
    }
  }
`
