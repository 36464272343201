// import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {}

export const timelineAnimation = ({ timeline, index }) => {
  // timeline.to(".FadeInStagger", {
  //   // delay: 1,
  //   duration: 1,
  //   autoAlpha: 1,
  //   stagger: 0.3,
  //   ease: "power1.inOut",
  // })
  timeline.to(
    ".FadeInStagger2",
    {
      autoAlpha: 1,
      stagger: 0.2,
      delay: 1,
      duration: 1,
      ease: "power1.inOut",
    },
    "<"
  )
}
