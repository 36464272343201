//General Imports
import styled from "styled-components"

//Components
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionTrigger,
  AccordionContent,
} from "@radix-ui/react-accordion"
import LocationAccordionIcon from "@components/SVG/LocationAccordionArrow"
import POIBulletPoint from "@components/SVG/POIBulletPoint"

//Data
import locationsData from "@data/LocationPOIsFinal.json"
import { useStore } from "@state/store"
import { useEffect, useRef } from "react"
import globalStyles from "../../style.global"
import { categoryColors } from "@data/categoryColors"
import useSocketStore from "@state/useSocketStore"

//Types
type LocationData = {
  id: number
  key: number
  index: number
  Category: string
  CategoryID: number
  Items: {
    PointOfInterest: string
    Address: {
      Line1: string
      Line2: string
      City: string
      ZIP: string
    }
    Distance: {
      Value: number
      Measurement: string
    }
  }[]
}

interface Location2Types {
  index?: number
  categoryVar?: string
  color?: string
  total?: number
}

export default function LocationsAccordion() {
  const activePOIName = useSocketStore((state) => state.activePOIName)
  const activePOICategory = useSocketStore((state) => state.activePOICategory)
  const viewBox = useStore((state) => state.viewBox)
  const timeline = useStore((state) => state.timeline)
  const mapExpanded = useSocketStore((state) => state.mapExpanded)
  const syncState = useSocketStore((state) => state.syncState)

  const scrollToPOIRef = useRef(null)
  const scrollToCategoryRef = useRef(null)

  function handleZoom({ id }) {
    String(id) === String(activePOICategory)
      ? syncState("mapExpanded", false)
      : syncState("mapExpanded", true)
  }

  useEffect(() => {
    if (scrollToPOIRef.current && activePOIName !== "") {
      const behavior = "smooth"
      let block = "nearest"
      let inline = "nearest"
      scrollToPOIRef.current.scrollIntoView({ behavior, block, inline })
    }
  }, [activePOIName])

  useEffect(() => {
    if (scrollToCategoryRef.current && activePOICategory !== "") {
      const behavior = "smooth"
      let block = "nearest"
      let inline = "nearest"
      scrollToCategoryRef.current.scrollIntoView({ behavior, block, inline })
    }
  }, [activePOICategory])

  const handleAccordionCategoryClick = (value) => {
    syncState("activePOICategory", String(value))
  }

  const handleCategoryScroll = ({ id }) => {
    const container = document.getElementById("AccordionCategory")
    const parentContainer = document.getElementById(
      "CategoryAccordionContainer"
    )
    container.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const handleAccordionPOINameClick = (value) => {
    syncState("activePOIName", String(value))
  }

  return (
    <StyledCategoryAccordion
      type="single"
      collapsible
      value={String(activePOICategory)}
      onValueChange={handleAccordionCategoryClick}
      id="CategoryAccordionContainer"
    >
      {locationsData.map((category) => (
        <StyledCategoryAccordionItem
          key={category.CategoryID}
          value={String(category.CategoryID)}
          index={category.CategoryID}
          color={categoryColors[category.Category]}
          className="AccordionCategory"
          id="AccordionCategory"
          ref={
            activePOIName === String(category.CategoryID)
              ? scrollToCategoryRef
              : null
          }
        >
          <StyledAccordionHeader asChild>
            <StyledAccordionTrigger asChild>
              <CategoryItemGrid
                id={`categoryDiv${category.CategoryID}`}
                onClick={() => {
                  handleZoom({ id: category.CategoryID })
                  // handleCategoryScroll({
                  //   id: `categoryDiv${category.CategoryID}`,
                  // })
                }}
              >
                <CategoryIconContainer>
                  <LocationAccordionIcon />
                </CategoryIconContainer>
                <CategoryTextContainer>
                  <p>{category.Category}</p>
                </CategoryTextContainer>
                <CategoryLineBreak id="CategoryLineBreak" />
              </CategoryItemGrid>
            </StyledAccordionTrigger>
          </StyledAccordionHeader>
          <StyledCategoryAccordionContent
            id="CategoryAccordionContent"
            index={category.CategoryID}
            total={category.PointsOfInterest.length}
          >
            <StyledPOIAccordion
              type="single"
              collapsible
              value={activePOIName}
              onValueChange={handleAccordionPOINameClick}
              total={category.PointsOfInterest.length}
            >
              {category.PointsOfInterest.map((poi, index) => (
                <StyledPOIAccordionItem
                  className="AccordionItem"
                  key={poi.PointOfInterestID}
                  index={category.CategoryID}
                  color={categoryColors[category.Category]}
                  value={String(
                    category.CategoryID + "_" + poi.PointOfInterestID
                  )}
                  ref={
                    activePOIName ===
                    String(category.CategoryID + "_" + poi.PointOfInterestID)
                      ? scrollToPOIRef
                      : null
                  }
                >
                  <StyledPOIAccordionHeader asChild>
                    <StyledAccordionTrigger asChild>
                      <POIItemGrid onClick={() => {}}>
                        <POIIconContainer>
                          <POIBulletPoint />
                        </POIIconContainer>
                        <POITextContainer>
                          {poi.PointOfInterest}
                        </POITextContainer>
                      </POIItemGrid>
                    </StyledAccordionTrigger>
                  </StyledPOIAccordionHeader>
                  {/* <StyledPOIAccordionContent>
                    <AddressLeft>
                      <p>{poi.Address.Line1},</p>
                      <p>{poi.Address.Line2},</p>
                      <p>
                        {poi.Address.City}, {poi.Address.ZIP}
                      </p>
                    </AddressLeft>
                    <AddressRight>
                      <p>
                        {poi.Distance.Value}
                        {poi.Distance.Measurement === "ft"
                          ? " ft away"
                          : poi.Distance.Measurement === "miles"
                          ? " miles away"
                          : poi.Distance.Measurement === "mile"
                          ? " mile away"
                          : ""}
                      </p>
                    </AddressRight>
                  </StyledPOIAccordionContent> */}
                  <POILineBreak
                    style={{
                      placeSelf: "center",
                      width: "60%",
                      borderBottom:
                        index !== category.PointsOfInterest.length - 1
                          ? `1px solid ${globalStyles.colors.grey}`
                          : null,
                    }}
                  />
                </StyledPOIAccordionItem>
              ))}
            </StyledPOIAccordion>
          </StyledCategoryAccordionContent>
        </StyledCategoryAccordionItem>
      ))}
    </StyledCategoryAccordion>
  )
}

//-------- Accordion --------- //

const StyledCategoryAccordion = styled(Accordion)`
  /* height: 100%; */
  width: 100%;
  grid-column: 1; //leave this, as it's used by Location-2
  grid-row: 3; //leave this, as it's used by Location-2
  z-index: 100;
  color: ${(props) => props.theme.colors.white};
  font-family: "Dreamer Bold", serif;
  /* font-weight: 800; */
  font-size: 2.4rem;
  overflow: scroll;
  touch-action: pan-y;
  /* display: grid;
  grid-auto-flow: row;
  grid-auto-rows: minmax(calc(100% / 8), 500%); */

  &button,
  &h3 {
    all: unset;
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`

//-------- Category Accordion --------- //
const StyledCategoryAccordionItem = styled(AccordionItem)<Location2Types>`
  overflow: scroll;
  touch-action: pan-y;
  background-color: ${({ theme, color }) => theme.colors[color]};
  /* min-height: 12vh; */
  /* max-height: 10vh; */
  width: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  &svg {
    transition: 0.3s;
  }

  &[data-state="open"] {
    #CategoryLineBreak {
      background-color: ${(props) => props.theme.colors.white};
    }
    & svg {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }

  &[data-state="closed"] {
    & svg {
      transform: rotate(0deg);
      transition: 0.3s;
    }
  }
`

const StyledAccordionHeader = styled(AccordionHeader)`
  overflow: scroll;
  touch-action: pan-y;
  min-height: 6.65rem;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  /* min-height: 6.65vh;
  min-height: 6.65dvh; */
`

const StyledAccordionTrigger = styled(AccordionTrigger)`
  overflow: scroll;
  touch-action: pan-y;
  height: 100%;
  width: 100%;
  pointer-events: auto;
`

const CategoryItemGrid = styled.div`
  /* overflow: scroll;
  touch-action: pan-y; */
  z-index: 2;
  height: 100%;
  width: 100%;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: minmax(0, 0.3fr) minmax(0, 3fr) minmax(0, 8fr) minmax(
      0,
      0.3fr
    );
  grid-template-rows: minmax(0, 1fr) minmax(0, 7fr) minmax(0, 1fr);
  place-items: center;
  &:focus {
    outline: none;
  }
`

const CategoryIconContainer = styled.div`
  height: 100%;
  width: 100%;
  /* overflow: scroll;
  touch-action: pan-y; */
  pointer-events: none;
  grid-column: 2;
  grid-row: 2;
  display: grid;
  place-items: center;
  transition: 1s;
  & svg {
    height: 50%;
    width: auto;
    place-self: center;
  }
`

const CategoryTextContainer = styled.div`
  height: 100%;
  width: 100%;
  /* overflow: scroll;
  touch-action: pan-y; */
  pointer-events: none;
  grid-column: 3;
  grid-row: 2;
  display: grid;
  align-self: center;
  justify-self: left;
  & p {
    display: grid;
    place-items: center left;
    font-size: clamp(1rem, 2vw, 2rem);
  }
`

const CategoryLineBreak = styled.div`
  /* overflow: scroll;
  touch-action: pan-y; */
  pointer-events: none;
  height: 2px;
  width: 100%;
  grid-column: 2 / -2;
  grid-row: 3;
  display: grid;
  align-self: start;
  justify-self: left;
`

const StyledCategoryAccordionContent = styled(AccordionContent)<Location2Types>`
  &[data-state="open"] {
    min-height: ${(props) => `${props.total * 8}rem`};
  }
  width: 100%;
  overflow: scroll;
  touch-action: pan-y;
  grid-column: 1 / -1;
  grid-row: 3;
  overflow: hidden;
  display: grid;
  &[data-state="open"] {
    animation: slideDown 300ms ease-out;
  }
  &[data-state="closed"] {
    /* animation: slideUp 300ms ease-out; */
  }
`

//-------- Category Accordion --------- //

const StyledPOIAccordion = styled(Accordion)<Location2Types>`
  &[data-state="open"] {
    min-height: ${(props) => `${props.total * 8}rem`};
  }
  pointer-events: none;
  /* --- */
  overflow: scroll;
  touch-action: pan-y;
  /* --- */
  /* overflow: scroll;
  touch-action: pan-y; */
  grid-column: 1; //leave this, as it's used by Location-2
  grid-row: 1; //leave this, as it's used by Location-2
  display: flex;
  flex-direction: column;
  font-family: "Gilmer", sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;

  & button,
  h3 {
    touch-action: pan-y;
    all: unset;
    cursor: pointer;
  }

  & button:focus {
    outline: none;
  }
`

const StyledPOIAccordionItem = styled(AccordionItem)<Location2Types>`
  touch-action: pan-y;
  background-color: ${({ theme, color }) => theme.colors[color]};
  /* min-height: 2vh;
  min-height: 2dvh; */
  /* --- */
  overflow: scroll;
  touch-action: pan-y;
  /* --- */
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 6;

  & svg {
    transition: 0.3s;
  }
`

const StyledPOIAccordionHeader = styled(AccordionHeader)<Location2Types>`
  /* --- */
  overflow: scroll;
  touch-action: pan-y;
  /* --- */
  /* min-height: 2vh;
  min-height: 2dvh; */
`

const POIItemGrid = styled.div`
  /* --- */
  overflow: scroll;
  touch-action: pan-y;
  /* --- */
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 8fr) minmax(
      0,
      1fr
    );
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
      0,
      0.1fr
    );
  place-items: center;
  &:focus {
    outline: none;
  }
`

const POIIconContainer = styled.div`
  /* --- */
  overflow: scroll;
  touch-action: pan-y;
  /* --- */
  grid-column: 2;
  grid-row: 2;
  display: grid;
  place-items: center;
  & svg {
    height: 100%; // Adjust this property to fit the icon properly
    width: auto;
    max-height: 10rem; // Add this property to prevent the icon from getting too big
    place-self: center;
  }
`

const POITextContainer = styled.div`
  pointer-events: none;
  grid-column: 3;
  grid-row: 2;
  display: grid;
  align-self: center;
  justify-self: left;
`

const POILineBreak = styled.div`
  pointer-events: none;
  /* grid-column: 2 / -2; */
  /* grid-row: 4; */
  height: 5px;
  width: 100%;
  /* padding: 1rem 0rem; */
  /* border-bottom: 1px solid ${(props) => props.theme.colors.grey}; */
`

const StyledPOIAccordionContent = styled(AccordionContent)`
  width: 100%;
  pointer-events: none;
  display: grid;
  grid-template-columns:
    minmax(0, 2fr) minmax(0, 1.5fr) minmax(0, 3fr) minmax(0, 0.5fr) minmax(
      0,
      5fr
    )
    minmax(0, 2fr);
  grid-template-rows: minmax(0, 0.01fr) minmax(0, 1fr);
  font-size: 0.7rem;
  text-align: left;
  &[data-state="open"] {
    animation: slideDown 50ms ease-out;
    #CategoryLineBreak {
      background-color: ${(props) => props.theme.colors.white};
    }
    & svg {
      transform: rotate(180deg);
      transition: 0.3s;
    }
  }
  /* &[data-state="closed"] {
    animation: slideUp 100ms ease-in;
  } */
`

const AddressLeft = styled.div`
  grid-column: 3;
  grid-row: 2;
  padding-bottom: 2rem;
  height: 100%;
  width: 100%;
`

const AddressRight = styled.div`
  grid-column: 5;
  grid-row: 2;
  padding-bottom: 2rem;
  height: 100%;
  width: 100%;
`
