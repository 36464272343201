//General
import React, { useEffect } from "react"
import { useStore } from "@state/store"

//Components
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"

//Types
interface StartTypes {
  flip?: boolean
}

export default function Finishes() {
  //Set Current Page State
  useEffect(() => {}, [])

  //Initiate carousel
  const [emblaRef] = useEmblaCarousel({ loop: true, speed: 20 })

  return (
    <FinishesWrapper>
      <DragToScroll>← DRAG TO SCROLL →</DragToScroll>
      <Embla ref={emblaRef}>
        <EmblaContainer>
          <EmblaSlide color="grey">
            <LeftSide>
              <LeftImageContainer>
                <LeftImage
                  src="/media/finishes/finishes--bathrooms-1.webp"
                  alt="The Boardwalk"
                />
              </LeftImageContainer>
              <Title>Bathrooms</Title>
              <Description>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos. Praesent
                auctor purus luctus enim egestas, ac scelerisque ante pulvinar.
                Donec ut rhoncus ex.
              </Description>
            </LeftSide>
            <RightSide>
              <BackgroundContainer>
                <Background
                  src="/media/finishes/finishes--bathrooms-2.webp"
                  alt="The Boardwalk"
                />
              </BackgroundContainer>
              <RightImageContainer>
                <RightImage
                  src="/media/finishes/finishes--bathrooms-3.webp"
                  alt="The Boardwalk"
                />
              </RightImageContainer>
            </RightSide>
          </EmblaSlide>
          <EmblaSlide color="grey">
            <LeftSide flip>
              <LeftImageContainer>
                <LeftImage
                  src="/media/finishes/finishes--bedrooms-1.webp"
                  alt="The Boardwalk"
                />
              </LeftImageContainer>

              <Title>Bedrooms</Title>
              <Description>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos. Praesent
                auctor purus luctus enim egestas, ac scelerisque ante pulvinar.
                Donec ut rhoncus ex.
              </Description>
            </LeftSide>
            <RightSide flip>
              <BackgroundContainer>
                <Background
                  src="/media/finishes/finishes--bedrooms-2.webp"
                  alt="The Boardwalk"
                />
              </BackgroundContainer>
              <RightImageContainer>
                <RightImage
                  src="/media/finishes/finishes--bedrooms-3.webp"
                  alt="The Boardwalk"
                />
              </RightImageContainer>
            </RightSide>
          </EmblaSlide>
          <EmblaSlide color="grey">
            <LeftSide>
              <LeftImageContainer>
                <LeftImage
                  src="/media/finishes/finishes--kitchen-living-1.webp"
                  alt="The Boardwalk"
                />
              </LeftImageContainer>
              <Title>Living & Kitchen</Title>
              <Description>
                Dorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos. Praesent
                auctor purus luctus enim egestas, ac scelerisque ante pulvinar.
                Donec ut rhoncus ex.
              </Description>
            </LeftSide>
            <RightSide>
              <BackgroundContainer>
                <Background
                  src="/media/finishes/finishes--kitchen-living-2.webp"
                  alt="The Boardwalk"
                />
              </BackgroundContainer>
              <RightImageContainer>
                <RightImage
                  src="/media/finishes/finishes--kitchen-living-3.webp"
                  alt="The Boardwalk"
                />
              </RightImageContainer>
            </RightSide>
          </EmblaSlide>
        </EmblaContainer>
      </Embla>
    </FinishesWrapper>
  )
}

const FinishesWrapper = styled.section<StartTypes>`
  height: 100%;
  width: 100%;
`

const DragToScroll = styled.div<StartTypes>`
  position: absolute;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-family: "Gilmer", sans-serif;
  padding: 0.7rem 2rem 0.7rem 2rem;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.colors.green};
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 100px;
  z-index: 3;
  font-weight: 400;
`

const Embla = styled.section<StartTypes>`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const EmblaContainer = styled.div<StartTypes>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
`

const EmblaSlide = styled.div<StartTypes>`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
  flex: 0 0 100%;
  min-width: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors[`${props.color}`]};
  z-index: 2;
`

const LeftSide = styled.div<StartTypes>`
  grid-column: ${(props) => (props.flip ? "2" : "1")};
  grid-row: 1;
  display: grid;
  grid-template-columns: minmax(0, 15fr) minmax(0, 70fr) minmax(0, 15fr);
  grid-template-rows:
    minmax(0, 17fr) minmax(0, 46fr) minmax(0, 14fr) minmax(0, 18fr)
    minmax(0, 5fr);
  background-color: ${(props) => props.theme.colors.grey};
  text-align: center;
`

const LeftImageContainer = styled.div<StartTypes>`
  grid-column: 2;
  grid-row: 2;
`

const LeftImage = styled.img<StartTypes>`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

const Title = styled.h2<StartTypes>`
  grid-column: 2;
  grid-row: 3;
  place-self: center;
  font-family: "Dreamer", serif;
  font-weight: 800;
  font-size: ${(props) => props.theme.fontSizes.xxxl};
  color: ${(props) => props.theme.colors.white};
`

const Description = styled.p<StartTypes>`
  grid-column: 2;
  grid-row: 4;
  height: 100%;
  width: 100%;
  padding: 0rem 3rem;
  place-self: center;
  font-family: "Gilmer", sans-serif;
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.white};
`

const RightSide = styled.div<StartTypes>`
  grid-column: ${(props) => (props.flip ? "1" : "2")};
  grid-row: 1;
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 20fr) minmax(0, 2fr);
  grid-template-rows: minmax(0, 2fr) minmax(0, 20fr) minmax(0, 2fr);
`

const Background = styled.img<StartTypes>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
`

const BackgroundContainer = styled.div<StartTypes>`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
`

const RightImageContainer = styled.div<StartTypes>`
  grid-column: 2;
  grid-row: 2;
  background-color: rgba(178, 178, 178, 0.5);
  z-index: 3;
`

const RightImage = styled.img<StartTypes>`
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 2rem;
`
