import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import QRCode from "qrcode"
import useSocketStore from "@state/useSocketStore"
import { appendFormDataToAirtable } from "./Airtable"

export default function SignupForm({ favorites }) {
  const qrCodeData = useSocketStore((state) => state.qrCodeData)
  const syncState = useSocketStore((state) => state.syncState)
  const finalFavorites = favorites.filter((f) => f !== "")
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      bcc: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, "Must be 100 characters or less")
        .required("Required"),
      email: Yup.string().max(30, "Invalid email").required("Required"),
      bcc: Yup.string().email("Invalid email"),
    }),
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2))
      const shareURL = `https://theboardwalklongbeach.app/?favorites=${finalFavorites.join(
        ","
      )}`
      // const handleAirtable = async () => {
      //   try {
      //     await appendFormDataToAirtable(
      //       values?.name,
      //       values?.email,
      //       shareURL,
      //       0
      //     )
      //     console.log("Data appended to Airtable")
      //   } catch (error) {
      //     console.log("Error appending data to Airtable:", error)
      //   }
      // }
      // handleAirtable()
      const generateQRCode = async (shareURL) => {
        try {
          const qrCodeDataUrl = await QRCode.toDataURL(shareURL)
          syncState("qrCodeData", qrCodeDataUrl)
          console.log(qrCodeData)
        } catch (error) {
          console.error("Failed to generate QR code:", error)
        }
      }
      generateQRCode(shareURL)
      console.log("QR Code Type: ", typeof QRCode)
      console.log(shareURL)
      const names = values?.name?.split(" ") ?? []
      const emailURL =
        "https://qqo4y7jd7c.execute-api.eu-west-2.amazonaws.com/dev/send"
      const apiKey = "SuslEuh0MU9WUeGbEjF6N83RLw84ikWq5pQqw7CK"
      fetch(emailURL, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          from: "sales@theboardwalklongbeach.com",
          to: [values?.email ?? ""],
          bcc: [values?.bcc ?? ""],
          template: "boardwalk",
          language: "en",
          data: {
            firstname: names[0],
            lastname: names.slice(1).join(" "),
            link: shareURL,
          },
        }),
      }).then(() => {
        formik.resetForm()
      })
    },
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="inputWrap">
        {!formik.isSubmitting ? (
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
        ) : (
          <div className="spinner" />
        )}
      </div>
      {formik.touched.name && formik.errors.name ? (
        <div>{formik.errors.name}</div>
      ) : null}

      <div className="inputWrap">
        {!formik.isSubmitting && (
          <input
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
        )}
      </div>
      {formik.touched.email && formik.errors.email ? (
        <div>{formik.errors.email}</div>
      ) : null}

      <div className="inputWrap">
        {!formik.isSubmitting && (
          <input
            id="bcc"
            name="bcc"
            type="email"
            placeholder="BCC"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bcc}
          />
        )}
      </div>

      {formik.touched.email && formik.errors.email ? (
        <div>{formik.errors.email}</div>
      ) : null}

      <div />
      <button disabled={formik.isSubmitting} type="submit">
        Submit
      </button>
    </form>
  )
}
