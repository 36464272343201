type TIconProps = {
  strokeWidth: number
  color: string
}

function CloseIcon({ strokeWidth, color }: TIconProps) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="closeIcon"
    > 
      <line 
        x1={33.5}
        y1={3.5}
        x2={3.5}
        y2={33.5}
        strokeWidth={strokeWidth}
        stroke={color}
        id="closeIcon"
      />
      <line 
        x1={3.5}
        y1={3.5}
        x2={33.5}
        y2={33.5}
        strokeWidth={strokeWidth}
        stroke={color}
        id="closeIcon"
      />
    </svg>
  )
}

export default CloseIcon
