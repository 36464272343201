export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="90"
      height="41"
      viewBox="0 0 90 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id="FavoritesRightArrow"
      {...props}
    >
      <path
        d="M20.6816 1H69.1362C79.9057 1 88.6362 9.73045 88.6362 20.5C88.6362 31.2696 79.9057 40 69.1362 40H20.6816C9.91208 40 1.18164 31.2696 1.18164 20.5C1.18164 9.73045 9.91209 1 20.6816 1Z"
        fill="#799B90"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M67.7071 19.2929C68.0976 19.6834 68.0976 20.3166 67.7071 20.7071L61.3431 27.0711C60.9526 27.4616 60.3195 27.4616 59.9289 27.0711C59.5384 26.6805 59.5384 26.0474 59.9289 25.6569L65.5858 20L59.9289 14.3431C59.5384 13.9526 59.5384 13.3195 59.9289 12.9289C60.3195 12.5384 60.9526 12.5384 61.3431 12.9289L67.7071 19.2929ZM24 19L67 19V21L24 21V19Z"
        fill="white"
      />
    </svg>
  )
}
