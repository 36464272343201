const globalStyles = {
  colors: {
    pink: "#DF9994",
    gold: "#D3A424",
    green: "#719086",
    green2: "#6C7852",
    blue: "#5A6DAC",
    navy: "#5B798E",
    orange: "#C27B3C",
    red: "#B87381",
    purple: "#7B6F98",
    grey: "#CEC4B5",
    black: "#141414",
    white: "#F6F6F6",
    Apartment_pink: "#EBB0AC",
    Apartment_blue: "#DCA29E",
    Apartment_grey: "#F8B2AD",
    Apartment_gold: "#F8C2BE",
    Apartment_white: "#F6F6F6",
  },
  fontSizes: {
    xxxl: "5.5rem",
    xxl: "4.4rem",
    xl: "3.3rem",
    lg: "2.2rem",
    md: "1.8rem",
    sm: "1.5rem",
    xs: "1.2rem",
    xxs: "1rem",
    xxxs: "0.9rem",
  },
}

export default globalStyles
