import { create } from "zustand"

interface InitialState {
  loaded: boolean
  setLoaded: (v: boolean) => void

  progress: number
  setProgress: (v: number) => void
}

export const useLoadingStore = create<InitialState>()((set, get) => ({
  loaded: false,
  setLoaded: (v: boolean) => set({ loaded: v }),
  progress: 0,
  setProgress: (v: number) => {
    set({ progress: v })
    if (v == 100) set({ loaded: true })
  },
}))
