//General
import React, { useEffect, useRef } from "react"
import { useStore } from "@state/store"
import styled from "styled-components"

//Components
import Location2Title from "@components/SVG/Location2Title"
import LocationsAccordion from "@components/LocationAccordion"
import Map from "@components/SVG/LocationMap"
import ZoomedMap from "@components/SVG/LocationZoomed"
import TextTopLayer from "@components/SVG/LocationTextTopLayer"
import MapLogo from "@components/SVG/LocationLogo"
import MapLogoZoomed from "@components/SVG/LocationLogoZoomed"
import MapDotA from "@components/SVG/MapDotA"
import MapDotB from "@components/SVG/MapDotB"
import { setTimelineAnimation, timelineAnimation } from "@animations/Location2"
import useSocketStore from "@state/useSocketStore"
import MapModal from "@components/MapModal/MapModal"

//Data
import locationsData from "@data/LocationPOIsFinal.json"
import { categoryColors } from "@data/categoryColors"
import { useAnimate } from "@utils/hooks"

//Types
type LocationData = {
  id: number
  key: number
  index: number
  Category: string
  CategoryID: number
  Items: {
    PointOfInterest: string
    Address: {
      Line1: string
      Line2: string
      City: string
      ZIP: string
    }
    Distance: {
      Value: number
      Measurement: string
    }
  }[]
}

type LocationTypes = {
  color?: string
  x?: number
  y?: number
  categoryid?: number
  pointofInterestid?: number
  currentcategory?: string
  currentpoi?: string
}

export default function Location2() {
  const gsapRef = useRef()
  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })
  const activePOIName = useSocketStore((state) => state.activePOIName)
  const activePOICategory = useSocketStore((state) => state.activePOICategory)
  const mapExpanded = useSocketStore((state) => state.mapExpanded)
  const timeline = useStore((state) => state.timeline)
  const viewBox = useStore((state) => state.viewBox)
  const setViewBox = useStore((state) => state.setViewBox)
  const syncState = useSocketStore((state) => state.syncState)

  function handleZoom() {
    if (!mapExpanded) {
      timeline?.to(".MapViewBoxSVG", {
        attr: {
          viewBox: `0 0 ${viewBox.w_} ${viewBox.h_}`,
        },
        ease: "none",
      })
      timeline?.to(
        ".NonZoomed",
        { autoAlpha: 1, duration: 0.6, ease: "none" },
        "<"
      )
      timeline?.to(
        ".ZoomedMap",
        { autoAlpha: 0, duration: 0.4, ease: "none" },
        "<"
      )
      timeline?.to(
        ".ZoomedMapBG",
        { autoAlpha: 0, duration: 0.4, ease: "none" },
        "<"
      )
    } else {
      timeline?.to(".MapViewBoxSVG", {
        attr: {
          viewBox: `${viewBox.w_ / 4 + viewBox.w_ * 0.01} ${
            viewBox.h_ / 4 + viewBox.h_ * 0.1
          } ${viewBox.w_ * 0.6}, ${viewBox.h_ * 0.6}`,
        },
        ease: "none",
      })
      timeline?.to(
        ".NonZoomed",
        { autoAlpha: 0, duration: 1.2, ease: "none" },
        "<"
      )
      timeline?.to(
        ".ZoomedMap",
        { delay: 0.3, autoAlpha: 1, duration: 1.2, ease: "none" },
        "<"
      )
      timeline?.to(
        ".ZoomedMapBG",
        { delay: 0.3, autoAlpha: 1, duration: 1.2, ease: "none" },
        "<"
      )
    }
  }

  function handleMapClick() {
    syncState("activePOICategory", activePOICategory !== "" ? "" : "")
    syncState("mapExpanded", mapExpanded === null ? true : !mapExpanded)
  }

  useEffect(() => {
    handleZoom()
  }, [mapExpanded])

  useEffect(() => {
    return () => {
      syncState("mapExpanded", null)
      syncState("activePOIName", "")
      syncState("activePOICategory", "")
      setViewBox({ x: 0, y: 0, w: viewBox.w_, h: viewBox.h_ })
    }
  }, [])

  return (
    <MapSection className="MapSection" ref={gsapRef}>
      <MapModal />
      <Sidebar>
        <LeftImageContainer>
          <LeftImage
            className="LeftImage"
            src="/media/location/location--2-image.webp"
            alt="The Boardwalk"
          />
        </LeftImageContainer>
        <TitleContainer>
          <Title className="SVGTitle" />
        </TitleContainer>
        <LocationsAccordion />
      </Sidebar>
      <RightContainer>
        <MapContainer>
          <Map
            className="MapViewBoxSVG FadeIn1 NonZoomed"
            style={{ visibility: "hidden" }}
            click={() => handleMapClick()}
          />
          <ZoomedMap
            className="ZoomedMap"
            style={{
              visibility: "hidden",

              zIndex: 7,
            }}
            click={() => handleMapClick()}
          />
          <div
            style={{
              visibility: "hidden",
              zIndex: 6,
            }}
            className="ZoomedMapBG"
          />
          <TextTopLayer
            className="MapViewBoxSVG FadeIn2 NonZoomed"
            style={{ visibility: "hidden" }}
            click={() => handleMapClick()}
          />
          <MapLogo
            className="MapViewBoxSVG FadeIn3 NonZoomed"
            style={{
              visibility: "hidden",
            }}
            click={() => handleMapClick()}
          />
          <MapLogoZoomed
            className="ZoomedMap"
            style={{
              visibility: "hidden",

              zIndex: 8,
            }}
            click={() => handleMapClick()}
          />

          {locationsData.map((category) => (
            <React.Fragment key={category.CategoryID}>
              {category.PointsOfInterest.filter(
                (poi) => poi.CircleType === "A"
              ).map((poi, index) => (
                <React.Fragment key={poi.PointOfInterestID}>
                  <MapDotA
                    className="MapDotA"
                    style={{
                      transitionDuration: mapExpanded ? "1500ms" : "200ms",
                      transitionDelay: mapExpanded
                        ? `${1200 + index * 5}ms`
                        : "50ms",

                      transitionProperty: "opacity",
                      opacity:
                        mapExpanded &&
                        (String(activePOICategory) ===
                          String(category.CategoryID) ||
                          String(activePOICategory) === "")
                          ? 0.9
                          : 0,
                    }}
                    x={poi.Position.x}
                    y={poi.Position.y}
                    categoryid={category.CategoryID}
                    pointofinterestid={poi.PointOfInterestID}
                    currentcategory={activePOICategory}
                    currentpoi={activePOIName}
                    key={`${category.CategoryID}-${poi.PointOfInterestID}`}
                    color={categoryColors[category.Category]}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          {locationsData.map((category) => (
            <React.Fragment key={category.CategoryID}>
              {category.PointsOfInterest.filter(
                (poi) => poi.CircleType === "B"
              ).map((poi, index) => (
                <React.Fragment key={poi.PointOfInterestID}>
                  <MapDotB
                    className="MapDotB"
                    style={{
                      transitionDuration: mapExpanded ? "1500ms" : "200ms",
                      transitionDelay: mapExpanded
                        ? `${1500 + index * 5}ms`
                        : "50ms",
                      transitionProperty: "opacity",
                      opacity:
                        mapExpanded &&
                        (String(activePOICategory) ===
                          String(category.CategoryID) ||
                          String(activePOICategory) === "")
                          ? 0.9
                          : 0,
                    }}
                    x={poi.Position.x}
                    y={poi.Position.y}
                    categoryid={category.CategoryID}
                    pointofinterestid={poi.PointOfInterestID}
                    currentcategory={activePOICategory}
                    currentpoi={activePOIName}
                    key={`${category.CategoryID}-${poi.PointOfInterestID}`}
                    color={categoryColors[category.Category]}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </MapContainer>
      </RightContainer>
    </MapSection>
  )
}

const MapSection = styled.section<LocationTypes>`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 615fr) minmax(0, 3225fr);
  grid-template-rows: minmax(0, 1fr);
  background-color: ${(props) => props.theme.colors.green};
  overflow: hidden;
  z-index: 4;
  .AccordionCategory {
    visibility: hidden;
  }
`

const Sidebar = styled.div<LocationTypes>`
  height: 100%;
  width: 100%;
  grid-column: 1;
  grid-row: 1;
  background-color: ${(props) => props.theme.colors.green};
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1.5fr) minmax(0, 0.8fr) minmax(0, 3fr);
  overflow: hidden;
`

const LeftImageContainer = styled.div<LocationTypes>`
  grid-column: 1;
  grid-row: 1;
`

const LeftImage = styled.img<LocationTypes>`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const TitleContainer = styled.div<LocationTypes>`
  grid-column: 1;
  grid-row: 2;
  display: grid;
  place-items: center;
  grid-template-columns: minmax(0, 1.5fr) minmax(0, 7fr) minmax(0, 1.5fr);
  grid-template-rows: minmax(0, 1.5fr) minmax(0, 7fr) minmax(0, 1.5fr);
  & svg {
    grid-column: 2;
    grid-row: 2;
    height: 90%;
    width: 90%;
  }
`

const Title = styled(Location2Title)<LocationTypes>``

const RightContainer = styled.div<LocationTypes>`
  grid-column: 2;
  grid-row: 1;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
`

const MapContainer = styled.div<LocationTypes>`
  .MapDotA,
  .MapDotB {
    z-index: 10;
    pointer-events: none;
    & svg,
    & path {
      pointer-events: none;
    }
    & circle,
    & path {
      pointer-events: auto;
    }
  }

  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: start;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.navy};
  z-index: 4;
  & svg {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: auto;
    width: 100%;
    & #locationMap {
      z-index: 4;
    }
  }
  .ZoomedMap {
    z-index: 6;
  }
  .ZoomedMapBG {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.colors.navy};
    z-index: 6;
    opacity: 1;
  }
`
