import Amenities from "@pages/Amenities"
import ErrorMessage from "@pages/ErrorMessage"
import Finishes from "@pages/Finishes"
import Gallery from "@pages/Gallery"
import GalleryResidences from "@pages/GalleryResidences"
import GalleryAmenities from "@pages/GalleryAmenities"
import GalleryViews from "@pages/GalleryViews"
import Landing from "@pages/Landing"
import Location from "@pages/Location"
import Location2 from "@pages/Location-2"
import Residences from "@pages/Residences"
import Home from "@pages/Home"
import Favourites from "@pages/Favourites"

import AuxResidences from "@pages/AuxResidences"
import AuxAmenities from "@pages/AuxAmenities"

import { createRef } from "react"

export const routes = [
  {
    name: "Landing",
    path: "/",
    exact: true,
    // element: Landing,
    element: <Landing />,
    nodeRef: createRef(),
  },
  {
    name: "Home",
    path: "/home",
    exact: true,
    element: <Home />,
    isOnNavbar: true,
    nodeRef: createRef(),
  },
  {
    name: "Location",
    path: "/location",
    exact: true,
    element: <Location />,
    isOnNavbar: true,
    nodeRef: createRef(),
  },
  {
    name: "Location - Map",
    path: "/location/map",
    exact: true,
    element: <Location2 />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
  {
    name: "Amenities",
    path: "/amenities",
    exact: true,
    element: <Amenities />,
    isOnNavbar: true,
    nodeRef: createRef(),
  },
  {
    name: "Residences",
    path: "/residences",
    exact: true,
    element: <Residences />,
    isOnNavbar: true,
    nodeRef: createRef(),
  },
  {
    name: "Finishes",
    path: "/finishes",
    exact: true,
    element: <Finishes />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
  {
    name: "Gallery",
    path: "/gallery",
    exact: true,
    element: <Gallery />,
    isOnNavbar: true,
    nodeRef: createRef(),
  },
  {
    name: "Gallery | Residences",
    path: "/gallery/residences",
    exact: true,
    element: <GalleryResidences />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
  {
    name: "Gallery | Amenities",
    path: "/gallery/amenities",
    exact: true,
    element: <GalleryAmenities />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
  {
    name: "Gallery | Views",
    path: "/gallery/views",
    exact: true,
    element: <GalleryViews />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
  {
    name: "Favourites",
    path: "/favorites",
    exact: true,
    element: <Favourites />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
  {
    name: "Error",
    path: "/*",
    exact: true,
    element: <Landing />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
]
export const auxRoutes = [
  {
    name: "Landing",
    path: "/",
    exact: true,
    element: <Landing />,
    nodeRef: createRef(),
  },
  {
    name: "Residences",
    path: "/residences",
    exact: true,
    element: <AuxResidences />,
    nodeRef: createRef(),
  },,
  {
    name: "Amenities",
    path: "/amenities",
    exact: true,
    element: <AuxAmenities />,
    nodeRef: createRef(),
  },
  {
    name: "Error",
    path: "/*",
    exact: true,
    element: <ErrorMessage />,
    isOnNavbar: false,
    nodeRef: createRef(),
  },
]
