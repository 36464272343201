import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {
  timeline.set(
    [
      ".SVGLogo path",
      ".Subtitle .char",
      ".Enter .char",
      ".LandingVideo",
      "#RoomButton",
    ],
    {
      autoAlpha: 0,
    }
  )
  const SplitSubtitle = new SplitType(".Subtitle", {
    types: "chars",
  })
  const SplitEnter = new SplitType(".Enter", {
    types: "chars",
  })
}

export const timelineAnimation = ({ timeline }) => {
  timeline.to(
    ".LandingVideo",

    {
      autoAlpha: 1,
      delay: 0.6,
      duration: 0.4,
      // y: "0%",
    },
    "<"
  )
  timeline.to(
    ".SVGLogo path",
    {
      delay: 0.9,
      autoAlpha: 1,
      duration: 4,
      stagger: 0.3,
      ease: "power4.out",
    },
    "<"
  )
  timeline.to(
    "#RoomButton",
    {
      delay: 1,
      autoAlpha: 1,
      duration: 2,
      stagger: 0.2,
      ease: "power4.out",
    },
    "<"
  )
  timeline.to(
    ".Subtitle .char",
    {
      autoAlpha: 1,
      duration: 1.8,
      delay: 1.2,
      stagger: 0.05,
    },
    "<"
  )
  timeline.to(
    ".Enter .char",
    {
      autoAlpha: 1,
      duration: 2.2,
      delay: 1.5,
      stagger: 0.1,
    },
    "<"
  )
}
