import { useCallback, useEffect, useRef } from "react"
import { useStore } from "@state/store"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"

//Components
import FavoritesLeftArrow from "@components/SVG/Favorites/FavoritesLeftArrow"
import FavoritesRightArrow from "@components/SVG/Favorites/FavoritesRightArrow"
import FavoritesShare from "@components/SVG/Favorites/FavoritesShare"
import Search from "@components/SVG/Residences/SideBarIcons/Search"
import Favourite from "@components/SVG/Residences/SideBarIcons/Favourite"
import SvgFavoritesShareStamp from "@components/SVG/Favorites/FavoritesShareStamp"
import { setTimelineAnimation, timelineAnimation } from "@animations/Favorites"
import Refresh from "@components/SVG/Favorites/FavoritesRefresh"

//Data
import residenceFloorPlanColors from "@data/residenceFloorPlanColors.json"
import exposureFacing from "@data/exposureFacing.json"

//SVGs - Floor Plans
// import * as FloorPlans from "@components/SVG/Residences/FloorPlans"
import { useNavigate } from "react-router-dom"
import { useAnimate } from "@utils/hooks"
import useSocketStore from "@state/useSocketStore"
import ResidencePopup from "@components/ResidencePopup/ResidencePopup"
import GetInitialActiveFavorite from "@utils/getInitialFavorite"
import { calculateSlide } from "@utils/calculateSlide"
import globalStyles from "../style.global"
import Form from "@components/Favorites/form"
import { useDeviceStore } from "@state/deviceStore"
import { floorPlanIndex } from "@data/floorPlanIndex"

//Types
interface Types {
  color?: string
  index?: number
}

export default function Favourites() {
  const isIpad = useDeviceStore((state) => state.isIpad)
  const qrCodeData = useSocketStore((state) => state.qrCodeData)
  const apartments = useDeviceStore((state) => state.apartments)
  const gsapRef = useRef()
  // const location = useLocation()
  const socket = useSocketStore((state) => state.socket)
  const setEmbla = useSocketStore((state) => state.setEmbla)
  const favorites = useSocketStore((state) => state.favorites)
  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })
  const syncState = useSocketStore((state) => state.syncState)
  const residencePopups = useSocketStore((state) => state.residencePopups)

  const navigate = useNavigate()

  const showShareFavorites = useSocketStore((state) => state.showShareFavorites)

  useEffect(() => {
    GetInitialActiveFavorite(favorites, apartments) &&
      updatePopupOptions({
        activeResidence: String(
          GetInitialActiveFavorite(favorites, apartments)
        ),
      })
    return () => {
      syncState("qrCodeData", null)
      syncState("showShareFavorites", false)
      syncState("formName", "")
      syncState("formEmail", "")
      syncState("formBCC", "")
      syncState("residencePoupups", {
        activeResidence: null,
        residenceContainerOpen: false,
        residenceFloorPlanOpen: false,
        residenceViewOpen: false,
      })
    }
  }, [])

  //Initiate carousel
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    speed: 20,
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    syncState("formName", "")
    syncState("formEmail", "")
    syncState("formBCC", "")
  }

  function updatePopupOptions(newValues) {
    const updatedPopupOptions = { ...residencePopups, ...newValues }
    syncState("residencePopups", updatedPopupOptions)
  }

  const onSelect = useCallback(() => {
    if (emblaApi) {
      const id = emblaApi.selectedScrollSnap()
      setEmbla(id)
    }
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect)
    }
  }, [emblaApi, onSelect])

  useEffect(() => {
    socket?.on("got-embla", ({ data }) => {
      if (emblaApi) {
        if (data !== emblaApi.selectedScrollSnap()) {
          emblaApi.scrollTo(data)
        }
      }
    })
  }, [emblaApi])

  const expandView = (favorite) => {
    updatePopupOptions({
      activeResidence: String(favorite),
      residenceContainerOpen: true,
    })
  }

  let validResidences = favorites
    .map((favorite: any) => calculateSlide(favorite, apartments))
    .filter((residence: any) => residence !== null)

  let orderedFavorites = validResidences
    .sort((a: any, b: any) => a.price - b.price)
    .map((residence: any) => residence.uid)

  return (
    <FavoritesSection share={showShareFavorites} ref={gsapRef}>
      {residencePopups.activeResidence !== null && (
        <ResidencePopup uid={residencePopups.activeResidence} />
      )}
      <p
        style={{
          opacity:
            favorites?.length === 0 ||
            (favorites[0] === "" && favorites?.length === 1)
              ? 1
              : 0,
        }}
        className="NoFavoritesTest"
      >
        You don't have any favorites yet.
        <br />
        <br />
        Return to "Residences" to add to your collection.
      </p>
      <FavoritesButtons>
        <FavoritesLeftArrow
          className="FadeInStagger2"
          style={{
            opacity:
              favorites[0] === ""
                ? favorites?.length < 3 || showShareFavorites
                  ? 0.1
                  : 1
                : favorites?.length < 2 || showShareFavorites
                ? 0.1
                : 1,

            pointerEvents:
              favorites[0] === ""
                ? favorites?.length < 3 || showShareFavorites
                  ? "none"
                  : "auto"
                : favorites?.length < 2 || showShareFavorites
                ? "none"
                : "auto",
          }}
          onClick={() => {
            if (emblaApi) {
              emblaApi.scrollPrev()
            }
          }}
        />

        <FavoritesRightArrow
          className="FadeInStagger2"
          style={{
            opacity:
              favorites[0] === ""
                ? favorites?.length < 3 || showShareFavorites
                  ? 0.1
                  : 1
                : favorites?.length < 2 || showShareFavorites
                ? 0.1
                : 1,

            pointerEvents:
              favorites[0] === ""
                ? favorites?.length < 3 || showShareFavorites
                  ? "none"
                  : "auto"
                : favorites?.length < 2 || showShareFavorites
                ? "none"
                : "auto",
          }}
          onClick={() => {
            if (emblaApi) {
              emblaApi.canScrollNext() ? emblaApi.scrollNext() : void 0
            }
          }}
        />

        <Refresh
          className="FadeInStagger2 refresh"
          onClick={() => syncState("favorites", [""])}
          style={{
            opacity:
              favorites?.length === 0 ||
              favorites[0] === "" ||
              showShareFavorites
                ? 0.1
                : 1,
            pointerEvents:
              favorites?.length === 0 || showShareFavorites ? "none" : "auto",
          }}
        />

        <FavoritesShare
          className="FadeInStagger2"
          onClick={() => syncState("showShareFavorites", !showShareFavorites)}
          style={{
            opacity:
              favorites[0] === ""
                ? favorites?.length < 2
                  ? 0.1
                  : 1
                : favorites?.length < 1
                ? 0.1
                : 1,

            pointerEvents:
              favorites[0] === ""
                ? favorites?.length < 2
                  ? "none"
                  : "auto"
                : favorites?.length < 1
                ? "none"
                : "auto",
          }}
        />
      </FavoritesButtons>
      <Embla ref={emblaRef}>
        <EmblaContainer
          style={{
            opacity: showShareFavorites ? 0 : 1,
            pointerEvents: showShareFavorites ? "none" : "auto",
          }}
        >
          {orderedFavorites !== null &&
            orderedFavorites.map((favorite, index) => {
              const matchedApartment = apartments.find((residence) => {
                const residenceUid =
                  residence["building"] === "Tower A"
                    ? `${residence["id"]}A`
                    : residence["building"] === "Tower B"
                    ? `${residence["id"]}B`
                    : null

                return residenceUid === favorite
              })

              const floorPlanType = matchedApartment["floorPlanType"] || "A-01"
              const id = matchedApartment["id"] || "Example"
              const buildingNumber = matchedApartment["buildingNumber"] || "100"
              const isAvailable = matchedApartment["isAvailable"] || false
              const price = matchedApartment["price"] || 1000000
              const showPrice = isAvailable || isIpad
              const floor = matchedApartment["floor"] || "4"
              const exposure = matchedApartment["exposure"] || []
              const interiorSquareFootage =
                matchedApartment["interiorSquareFootage"] || 1000
              const terraceSquareFootage =
                matchedApartment["terraceSquareFootage"] || 200
              const bed = matchedApartment["bed"] || "1"
              const bath = matchedApartment["bath"] || "1"

              let color: string
              residenceFloorPlanColors.some((obj) => {
                // Loop through the array of objects
                if (String(bed) in obj) {
                  // Check if String(bed) is a key in the current object
                  color = obj[String(bed)] // Assign the corresponding color value to the color variable
                  return true // Return true to exit the loop
                } else {
                  color = "green"
                  return false
                }
              })
              if (String(floor) === "PH") {
                color = "grey"
              }
              // let FloorPlanName = String(floorPlanType.replace("-", ""))
              // let FloorPlanComponent = FloorPlans[FloorPlanName]
              const facingDirections = exposure.map(
                (letter) => exposureFacing[0][letter]
              )
              const facingString = facingDirections.join(", ")

              const matchingKey = Object.keys(floorPlanIndex).find((key) =>
                floorPlanIndex[key].includes(favorite)
              )
              const floorplanSrc = `/media/residences/FloorPlanWEBPs/${floorPlanIndex[matchingKey]}.webp`

              if (!matchedApartment) {
              } else
                return (
                  <EmblaSlide
                    className="FadeInStagger"
                    style={{ visibility: "hidden" }}
                    color={color}
                    index={index}
                    key={index}
                  >
                    <div
                      onClick={() => expandView(favorite)}
                      className="bottomLeftBg"
                    />
                    <div
                      onClick={() => expandView(favorite)}
                      className="bottomRightBg"
                    />
                    <h2 onClick={() => expandView(favorite)} className="title">
                      Apartment {id}
                    </h2>
                    <p
                      onClick={() => expandView(favorite)}
                      className="subtitle"
                    >
                      {Number(buildingNumber)} BOARDWALK
                      {showPrice &&
                        ` • $${Number(price / 100).toLocaleString("en-US")}`}
                    </p>
                    <div
                      onClick={() => expandView(favorite)}
                      className="information"
                    >
                      <div className="informationLeft">
                        <p>Floor {floor}</p>
                        <p>{interiorSquareFootage} SF</p>
                        <p>Terrace {terraceSquareFootage} SF</p>
                      </div>
                      <div className="informationRight">
                        <p>{facingString} Facing</p>
                        <p>{bed} Bedrooms</p>
                        <p>{bath} Baths</p>
                      </div>
                    </div>
                    {/* <div className="imageWrap">
                      <FloorPlanComponent
                        bed={String(bed)}
                        floor={String(floor)}
                        color={color}
                        className="svgFloorPlan"
                        onClick={() => expandView(favorite)}
                      />
                    </div> */}
                    <div
                      className="imageWrap"
                      onClick={() => expandView(favorite)}
                    >
                      <img alt="The Boardwalk" src={floorplanSrc} />
                    </div>
                    <div className="buttonWrap">
                      <Favourite
                        className="svgFavourite"
                        onClick={() => {
                          syncState(
                            "favorites",
                            favorites?.length === 1
                              ? [""]
                              : favorites.includes(favorite)
                              ? favorites.filter((item) => item !== favorite)
                              : [...favorites, favorite]
                          )
                        }}
                        active
                      />
                      <Search
                        onClick={() => expandView(favorite)}
                        className="svgSearch"
                      />
                    </div>
                  </EmblaSlide>
                )
            })}
        </EmblaContainer>
      </Embla>
      <ShareContainer
        style={{
          opacity: showShareFavorites ? 1 : 0,
          pointerEvents: showShareFavorites ? "auto" : "none",
        }}
      >
        <div className="leftImage">
          <img alt="The Boardwalk" src="/media/favorites/hero.webp" />
        </div>
        <div className="rightForm">
          <div className="stampContainer">
            {qrCodeData ? (
              <img src={qrCodeData} alt="QR Code" />
            ) : (
              <SvgFavoritesShareStamp />
            )}
          </div>
          <Form favorites={favorites} />
        </div>
      </ShareContainer>
    </FavoritesSection>
  )
}

const FavoritesSection = styled.section<{ share: boolean }>`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey};
  display: grid;

  grid-template-rows: minmax(0, 0.5fr) minmax(0, 8fr) minmax(0, 0.5fr);
  grid-template-columns:
    minmax(0, 0.2fr) minmax(0, 2fr) minmax(0, 0.5fr) minmax(0, 3fr) minmax(
      0,
      1.5fr
    )
    minmax(0, 0.2fr);

  svg {
    height: 100%;
  }

  #FavoritesLeftArrow {
    max-height: 80%;
    width: 80%;
    grid-column: 1;
    transition: opacity 1s;
    /* grid-row: 2; */
  }
  #FavoritesRightArrow {
    max-height: 80%;
    width: 80%;
    grid-column: 2;
    transition: opacity 1s;
    /* grid-row: 2; */
  }
  #FavoritesRefresh {
    max-height: 80%;
    width: 80%;
    grid-column: 3;
    transition: opacity 1s;
    /* grid-row: 2; */
  }
  #FavoritesShare {
    max-height: 80%;
    width: 80%;
    grid-column: 4;

    .FavoritesShareHeart {
      opacity: ${(props) => (props.share ? 0 : 1)};
    }
    .FavoritesShareArrow {
      opacity: ${(props) => (props.share ? 1 : 0)};
    }
    /* grid-row: 2; */
  }
  .NoFavoritesTest {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    text-align: center;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    color: ${({ theme }) => theme.colors.green};
    font-family: "Dreamer Bold";
    font-size: 3rem;
  }
`

const FavoritesButtons = styled.div`
  height: 100%;
  grid-column: 5;
  grid-row: 1;
  display: grid;
  place-items: center end;
  grid-auto-flow: column;
  column-gap: 1rem;
  padding: 0.8rem;

  & svg {
    height: 100%;
    z-index: 7;
    cursor: pointer;
  }

  .refresh {
    /* transform: scale(0.95); */

    path {
      fill: ${globalStyles.colors.white};
      stroke: ${globalStyles.colors.white};

      :nth-of-type(1) {
        /* fill: ${globalStyles.colors.green}; */
        fill: #799b90;
      }
    }
  }
`

const Embla = styled.section<Types>`
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 2;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 3;

  & img {
    pointer-events: none;
  }
`

const EmblaContainer = styled.div<Types>`
  padding: 0rem 4rem;
  transition: opacity 0.2s;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  column-gap: 1vw;
  grid-auto-flow: column;
  grid-auto-columns: 40%;
  /* margin-left: -25%; */
`

const EmblaSlide = styled.div<Types>`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(0, 0.5fr) minmax(0, 1fr) repeat(4, minmax(0, 1fr))
    minmax(0, 1fr) minmax(0, 0.5fr);
  grid-template-rows: ${(props) =>
    props.index % 2 === 0
      ? "minmax(0, 1fr) minmax(0, 3fr) minmax(0, 1.5fr) minmax(0, 3fr) minmax(0, 6fr) minmax(0, 6fr) minmax(0, 2fr)"
      : "minmax(0, 2fr) minmax(0, 6fr) minmax(0, 6fr) minmax(0, 3fr) minmax(0, 1.5fr) minmax(0, 3fr) minmax(0, 1fr)"};
  background-color: ${(props) => props.theme.colors[props.color]};
  font-family: "Gilmer Light", sans-serif;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.white};
  margin: 1vw;
  overflow: hidden;

  @media (max-width: 3000px) {
    grid-template-columns:
      minmax(0, 2fr) minmax(0, 1.5fr) repeat(4, minmax(0, 1fr))
      minmax(0, 1.5fr) minmax(0, 2fr);
  }

  .bottomLeftBg {
    grid-column: 1 / 5;
    grid-row: ${(props) => (props.index % 2 === 0 ? "6 / -1" : "1 / 3")};
    height: 100%;
    width: 100%;
    opacity: 0.1;
    mix-blend-mode: screen;
    background-color: ${(props) => props.theme.colors[props.color]};
  }

  .bottomRightBg {
    grid-column: 5 / -1;
    grid-row: ${(props) => (props.index % 2 === 0 ? "6 / -1" : "1 / 3")};
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
    opacity: 0.2;
    background-color: ${(props) => props.theme.colors[props.color]};
  }

  .title {
    grid-column: 1 / -1;
    grid-row: ${(props) => (props.index % 2 === 0 ? "2" : "4")};
    height: 100%;
    width: 100%;
    font-family: "Dreamer Bold", serif;
    font-weight: 400;
    font-size: clamp(1rem, 5vw, 6rem);
    display: grid;
    place-items: center;
  }

  .subtitle {
    grid-column: 1 / -1;
    grid-row: ${(props) => (props.index % 2 === 0 ? "3" : "5")};
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    font-size: 1.6rem;
    letter-spacing: 0.3rem;
    font-family: "Gilmer", sans-serif;
  }

  .information {
    grid-column: 2 / -2;
    grid-row: ${(props) => (props.index % 2 === 0 ? "4" : "6")};
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    place-items: center;
    text-align: center;
    font-size: 1.3rem;

    .informationLeft {
      grid-column: 1;
      height: 60%;
      width: 100%;
      display: grid;
      place-items: center;
      grid-auto-flow: row;
      p {
        display: grid;
        place-items: center;
        height: 100%;
        width: 100%;
      }
    }

    .informationRight {
      grid-column: 2;
      height: 60%;
      width: 100%;
      display: grid;
      place-items: center;

      p {
        display: grid;
        place-items: center;
      }
    }
  }

  .imageWrap {
    grid-column: 3 / -3;
    grid-row: ${(props) => (props.index % 2 === 0 ? "5 / 7" : "2 / 4")};
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    background-color: white;
    z-index: 5;
    overflow: hidden;
    pointer-events: auto;

    @media (max-width: 3000px) {
      grid-column: 2 / -2;
    }

    & img {
      object-fit: contain;
      height: 95%;
      width: 100%;
      display: grid;
      place-items: center;
      overflow: hidden;
      /* touch-action: pinch-zoom; */
      /* pointer-events: auto; */
    }
  }

  .buttonWrap {
    grid-column: ${(props) => (props.index % 2 === 0 ? "2" : "7")};
    grid-row: ${(props) => (props.index % 2 === 0 ? "6" : "2")};
    row-gap: 1rem;
    width: 80%;
    place-self: ${(props) => (props.index % 2 === 0 ? "end" : "start")};
    display: grid;
    place-items: ${(props) =>
      props.index % 2 === 0 ? "end center" : "start centet"};
    grid-auto-flow: row;
    padding: 0rem 1rem;

    @media (max-width: 3000px) {
      grid-column: ${(props) => (props.index % 2 === 0 ? "1" : "8")};
    }

    & svg {
      cursor: pointer;
      z-index: 6;
      width: 100%;
      height: auto;
    }
  }
`

const FullImage = styled.img<Types>`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const ShareContainer = styled.div<Types>`
  transition: 1s;
  height: 100%;
  width: 100%;
  grid-row: 2;
  grid-column: 2 / -2;
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-flow: column;

  .leftImage {
    height: 100%;
    width: 100%;
    overflow: hidden;

    & img {
      height: 100%;
      width: 100%;
    }
  }

  .rightForm {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: minmax(0, 0.5fr) minmax(0, 0.7fr) minmax(0, 0.5fr);
    padding: 4rem;

    .stampContainer {
      height: 100%;
      width: 100%;
      grid-row: 1;
      place-self: start end;
      display: grid;
      place-items: start end;
      & svg {
        height: 70%;
        width: auto;
      }
    }

    & form {
      height: 100%;
      width: 100%;
      grid-row: 2;
      display: grid;
      place-items: center;
      grid-auto-flow: row;
      text-align: center;
      pointer-events: auto;
      z-index: 5;

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }

      .spinner {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 0 auto;
        animation: rotate 1s infinite linear;
      }

      .spinner::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid #ccc;
        border-top-color: #000;
        animation: spin 1s infinite linear;
      }

      .inputWrap {
        height: 100%;
        width: 60%;
        display: grid;
        place-items: center;
        border-bottom: 2px solid ${(props) => props.theme.colors.black};
        & input {
          all: unset;
          height: 100%;
          width: 100%;
          font-size: 1.5rem;
          color: ${(props) => props.theme.colors.black};
          padding: 1rem 1rem;
        }
        &::placeholder {
          color: ${(props) => props.theme.colors.grey};
          font-family: "Gilmer Medium", serif;
          font-size: 6rem;
          letter-spacing: 0.2rem;
        }
      }

      & button {
        all: unset;
        font-family: "Dreamer Bold", serif;
        font-size: 2.3rem;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.black};
        border: 2px solid ${(props) => props.theme.colors.black};
        border-radius: 50px;
        padding: 1rem 4rem;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
          background-color: ${(props) => props.theme.colors.grey};
        }
      }

      & button:disabled {
        background-color: ${(props) => props.theme.colors.grey};
        color: ${(props) => props.theme.colors.grey};
        opacity: 0.3;
        pointer-events: none;
        cursor: none;
      }
    }
  }
`
