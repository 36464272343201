export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M13 0C5.824 0 0 5.9696 0 13.325C0 20.6804 5.824 26.65 13 26.65C20.176 26.65 26 20.6804 26 13.325C26 5.9696 20.176 0 13 0ZM13 15.99L7.8 10.66H18.2L13 15.99Z"
        fill="white"
      />
    </svg>
  )
}
