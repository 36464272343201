export default function SVG({ className, click, ...props }: any) {
  return (
    <svg
      width="46"
      height="34"
      viewBox="0 0 46 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={click}
      {...props}
    >
      <path
        d="M45 16.8361C45 25.5819 37.9101 32.6719 29.1642 32.6719L16.8358 32.6719C8.08994 32.6719 0.999999 25.5819 0.999999 16.8361C0.999998 8.09018 8.08993 1.00024 16.8358 1.00024L29.1642 1.00024C37.9101 1.00024 45 8.09018 45 16.8361Z"
        fill="#799B90"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M9.89588 15.8007C9.50536 16.1912 9.50536 16.8244 9.89588 17.2149L16.2598 23.5789C16.6504 23.9694 17.2835 23.9694 17.6741 23.5789C18.0646 23.1884 18.0646 22.5552 17.6741 22.1647L12.0172 16.5078L17.6741 10.851C18.0646 10.4604 18.0646 9.82727 17.6741 9.43675C17.2835 9.04622 16.6504 9.04622 16.2598 9.43675L9.89588 15.8007ZM35.2342 15.5078L10.603 15.5078L10.603 17.5078L35.2342 17.5078L35.2342 15.5078Z"
        fill="white"
      />
    </svg>
  )
}
