// @ts-nocheck
import ReactDOM from "react-dom/client"
import { invoke } from "@tauri-apps/api"
import App from "./App"
import "./style.css"

import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { routes, auxRoutes } from "@routes/routes"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === "/",
      path: route.path === "/" ? undefined : route.path,
      element: route.element,
    })),
  },
])

const auxRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: auxRoutes.map((route) => ({
      index: route.path === "/",
      path: route.path === "/" ? undefined : route.path,
      element: route.element,
    })),
  },
])

if (!(window.__TAURI__ === undefined)) {
  invoke("get_window_label")
    .then((label: any) => {
      if (label.includes("screen")) {
        const room = label.split("-")[0]
        const screen = label.split("-")[1]

        if (room === "room1") {
          if (screen === "screen1") {
            ReactDOM.createRoot(document.getElementById("root")).render(
              <RouterProvider router={router} />
            )
          } else {
            ReactDOM.createRoot(document.getElementById("root")).render(
              <RouterProvider router={auxRouter} />
            )
          }
        } else {
          ReactDOM.createRoot(document.getElementById("root")).render(
            <RouterProvider router={router} />
          )
        }
      }
    })
    .catch((err) => {
      console.error("Error getting window label:", err)
    })
} else {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
  )
}
