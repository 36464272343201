import { Canvas } from "@react-three/fiber"
import { Suspense, useRef } from "react"
import styled from "styled-components"
import Assetmanager from "@canvas_components/Assetmanager/Assetmanager"
import WebGlErrorCatch from "@canvas_components/WebGlErrorCatch/WebGlErrorCatch"
import ErrorBoundary from "@components/ErrorCatch/ErrorCatch"
import { useStore } from "@state/store"
import CameraControls from "@canvas_components/CameraControls"
import { useLoadingStore } from "@state/loadingStore"
import { HandleLoadingProgress } from "@utils/loading"
import { Environment } from "@react-three/drei"
import { Perf } from "r3f-perf"

import { useDeviceStore } from "@state/deviceStore"
import StaticCompass from "@canvas_components/StaticCompass/StaticCompass"
type Props = {}

const TCanvas = (props: Props) => {
  const controlsCatcher = useStore((s) => s.controlsCatcher)
  const setProgress = useLoadingStore((s) => s.setProgress)
  const nodeRef = useRef(null)
  const canvasRef = useRef(null)

  const isIpad = useDeviceStore((s) => s.isIpad)

  const handleLoading = (v) => {
    setProgress(v)
  }

  return (
    <CanvasWrapper ref={nodeRef}>
      <ErrorBoundary>
        <Suspense
          fallback={
            <HandleLoadingProgress
              handleLoading={handleLoading}
              totalAssets={35}
              logassets={false}
            />
          }
        >
          <Canvas
            ref={canvasRef}
            dpr={isIpad ? 1.25 : 1.75}
            onCreated={(state) => state.events.connect(controlsCatcher)}
            camera={{ near: 0.02, far: 10, position: [1.4, 1.4, 1.4] }}
          >
            <StaticCompass />
            {/* <WebGlErrorCatch /> */}
            {/* <Perf
              style={{ margin: "10rem" }}
              openByDefault
              trackGPU={true}
              position={"bottom-left"}
            /> */}
            <ambientLight />
            <CameraControls />
            <Assetmanager />
            <Environment
              files="/media/residences/residences.hdr"
              blur={0.1}
              background={true}
            />
          </Canvas>
        </Suspense>
      </ErrorBoundary>
    </CanvasWrapper>
  )
}

export default TCanvas

const CanvasWrapper = styled.div`
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.grey};
  pointer-events: auto;
  & canvas {
    pointer-events: auto;
  }
`
