import { useRef } from "react"

//Data
import globalStyles from "../../style.global"
import useSocketStore from "@state/useSocketStore"

export default function SVG({
  x,
  y,
  color,
  categoryid,
  currentpoi,
  pointOfInterestid,
  pointofinterestid,
  // arrowDirection,
  className,
  ...props
}: any) {
  const ref = useRef<any>(null)
  const activePOICategory = useSocketStore((state) => state.activePOICategory)
  const activePOIName = useSocketStore((state) => state.activePOIName)
  const mapExpanded = useSocketStore((state) => state.mapExpanded)
  const syncState = useSocketStore((state) => state.syncState)

  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      viewBox="0 0 3225 2030"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id="viewBoxSVG"
      {...props}
      onClick={() =>
        // String(activePOICategory) === String(categoryid) &&
        // setActivePOICategory(String(categoryid)),
        // syncState("activePOICategory", String(categoryid)),
        // String(activePOICategory) === String(categoryid) &&
        mapExpanded &&
        syncState(
          "activePOIName",
          String(activePOIName) === String(categoryid + "_" + pointofinterestid)
            ? ""
            : String(categoryid + "_" + pointofinterestid)
        )
      }
    >
      <g transform={`translate(${x}, ${y}), scale(4)`}>
        <circle
          cx="10"
          cy="10"
          r="10"
          style={{
            pointerEvents:
              String(activePOICategory) === String(categoryid) ||
              String(activePOICategory) === ""
                ? "auto"
                : "none",

            fill:
              String(currentpoi) ===
              String(categoryid + "_" + pointofinterestid)
                ? globalStyles.colors.black
                : globalStyles.colors[color],
          }}
        />
        <text
          fontSize="smaller"
          x="10"
          y="10"
          fill="white"
          textAnchor="middle"
          alignmentBaseline="central"
          style={{ pointerEvents: "none" }}
        >
          {pointofinterestid}
        </text>
      </g>
    </svg>
  )
}
