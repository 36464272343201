// import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {
  //   const SplitParagraph = new SplitType(".Paragraph", {
  //     types: "words",
  //   })
  //   const SplitEnter = new SplitType(".Enter", {
  //     types: "chars",
  //   })
  // timeline.set(["SlideImage1", "SlideImage2", "SlideImage3", "SlideImage4"], {
  //   autoAlpha: 0,
  //   scale: 1.2,
  // })
}

export const timelineAnimation = ({ timeline, index }) => {
  timeline.to(`.SVGTitle${index} path`, {
    autoAlpha: 1,
    duration: 1,
    stagger: 0.4,
    clipPath: "circle(141.2% at 100% 0)",
    ease: "power1.inOut",
  })
  timeline.to(
    `.SlideImage${index}`,
    {
      scale: 1,
      duration: 2,
      ease: "power1.inOut",
    },
    "<"
  )
  // timeline.to(
  //   `.SlideImage${index}`,
  //   {
  //     autoAlpha: 1,
  //     duration: 2,
  //   },
  //   "<"
  // )
  timeline.to(
    `.Paragraph${index}`,
    {
      autoAlpha: 1,
      duration: 2.5,
      delay: 0.8,
      ease: "power1.inOut",
    },
    "<"
  )
}
