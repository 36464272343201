import { useGLTF } from "@lib/useGLTF"
import { useLocation } from "react-router-dom"
import { Color, DoubleSide } from "three"
import { a } from "@react-spring/three"
import { useTransition } from "@react-spring/core"
import useSocketStore from "@state/useSocketStore"

type Props = {
  nodes: any[]
}

const View360Label = ({ pos, scale, click, active, area }) => {
  const { nodes } = useGLTF("/models/Amenities/View360v8.glb")

  const transition = useTransition(active, {
    from: { opacity: 0 },
    enter: { opacity: 1, delay: 1500 },
    leave: { opacity: 0 },
  })

  return (
    <group position={pos} scale={scale} onClick={(e) => click(e, area)}>
      {transition(
        ({ opacity }, item) =>
          item && (
            <>
              {Object.entries(nodes)
                .filter(([key]) => key.startsWith("Curve"))
                .map(([node]) => (
                  <mesh key={node} geometry={nodes[node].geometry}>
                    <a.meshBasicMaterial
                      transparent
                      opacity={opacity}
                      color="#fff"
                      side={DoubleSide}
                    />
                  </mesh>
                ))}
              {Object.entries(nodes)
                .filter(([key]) => key === "Cylinder")
                .map(([node]) => (
                  <mesh key={node} geometry={nodes[node].geometry}>
                    <a.meshBasicMaterial
                      transparent
                      opacity={opacity}
                      color="#fff"
                      side={DoubleSide}
                    />
                  </mesh>
                ))}
              {Object.entries(nodes)
                .filter(([key]) => key === "Cylinder002")
                .map(([node]) => (
                  <mesh key={node} geometry={nodes[node].geometry}>
                    <a.meshMatcapMaterial
                      transparent
                      toneMapped={false}
                      opacity={opacity}
                      color="#7d99f8"
                      side={DoubleSide}
                    />
                  </mesh>
                ))}
              {Object.entries(nodes)
                .filter(([key]) => key.startsWith("Button"))
                .map(([node]) => (
                  <mesh key={node} geometry={nodes[node].geometry}>
                    <a.meshMatcapMaterial
                      transparent
                      toneMapped={false}
                      opacity={opacity}
                      color="#7d99f8"
                      side={DoubleSide}
                    />
                  </mesh>
                ))}
            </>
          )
      )}
    </group>
  )
}

export default View360Label
