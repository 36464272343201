export default function SVG({ className, active, ...props }: any) {
  return (
    <svg
      width="57"
      height="42"
      viewBox="0 0 57 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M0.5 20.8939C0.5 9.63064 9.63064 0.5 20.8939 0.5H35.3916C46.6549 0.5 55.7855 9.63064 55.7855 20.8939C55.7855 32.1571 46.6548 41.2877 35.3916 41.2877H20.8938C9.63062 41.2877 0.5 32.1571 0.5 20.8939Z"
        stroke="white"
        fill={active ? "#f6f6f6" : "#F6F6F600"}
      />
      <path
        d="M29.8275 28.8374C29.768 28.894 29.7082 28.9509 29.6481 29.008C29.6476 29.0085 29.6471 29.009 29.6466 29.0095L28.5691 30.0168L27.4915 29.0095C27.491 29.009 27.4904 29.0085 27.4899 29.008C27.4287 28.9498 27.3677 28.8918 27.3071 28.8341C24.8553 26.5017 22.8559 24.5997 21.4536 22.8136C20.0232 20.9917 19.2617 19.3481 19.2617 17.5631C19.2617 14.6496 21.411 12.4395 24.1555 12.4395C25.7121 12.4395 27.1908 13.1823 28.1809 14.4018L28.5691 14.8799L28.9572 14.4017C29.9471 13.1823 31.4257 12.4395 32.9825 12.4395C35.7273 12.4395 37.8764 14.6495 37.8764 17.5631C37.8764 19.3481 37.1149 20.9918 35.6845 22.8137C34.2816 24.6008 32.2809 26.5037 29.8275 28.8374Z"
        stroke="white"
        fill={active ? "#c27b3c" : "#c27b3c000"}
      />
    </svg>
  )
}
