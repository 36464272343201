import styled from "styled-components"

import ErrorMessage from "@pages/ErrorMessage"

import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"

export default function Amenities() {
  const auxScreen = useDeviceStore((s) => s.auxScreen)
  const amenitiesExpanded = useSocketStore((state) => state.amenitiesExpanded)
  const currentAmenityIndex = useSocketStore(
    (state) => state.currentAmenityIndex
  )

  const paths = {
    left: {
      initial: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
      final: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    },
    right: {
      initial: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
      final: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    },
  }

  return (
    <>
      <ErrorMessage />
      <AmenitiesSection
        style={{
          clipPath: amenitiesExpanded
            ? paths[auxScreen]?.final
            : paths[auxScreen]?.initial,
        }}
      >
        {auxScreen === "left" ? (
          <ExpandedImage>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
              <img
                key={number}
                src={`/media/amenities/${number}.webp`}
                alt={`Image ${number}`}
                style={{
                  opacity: currentAmenityIndex === number ? 1 : 0,
                }}
              />
            ))}
          </ExpandedImage>
        ) : (
          <ExpandedImage>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
              <img
                key={number}
                src={`/media/amenities/0b.webp`}
                alt={`Image ${number}`}
                style={{
                  opacity: currentAmenityIndex === number ? 1 : 0,
                }}
              />
            ))}
          </ExpandedImage>
        )}
      </AmenitiesSection>
    </>
  )
}

const AmenitiesSection = styled.section`
  position: absolute;
  z-index: 13;
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  transition: clip-path 0.8s ease-in;
`

const ExpandedImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  & img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.8s 0.5s ease-in;
    object-fit: cover;
  }
`
