export default function SVG({ className, click, ...props }: any) {
  return (
    <svg
      width="57"
      height="42"
      viewBox="0 0 57 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={() => click()}
      {...props}
    >
      <path
        d="M21 0.5H35.553C46.8748 0.5 56.0529 9.67816 56.0529 21C56.0529 32.3218 46.8748 41.5 35.5529 41.5H21C9.67815 41.5 0.5 32.3218 0.5 21C0.5 9.67816 9.67816 0.5 21 0.5Z"
        stroke="white"
      />
      <path
        d="M34.6315 19.2874C34.6315 23.5086 31.2107 26.9302 26.9913 26.9302C22.7719 26.9302 19.3511 23.5086 19.3511 19.2874C19.3511 15.0662 22.7719 11.6445 26.9913 11.6445C31.2107 11.6445 34.6315 15.0662 34.6315 19.2874Z"
        stroke="white"
      />
      <line
        x1="0.5"
        y1="-0.5"
        x2="10.4075"
        y2="-0.5"
        transform="matrix(0.777431 0.628968 -0.62872 0.777632 32.1768 24.4277)"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  )
}
