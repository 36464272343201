import { Link } from "react-router-dom"
import styled from "styled-components"
import PrimaryLogo from "@components/SVG/PrimaryLogo"
import { useRef } from "react"
import { useAnimate } from "@utils/hooks"
import { setTimelineAnimation, timelineAnimation } from "@animations/Landing"
import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"
import IntroVideo from "@components/IntroVideo/IntroVideo"

export default function Landing() {
  const gsapRef = useRef()

  const favorites = useSocketStore((s) => s.favorites)
  const { room, connectSocket, emitSocketEvent } = useSocketStore()
  const auxScreen = useDeviceStore((s) => s.auxScreen)
  const isIpad = useDeviceStore((s) => s.isIpad)

  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })

  function handleRoomClick(txt) {
    const roomName = String("room" + String(txt))
    if (room === null) {
      connectSocket({
        room: roomName,
        favs: favorites,
        path: window.location.pathname,
        isIpad,
      })
    } else {
      if (room !== roomName) {
        emitSocketEvent("switchRoom", {
          newRoom: roomName,
          oldRoom: room,
          favs: favorites,
          path: window.location.pathname,
          isIpad,
        })
      }
    }
    setTimeout(
      () =>
        emitSocketEvent("navigation", {
          path: "/home",
          room: roomName,
        }),
      100
    )
  }

  return (
    <>
      <IntroVideo />
      <LandingWrapper ipad={isIpad} ref={gsapRef}>
        {(auxScreen === "center" || !auxScreen) && (
          <>
            <Logo>
              <SVGLogo className="SVGLogo" />
            </Logo>
            <SubtitleWrapper>
              <p className="Subtitle">LUXURIOUS OCEANFRONT CONDOMINIUMS</p>
            </SubtitleWrapper>
            <EnterButton className="Enter">TAP ROOM TO ENTER</EnterButton>
            <ButtonsWrapper>
              {isIpad &&
                [1, 2, 3].map((room) => (
                  <Link key={room} to="/home">
                    <button
                      id="RoomButton"
                      style={{ visibility: "hidden" }}
                      onClick={() => handleRoomClick(room)}
                    >
                      ROOM {room}
                    </button>
                  </Link>
                ))}
            </ButtonsWrapper>
          </>
        )}
      </LandingWrapper>
    </>
  )
}

const LandingWrapper = styled.div<{ ipad: boolean }>`
  position: absolute;
  height: 110vh;
  height: 110dvh;
  width: 100%;
  margin-top: -10vh;
  margin-top: -10dvh;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: ${(props) =>
    props.ipad
      ? "minmax(0, 3fr) minmax(0, 2fr) minmax(0, 0.2fr) minmax(0, 0.4fr) minmax(0, 0.2fr) minmax(0, 2fr) minmax(0, 4fr)"
      : "minmax(0, 4fr) minmax(0, 2fr) minmax(0, 0.2fr) minmax(0, 0.5fr) minmax(0, 0.2fr) minmax(0, 1fr) minmax(0, 3fr)"};
  row-gap: 2rem;
  text-decoration: none;
  z-index: 12;
`

const Logo = styled.div`
  grid-column: 1 / -1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  place-self: center;
  z-index: 13;
  display: grid;
  place-items: center;

  & svg {
    height: auto;
    width: 60%;
  }

  & path {
    fill: ${(props) => props.theme.colors.white};
    visibility: hidden;
  }
`

const SVGLogo = styled(PrimaryLogo)``

const SubtitleWrapper = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Gilmer", sans-serif;
  font-weight: 400;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.sm};
  grid-column: 1 / -1;
  grid-row: 4;
  display: grid;
  place-items: center;
  place-self: center;
  z-index: 13;
  & p .char {
    visibility: hidden;
    margin: 0 0.1rem;
  }
`

const EnterButton = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-size: clamp(1rem, 0.8vw, 2rem);
  grid-column: 1 / -1;
  grid-row: 6;
  place-self: center;
  z-index: 13;
  font-family: "Gilmer", sans-serif;
  font-weight: 400;
  .char {
    visibility: hidden;
  }
`

const ButtonsWrapper = styled.div`
  height: 100%;
  width: 60%;
  margin: 0 auto;
  grid-column: 1 / -1;
  grid-row: 7;
  display: grid;
  place-items: center top;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  & a {
    all: unset;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center top;
    column-gap: 4rem;
  }
  & button {
    all: unset;
    width: 70%;
    height: 3rem;
    text-align: center;
    text-decoration: none;

    color: ${(props) => props.theme.colors.white};
    border-radius: 8rem;
    border: 0.2rem solid ${(props) => props.theme.colors.white};
    padding: 2rem 2rem;
    font-size: 2rem;
    display: grid;
    place-items: center top;
    vertical-align: middle;
    align-content: center;
  }
`
