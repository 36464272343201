import TCanvas from "@canvas_components/TCanvas"
import ControlsCatcher from "@components/ControlsCatcher"
import styled, { ThemeProvider } from "styled-components"
import Nav from "@components/Nav/Nav"
import { routes } from "./routes/routes"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useLocation, useOutlet, useNavigate } from "react-router-dom"
import globalStyles from "./style.global"
import { useEffect } from "react"
import { invoke } from "@tauri-apps/api"
import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"
import { InactivityDetector } from "@utils/hooks"
import Circles from "@components/Circles/Circles"
import fetchApartments from "@utils/api"

import trimData from "@utils/trimData"
import { r } from "@tauri-apps/api/clipboard-79413165"
declare global {
  interface Window {
    __TAURI__: any
  }
}

function DefaultLayout() {
  const auxScreen = useDeviceStore((s) => s.auxScreen)
  // const setApartments = useDeviceStore((s) => s.setApartments)

  // fetch(`https://api-gateway.vmiservers.com/boardwalk/units`)
  //   .then((res) => res.json())
  //   .then((res) => setApartments(trimData(res.payload)))
  //   .catch((error) => {
  //     console.error(
  //       "There has been a problem with your fetch operation:",
  //       error
  //     )
  //   })

  fetchApartments()

  return (
    <ThemeProvider theme={globalStyles}>
      <Wrapper>
        {(!auxScreen || auxScreen === "center") && <Nav />}
        {(!auxScreen || auxScreen === "center") && <TCanvas />}
        {(!auxScreen || auxScreen === "center") && <ControlsCatcher />}
        <DOM />
      </Wrapper>
    </ThemeProvider>
  )
}

const DOM = () => {
  const location = useLocation()
  const currentOutlet = useOutlet()
  const { nodeRef }: any =
    routes.find((route) => route.path === location.pathname) ?? {}
  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={1000}
          classNames="fade"
          unmountOnExit
        >
          <PageWrapper ref={nodeRef}>{currentOutlet}</PageWrapper>
        </CSSTransition>
      </SwitchTransition>
      <InactivityDetector />
      <SocketSetup />
      <Circles />
    </>
  )
}

const SocketSetup = () => {
  const navigate = useNavigate()
  const setIsIpad = useDeviceStore((s) => s.setIsIpad)
  const setHasAux = useDeviceStore((s) => s.setHasAux)
  const setPath = useSocketStore((s) => s.setPath)
  const socket = useSocketStore((s) => s.socket)
  const connectSocket = useSocketStore((s) => s.connectSocket)
  const setAuxScreen = useDeviceStore((s) => s.setAuxScreen)

  const syncState = useSocketStore((s) => s.syncState)

  useEffect(() => {
    window.__TAURI__ === undefined ? setIsIpad(true) : setIsIpad(false)
  }, [])

  useEffect(() => {
    if (!(window.__TAURI__ === undefined)) {
      invoke("get_window_label")
        .then((label: any) => {
          if (label.includes("screen")) {
            const room = label.split("-")[0]
            const screen = label.split("-")[1]
            connectSocket({
              room: room,
              isIpad: false,
            })
            if (room === "room1") {
              setHasAux(true)

              setAuxScreen(
                screen === "screen2"
                  ? "left"
                  : screen === "screen3"
                  ? "right"
                  : screen === "screen1"
                  ? "center"
                  : null
              )
            }
          }
        })
        .catch((err) => {
          console.error("Error getting window label:", err)
        })
    }
  }, [])

  useEffect(() => {
    if (!(window.__TAURI__ === undefined)) {
      invoke("get_window_label")
        .then((label: any) => {
          if (label.includes("screen") && socket === null) {
            const room = label.split("-")[0]
            connectSocket({
              room: room,
              isIpad: false,
            })
          }
        })
        .catch((err) => {
          console.error("Error getting window label:", err)
        })

      socket?.on("updateStore", (data) => {
        if (data.path) {
          setPath(data.path)
          navigate(data.path)
        }

        if (data.favs) {
          syncState("favorites", data.favs)
        }
      })
    }
  }, [socket])

  return null
}
export default DefaultLayout
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(0, 6fr) minmax(0, 94fr);

  .circle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: none;
    mix-blend-mode: exclusion;
    border: ${(props) => props.theme.colors.pink} 0.001rem solid;
    border-radius: 50%;
    z-index: 100;
    animation: scaleFadeOut 1s ease-in forwards;
    pointer-events: none;
  }

  @keyframes scaleFadeOut {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(2.5);
    }
    100% {
      opacity: 0;
      transform: scale(4);
    }
  }
`
const PageWrapper = styled.div`
  grid-row: 2;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
`
const Room = styled.div`
  z-index: 1;
  & #favourites-heart {
    fill: ${(props) => props.theme.colors.pink};
    transition: 1s;
  }
  & svg {
    height: 3em;
    width: 3em;
    & path {
      stroke: ${(props) => props.theme.colors.pink};
      transition: 1s;
    }
  }
`
