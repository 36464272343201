import shaderMaterial from "@lib/dreiShaderMaterial"
import { DoubleSide, Mesh, Texture } from "three"
import { extend, useThree } from "@react-three/fiber"
import { a } from "@react-spring/three"
import { useTransition } from "@react-spring/core"
import { useState, useEffect, useRef } from "react"

//@ts-ignore
import vertex from "./glsl/vertex.glsl"
//@ts-ignore
import fragment from "./glsl/fragment.glsl"
import { useLocation } from "react-router-dom"
import { useTexture } from "@react-three/drei"
import useSocketStore from "@state/useSocketStore"

import { ShaderMaterial } from "three"

import map_000 from "/models/building/maps/map_000.jpg"
import map_001 from "/models/building/maps/map_001.jpg"
import map_003 from "/models/building/maps/map_003.jpg"
import map_004 from "/models/building/maps/map_004.jpg"
import map_005 from "/models/building/maps/map_005.jpg"
import map_006 from "/models/building/maps/map_006.jpg"
import map_007 from "/models/building/maps/map_007.jpg"

import { useLoader } from "@react-three/fiber"
import { TextureLoader } from "three"
useLoader.preload(TextureLoader, [
  map_000,
  map_001,
  map_003,
  map_004,
  map_005,
  map_006,
  map_007,
])

// Import shaders
const BakedShader2 = shaderMaterial(
  {
    uMap: new Texture(),
    uOpacity: 0,
  },
  vertex,
  fragment,

  (material) => {
    material.side = DoubleSide
  }
)

extend({ BakedShader2 })

//@ts-ignore
const AnimatedBakedShader2 = a("bakedShader2")

// --- Shaders
export default function BuildingModel({ nodes }) {
  const { pathname } = useLocation()

  // const { gl, set, scene } = useThree()
  // const webglContext = gl.getContext()

  const residencePopups = useSocketStore((s) => s.residencePopups)

  const [elements, setElements] = useState(null)

  useEffect(() => {
    if (pathname === "/residences" && !residencePopups.residenceContainerOpen) {
      setElements(nodes)
    } else {
      setElements(null)
    }
  }, [pathname, residencePopups])

  const [shouldDelay, setShouldDelay] = useState(true)

  useEffect(() => {
    if (residencePopups.residenceContainerOpen) {
      setShouldDelay(false)
    } else {
      setTimeout(() => {
        setShouldDelay(true)
      }, 2500)
    }
  }, [residencePopups.residenceContainerOpen])

  const transition = useTransition(elements, {
    from: {
      opacity: 0,
      delay: shouldDelay ? 1000 : 0,
    },
    enter: {
      opacity: 1,
      delay: shouldDelay ? 1000 : 1000,
      config: { duration: 500, clamp: true },
    },
    leave: {
      opacity: 0,
      delay: shouldDelay ? 0 : 300,
      config: { duration: 500, clamp: true },
    },
  })

  useEffect(() => {
    if (pathname === "/residences") {
      setTimeout(() => {
        gRef.current.position.y = 0
      }, 10)
    } else {
      setTimeout(() => {
        gRef.current.position.y = 10
      }, 1000)
    }
  }, [pathname])

  const gRef = useRef() as any

  return (
    <>
      <group ref={gRef}>
        {transition(
          ({ opacity }, item) =>
            item && <MeshElement node={item} opacity={opacity} />
        )}
      </group>
    </>
  )
}

const MeshElement = ({ node, opacity }) => {
  //TODO MOVE UP
  const texture = useTexture(
    `/models/building/maps/${node.name.replace("mesh", "map")}.jpg`
  )
  texture.flipY = false
  return (
    <mesh geometry={node.mesh_0.geometry}>
      {/* @ts-ignore */}
      <AnimatedBakedShader2 uMap={texture} transparent uOpacity={opacity} />
    </mesh>
  )
}
