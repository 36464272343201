export default function SVG({ className, click, ...props }: any) {
  return (
    <svg
      width="46"
      height="34"
      viewBox="0 0 46 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
      onClick={() => click()}
    >
      <path
        d="M16.8358 1L29.1642 1.00001C37.9101 1.00001 45 8.08995 45 16.8358C45 25.5817 37.9101 32.6716 29.1642 32.6716L16.8358 32.6716C8.08993 32.6716 0.999996 25.5817 0.999997 16.8358C0.999999 8.08994 8.08994 1 16.8358 1Z"
        fill="#799B90"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M36.1039 17.8712C36.4945 17.4806 36.4945 16.8475 36.1039 16.457L29.74 10.093C29.3495 9.70247 28.7163 9.70247 28.3258 10.093C27.9352 10.4835 27.9352 11.1167 28.3258 11.5072L33.9826 17.1641L28.3258 22.8209C27.9352 23.2114 27.9352 23.8446 28.3258 24.2351C28.7163 24.6257 29.3494 24.6257 29.74 24.2351L36.1039 17.8712ZM10.7656 18.1641L35.3968 18.1641L35.3968 16.1641L10.7656 16.1641L10.7656 18.1641Z"
        fill="white"
      />
    </svg>
  )
}
