import { InitialState } from "./types.store"

export const defaultCameraSettings = {
  position: [0.6, 0.6, 0.6],
  target: [0, 0, 0],
  fov: 75,
  delay: 100,
  duration: 2500,
  rotateEnabled: true,
  zoomEnabled: true,
  enabled: true,
}

export const initialState: InitialState = {
  activeUID: null,
  viewBox: {
    x: 0,
    y: 0,
    w: 3225,
    h: 2030,
    w_: 3225,
    h_: 2030,
  },
  controlsCatcher: null,
  cameraSettings: defaultCameraSettings,
  activePOIName: "",
  activePOICategory: "",
  amenitiesExpanded: false,
  currentAmenityIndex: 0,
  filteredResults: [],
  favorites: [],
  timeline: null,
  mapExpanded: null,
  filterOptions: {
    dropModelOpacity: false,
    floorFilterOpen: false,
    priceFilterOpen: false,
    floor: [],
    beds: [],
    exposure: [],
    priceMin: 800000,
    priceMax: 2000000,
    initialPriceMin: 800000,
    initialPriceMax: 2000000,
  },
  residencePopups: {
    activeResidence: null,
    residenceContainerOpen: false,
    residenceFloorPlanOpen: false,
    residenceViewOpen: false,
  },
  showShareFavorites: false,
  formName: "",
  formEmail: "",
  formBCC: "",
  view360: {
    show: false,
    material: "Pool",
  },
  emblaControls: null,
  availableFilter: "AVAILABLE",
  qrCodeData: null,
  showPrice: false,
}
