import { useEffect, useRef } from "react"
import { gsap } from "gsap"
import styled from "styled-components"

import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"
import RoomTaken from "@components/RoomTaken/RoomTaken"

import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"
import { useStore } from "@state/store"
import globalStyles from "../../style.global"

function RoomSelector({ open, setOpen }) {
  const favorites = useSocketStore((s) => s.favorites)
  const { room, connectSocket, disconnectSocket, emitSocketEvent } =
    useSocketStore()
  const menuRef = useRef<HTMLDivElement>()
  const iconRef = useRef<HTMLDivElement>()
  const isIpad = useDeviceStore((s) => s.isIpad)

  const buttons = ["room 1", "room 2", "room 3", "disconnect"]
  function handleRoomClick(txt) {
    console.log("ROOM CLICK")
    const roomName = txt.replaceAll(" ", "")
    if (txt === "disconnect") {
      // console.log("leave")
      disconnectSocket(room)
    } else {
      // No one is in the room
      if (room === null) {
        connectSocket({
          room: roomName,
          favs: favorites,
          path: window.location.pathname,
          isIpad,
        })
      } else {
        if (room === roomName) {
          return
        }
        emitSocketEvent("switchRoom", {
          newRoom: roomName,
          oldRoom: room,
          favs: favorites,
          path: window.location.pathname,
          isIpad,
        })
      }
    }
  }

  function outsideClick(e) {
    if (!e.target.dataset.selector) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        window.addEventListener("click", outsideClick)
      }, 200)
    }

    return () => {
      window.removeEventListener("click", outsideClick)
    }
  }, [open])

  useEffect(() => {
    if (menuRef.current) {
      gsap.to(menuRef.current, {
        clipPath: open
          ? "circle(200% at 93.5% 5.25%)"
          : "circle(0% at 93.5% 6%)",
        ease: "power1.out",
        duration: 1,
        onComplete: () => {
          open &&
            gsap.to(iconRef.current, {
              opacity: 1,
            })
        },
        onStart: () => {
          !open &&
            gsap.to(iconRef.current, {
              opacity: 0,
            })
        },
      })
    }
  }, [open])
  return (
    isIpad && (
      <>
        <Menu
          ref={menuRef}
          style={{
            pointerEvents: open ? "all" : "none",
          }}
          data-selector="roomSelector"
        >
          {buttons.map((txt, i) => {
            return (
              <Button
                key={`btn${i}`}
                className={room === txt.replaceAll(" ", "") ? "active" : ""}
                onClick={() => {
                  handleRoomClick(txt)
                }}
                data-selector="roomSelector"
              >
                {txt}
              </Button>
            )
          })}
          <Icon
            ref={iconRef}
            style={{
              pointerEvents: open ? "all" : "none",
            }}
            onClick={() => {
              setOpen(false)
            }}
            data-selector="roomSelector"
          >
            <CloseIcon strokeWidth={3} color={globalStyles.colors.grey} />
          </Icon>
        </Menu>
        <RoomTaken />
      </>
    )
  )
}

export default RoomSelector
const Menu = styled.div`
  position: absolute;
  z-index: 14;
  top: 0%;
  right: 0%;
  width: 20%;
  height: 37%;
  clip-path: circle(0% at 93.5% 5.25%);
  background-color: ${globalStyles.colors.green};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  box-shadow: -5px 5px 15px ${globalStyles.colors.gold}cc;

  .active {
    color: ${globalStyles.colors.pink};
  }
`

const Icon = styled.div`
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
  aspect-ratio: 1/1;
  border: 3px solid ${globalStyles.colors.gold};
  border-radius: 100%;
  cursor: pointer;
  opacity: 0;

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(0.6);
  }
`

const Button = styled.p`
  font-size: clamp(1rem, 2vw, 2rem);
  font-family: "Gilmer Medium", sans-serif;
  color: ${globalStyles.colors.grey};
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.5s ease-out;
`
