// import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {}

export const timelineAnimation = ({ timeline, index }) => {
  timeline.fromTo(
    ".FadeInStagger0",
    { scale: 1.2 },
    {
      duration: 2,
      autoAlpha: 1,
      scale: 1,
      opacity: 1,
      ease: "power1.inOut",
    }
  )
  timeline.to(
    ".FadeInStagger",
    {
      duration: 1,
      delay: 0.1,
      opacity: 1,
      ease: "power1.inOut",
    },
    "<"
  )
  timeline.to(
    ".FadeInStagger2",
    {
      duration: 1,
      delay: 0.2,
      opacity: 1,
      ease: "power1.inOut",
    },
    "<"
  )
  timeline.to(
    ".FadeInStagger3",
    {
      duration: 1,
      delay: 0.3,
      opacity: 1,
      ease: "power1.inOut",
    },
    "<"
  )
}
