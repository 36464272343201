import styled from "styled-components"
import { useEffect, useRef } from "react"
import { gsap } from "gsap"

import CloseIcon from "@components/SVG/CloseIcon/CloseIcon"

import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"
import { useStore } from "@state/store"
import globalStyles from "../../style.global"

function RoomTaken() {
  const isIpad = useDeviceStore((s) => s.isIpad)
  const favorites = useSocketStore((s) => s.favorites)
  const room = useSocketStore((s) => s.room)
  const path = useSocketStore((s) => s.path)
  const roomStatus = useSocketStore((s) => s.roomStatus)
  const roomTaken = useSocketStore((s) => s.roomTaken)
  const setRoomTaken = useSocketStore((s) => s.setRoomTaken)
  const emitSocketEvent = useSocketStore((s) => s.emitSocketEvent)
  const contentRef = useRef<HTMLDivElement>()
  const rooms = roomStatus ? Object.keys(roomStatus) : null
  const availableRooms = rooms?.filter((txt) => roomStatus[txt] === false)

  function format(string) {
    return `${string.slice(0, 4)} ${string.slice(-1)}`
  }

  function handleClose() {
    if (contentRef.current) {
      gsap.to(contentRef.current, {
        opacity: 0,
      })
      setTimeout(() => {
        setRoomTaken(false)
      }, 810)
    } else {
      return
    }
  }

  return (
    roomTaken && (
      <Wrapper>
        <Content
          ref={(node) => {
            if (node) {
              contentRef.current = node
              gsap.to(node, {
                opacity: 1,
              })
            }
          }}
        >
          <h1>
            YOU ATTEMPTED TO JOIN <span>{format(roomStatus.attempted)} </span>
            <br />
            BUT IT'S BEING USED BY SOMEONE ELSE <br />
            YOU CAN "JOIN ANYWAY" AND <span>REMOVE ANY OTHER USER</span>
            <br />
          </h1>

          <Button
            className="taken"
            onClick={() => {
              emitSocketEvent("forceJoin", {
                newRoom: roomStatus.attempted,
                favs: favorites,
                path: window.location.pathname,
                isIpad,
              })
              setTimeout(() => {
                handleClose()
              }, 200)
            }}
          >
            JOIN ANYWAY
          </Button>

          <h1>
            OR CHOOSE A FREE ROOM: <br />
          </h1>

          <div className="freeBtns">
            {availableRooms?.map((txt, i) => {
              return (
                <Button
                  key={`btn${i}`}
                  className="free"
                  onClick={() => {
                    emitSocketEvent("switchRoom", {
                      newRoom: txt,
                      oldRoom: room,
                      isIpad: true,
                    })
                    setTimeout(() => {
                      handleClose()
                    }, 200)
                  }}
                >
                  {format(txt)}
                </Button>
              )
            })}
          </div>
          <Button
            className="close"
            onClick={() => {
              handleClose()
            }}
          >
            <CloseIcon strokeWidth={5} color={globalStyles.colors.grey} />
          </Button>
        </Content>
      </Wrapper>
    )
  )
}

export default RoomTaken

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: auto;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.green}CC;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3%;

  & h1 {
    text-align: center;
    line-height: 1.8em;
    letter-spacing: 0.2em;

    & span {
      color: ${globalStyles.colors.white};
      font-style: italic;
      text-transform: uppercase;
    }
  }

  .taken {
    background-color: ${globalStyles.colors.grey};
    color: ${globalStyles.colors.white};
    opacity: 0.8;
  }

  .free {
    background-color: ${globalStyles.colors.pink};
    color: ${globalStyles.colors.white};
  }

  .freeBtns {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5%;
  }

  .close {
    position: absolute;
    bottom: 2%;
    left: 50%;
    width: 3em;
    height: 3em;
    aspect-ratio: 1/1;
    background: none;
    border: 3px solid ${globalStyles.colors.gold};
    border-radius: 100%;
    display: grid;
    place-content: center;
    transform: translate(-50%, -50%);

    & svg {
      transform: scale(1.5);
    }
  }
`

const Button = styled.button`
  text-transform: uppercase;
  background: none;
  border: none;
  outline: none;
  padding: 1em;
  font-size: clamp(1rem, 1.8rem, 2rem);
  font-family: "Gilmer Medium", sans-serif;
  letter-spacing: 0.2em;
  cursor: pointer;
`
