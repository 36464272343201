import { create } from "zustand"
import staticData from "@data/residencesData.json"

interface DeviceStore {
  isIpad: boolean
  setIsIpad: (v: boolean) => void
  hasAux: boolean
  setHasAux: (v: boolean) => void
  auxScreen: "left" | "right" | "center" | null
  setAuxScreen: (v: "left" | "right" | "center" | null) => void
  apartments: {}[]
  setApartments: (v: {}[]) => void
}

export const useDeviceStore = create<DeviceStore>()((set, get) => ({
  isIpad: false,
  setIsIpad: (v: boolean) => set({ isIpad: v }),
  hasAux: false,
  setHasAux: (v: boolean) => set({ hasAux: v }),
  auxScreen: null,
  setAuxScreen: (v: "left" | "right" | "center" | null) =>
    set({ auxScreen: v }),
  apartments: staticData,
  setApartments: (v) => set({ apartments: v }),
}))
