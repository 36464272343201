import { useState, useRef, useEffect } from "react"
import styled from "styled-components"

import ApartmentsList from "@components/ApartmentsList/ApartmentsList"
import ListIcon from "@components/SVG/ListIcon/ListIcon"

import { useDeviceStore } from "@state/deviceStore"
import globalStyles from "../../style.global"

function ApartmentListPopUp({ tableVisible, setTableVisible }) {
  const isIpad = useDeviceStore((s) => s.isIpad)
  // const [tableVisible, setTableVisible] = useState(false)
  const tableRef = useRef<HTMLDivElement>()

  // function handleTableToggle() {
  //   if (tableVisible) {
  //     tableRef.current.style.opacity = "0"
  //     setTimeout(() => {
  //       setTableVisible(false)
  //     }, 800)
  //   } else {
  //     setTableVisible(true)
  //     setTimeout(() => {
  //       tableRef.current.style.opacity = "1"
  //     }, 100)
  //   }
  // }

  useEffect(() => {
    return () => tableVisible && setTableVisible(false)
  }, [])

  return (
    isIpad && (
      <Wrapper>
        {tableVisible && (
          <TableWrapper
            style={{
              opacity: tableVisible ? 1 : 0,
              pointerEvents: tableVisible ? "auto" : "none",
              transition: "opacity 1s ease-in",
            }}
            // ref={tableRef}
          >
            <ApartmentsList />
          </TableWrapper>
        )}
        {/* <Icon
        onClick={() => {
          handleTableToggle()
        }}
        ref={node => {
          if(node){
            setTimeout(() => {
              
            node.style.opacity = "1"
            }, 2500)
          }
        }}
      >
        <ListIcon open={tableVisible} />
      </Icon> */}
      </Wrapper>
    )
  )
}

export default ApartmentListPopUp

const Wrapper = styled.div``

const TableWrapper = styled.div`
  position: absolute;
  z-index: 9;
  top: 6%;
  left: 0%;
  width: 100%;
  height: 86.5%;
  max-height: 100%;
  opacity: 0;
  transition: opacity 2s ease-in;
  background-color: ${globalStyles.colors.grey};
`

const Icon = styled.button`
  position: absolute;
  bottom: 10%;
  right: 0.5%;
  z-index: 10;
  cursor: pointer;
  display: grid;
  border: none;
  background: none;
  opacity: 0;
  transition: opacity 0.8s ease-in;
`
