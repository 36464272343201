import { useRef, useEffect } from "react"
import styled from "styled-components"
import { gsap } from "gsap"

import globalStyles from "../../style.global"
import { useDeviceStore } from "@state/deviceStore"
import useSocketStore from "@state/useSocketStore"

type TToggleIconProps = {
  cue: boolean
  [x: string]: any
}

function ToggleIcon({ cue, ...props }: TToggleIconProps) {
  const orderState = useSocketStore((s) => s.availableFilter)
  const firstRender = useRef<boolean>(true)
  const bgRef = useRef<HTMLDivElement>()
  const markerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    if (markerRef.current && bgRef.current) {
      gsap.to(bgRef.current, {
        transform:
          orderState !== "AVAILABLE" ? "translateX(0%)" : "translateX(-50%)",
      })
      gsap.to(markerRef.current, {
        transform:
          orderState !== "AVAILABLE" ? "translateX(100%)" : "translateX(0%)",
      })
    }
  }, [orderState])

  useEffect(() => {
    return () => {
      firstRender.current = true
    }
  }, [])

  return (
    <ToggleWrapper {...props}>
      <Background
        style={{
          transform:
            orderState !== "AVAILABLE" ? "translateX(0%)" : "translateX(-50%)",
        }}
        ref={bgRef}
      />
      <Marker
        style={{
          transform:
            orderState !== "AVAILABLE" ? "translateX(100%)" : "translateX(0%)",
        }}
        ref={markerRef}
      />
    </ToggleWrapper>
  )
}

export default ToggleIcon

const ToggleWrapper = styled.div`
  z-index: 13;
  pointer-events: all;
  margin: 0 auto;
  width: 60%;
  aspect-ratio: 1/0.5;
  transform: translate(0%, -50%);
  border-radius: 5rem;
  background-color: ${globalStyles.colors.white};
  border: 0.25rem solid ${globalStyles.colors.white};
  overflow: hidden;
`

const Marker = styled.div`
  pointer-events: all;
  width: 50%;
  border-radius: 5rem;
  z-index: 2;
  position: absolute;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: ${globalStyles.colors.green};
`

const Background = styled.div`
  pointer-events: all;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: 5rem;
  transform: translateX(-50%);
  background-color: ${globalStyles.colors.pink};
`
