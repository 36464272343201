import staticData from "@data/residencesData.json"

function trimData(data: {}[]): {}[] {
  // Check if the response indicates an error
  if (data["name"] === "AxiosError") {
    // Handle the error here
    console.error("Too Many Requests. Please try again later.")
    return null
  }
  let trimmedData
  trimmedData = data.map((obj) => {
    const uid = getUID(obj["building"], obj["unit"])
    const staticObject = staticData.find((obj) => obj.uid === uid)
    return {
      ...obj,
      buildingNumber: getBuildingNumber(obj["building"]),
      uid,
      id: getID(obj["unit"]),
      inventoryID: obj["id"],
      exposure: staticObject?.exposure,
      floorplanType: staticObject?.floorPlanType,
      floor: obj["floor"] === 11 ? "PH" : `${obj["floor"]}`,
      bed: obj["bedrooms"],
      bath: obj["bathrooms"],
      interiorSquareFootage: obj["area"],
      terraceSquareFootage: obj["exterior_area"],
      isAvailable: obj["status_id"] === 33512,
      status: obj["status_id"],
    }
  })
  return trimmedData
}

export default trimData

export function addRealEstateTax(apartments: {}[], realEstateTax: {}[]): {}[] {
  let appendedData
  return appendedData
}

function getID(id) {
  if (id.toLowerCase().includes("l")) {
    let cleanedID = id.replace(/\s/g, "").replace(/[a-zA-Z]/g, "")
    return `L${cleanedID}`
  } else if (id.toLowerCase().includes("p")) {
    let cleanedID = id.replace(/\s/g, "").replace(/[a-zA-Z]/g, "")
    return `PH${cleanedID}`
  } else {
    let cleanedID = id.replace(/\s/g, "").replace(/[a-zA-Z]/g, "")
    return cleanedID
  }
}

function getUID(tower, id) {
  id = getID(id)
  if (tower === "Tower A") {
    return `${id}A`
  } else if (tower === "Tower B") {
    return `${id}B`
  } else {
    return id
  }
}

function getBuildingNumber(tower) {
  if (tower === "Tower A") {
    return "100"
  } else if (tower === "Tower B") {
    return "140"
  }
}
