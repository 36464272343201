import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"

import useSocketStore from "@state/useSocketStore"
import locationData from "@data/LocationPOIsFinal.json"
import { categoryColors } from "@data/categoryColors"
import globalStyles from "../../style.global"

function MapModal() {
  const activePOI = useSocketStore((s) => s.activePOIName)
  const mapExpanded = useSocketStore((s) => s.mapExpanded)
  const [visible, setVisible] = useState(null)
  const [poiData, setPoiData] = useState<any>(null)
  const [color, setColor] = useState<any>("blue")
  const modalRef = useRef<HTMLDivElement>()

  const paths = {
    initial: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
    final: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
  }

  useEffect(() => {
    if (visible === null) {
      setVisible(false)
      return
    }
    if (activePOI.length === 0) {
      setVisible(false)
    } else {
      setVisible(true)
    }
  }, [activePOI])

  useEffect(() => {
    if (visible) {
      const categoryID = Number(activePOI.split("_")[0])
      const poiID = Number(activePOI.split("_")[1])
      if (categoryID && poiID) {
        const data = locationData
          .filter((obj) => obj["CategoryID"] === categoryID)[0]
          .PointsOfInterest.filter((obj) => obj.PointOfInterestID === poiID)[0]
        const color = locationData.filter(
          (obj) => obj["CategoryID"] === categoryID
        )[0].Category
        setColor(categoryColors[color])
        setPoiData(data)
      }
    }
    gsap.to(modalRef.current, {
      clipPath: visible ? paths.final : paths.initial,
      onComplete: () => {
        gsap.to("#modalTxt", {
          opacity: visible ? 1 : 0,
        })
      },
    })
  }, [visible, activePOI])

  useEffect(() => {
    if (!mapExpanded && visible !== null) {
      setVisible(false)
    }
  }, [mapExpanded])
  return (
    <MapModalContainer
      ref={modalRef}
      style={{
        backgroundColor: `${globalStyles.colors[color]}E6`,
      }}
    >
      <p id="modalTxt">{poiData?.PointOfInterest}</p>
      <p id="modalTxt">{poiData?.Address.Line1},</p>
      <p id="modalTxt">{poiData?.Address.Line2},</p>
      <p id="modalTxt">
        {poiData?.Address.City}, {poiData?.Address.ZIP}
      </p>
      <p id="modalTxt">
        {poiData?.Distance.Value}
        {poiData?.Distance.Measurement === "ft"
          ? " ft away"
          : poiData?.Distance.Measurement === "miles"
          ? " miles away"
          : poiData?.Distance.Measurement === "mile"
          ? " mile away"
          : ""}
      </p>
    </MapModalContainer>
  )
}

export default MapModal

const MapModalContainer = styled.div`
  top: 7%;
  right: 0%;
  position: absolute;
  z-index: 5;
  width: 30%;
  height: 20%;
  clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5%;
  padding-left: 5%;
  color: ${globalStyles.colors.white};

  & p {
    font-size: 1.5rem;
    :nth-of-type(1) {
      font-size: 2rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
`
