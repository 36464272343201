export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="54"
      height="40"
      viewBox="0 0 54 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M20 1H33.8776C44.371 1 52.8776 9.50659 52.8776 20C52.8776 30.4934 44.371 39 33.8776 39H20C9.50658 39 1 30.4934 1 20C1 9.50659 9.50659 1 20 1Z"
        fill="#719086"
        stroke="#D0CBBC"
        strokeWidth="2"
      />
      <path
        d="M16.5 21.0459H37.5C38.3281 21.0459 39 20.374 39 19.5459C39 18.7171 38.3281 18.0459 37.5 18.0459H16.5C15.6712 18.0459 15 18.7171 15 19.5459C15 20.374 15.6712 21.0459 16.5 21.0459Z"
        fill="#D0CBBC"
      />
      <path
        d="M16.9541 15L37.9541 15C38.7822 15 39.4541 14.3281 39.4541 13.5C39.4541 12.6712 38.7822 12 37.9541 12L16.9541 12C16.1254 12 15.4541 12.6712 15.4541 13.5C15.4541 14.3281 16.1254 15 16.9541 15Z"
        fill="#D0CBBC"
      />
      <path
        d="M16.5 27.0459H37.5C38.3281 27.0459 39 26.374 39 25.5459C39 24.7171 38.3281 24.0459 37.5 24.0459H16.5C15.6712 24.0459 15 24.7171 15 25.5459C15 26.374 15.6712 27.0459 16.5 27.0459Z"
        fill="#D0CBBC"
      />
    </svg>
  )
}
