import styled from "styled-components"
import globalStyles from "../../style.global"
import { useDeviceStore } from "@state/deviceStore"
import "vidstack/styles/base.css"
import { MediaOutlet, MediaPlayer } from "@vidstack/react"
import { MediaPlayerElement } from "vidstack"
import { useEffect, useRef } from "react"
import useSocketStore from "@state/useSocketStore"

function IntroVideo() {
  const auxScreen = useDeviceStore((s) => s.auxScreen)
  const player = useRef<MediaPlayerElement>(null)

  const { socket, room, emitSocketEvent } = useSocketStore()

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout
    let receivedSocketEvent = false // to track whether a socket event has been received

    const startVideo = (payload: any) => {
      if (payload.length === 3) {
        player.current.play()
        receivedSocketEvent = true
      }
    }

    socket && socket.on("startVideo", startVideo)

    // start the video if no socket event is received after 5 seconds
    timeoutId = setTimeout(() => {
      if (!receivedSocketEvent) {
        player.current.play()
      }
    }, 5000)

    return () => {
      socket && socket.off("startVideo", startVideo)
      clearTimeout(timeoutId)
    }
  }, [socket, player])

  return (
    <Wrapper>
      {window.__TAURI__ && auxScreen && (
        <Video
          ref={player}
          className={auxScreen ? `${auxScreen}` : "center"}
          src={`/media/landing/landing--video-${auxScreen}.mp4`}
          muted
          onEnd={() => {
            emitSocketEvent("videoCanPlay", {
              room: room,
              screen: auxScreen,
            })
          }}
          onCanPlay={() => {
            emitSocketEvent("videoCanPlay", {
              room: room,
              screen: auxScreen,
            })
          }}
        >
          <MediaOutlet />
        </Video>
      )}
      {window.__TAURI__ === undefined && (
        <Video
          ref={player}
          className="iPad"
          src={`/media/landing/landing--video.mp4`}
          muted
          autoplay
          loop
        >
          <MediaOutlet />
        </Video>
      )}
      {(room === "room2" || room === "room3") && (
        <Video
          ref={player}
          className="center"
          src={`/media/landing/landing--video.mp4`}
          muted
          autoplay
          loop
        >
          <MediaOutlet />
        </Video>
      )}
    </Wrapper>
  )
}

export default IntroVideo

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${globalStyles.colors.black};
  z-index: 12;
  overflow: hidden;
  display: grid;
`

const Video = styled(MediaPlayer)`
  & video {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    .iPad {
      place-self: center;
    }
    .iPad {
      place-self: center;
    }
    .left {
      place-self: center left;
    }
    .right {
      place-self: center right;
    }
  }

  /* position: absolute;
  top: 50%;
  height: auto;
  object-fit: cover;
  z-index: 0;
  opacity: 0.8;
  border: 5px red solid;
  height: 100%;
  width: 100%; */

  /* .LandingVideo {
    visibility: hidden;
  }
  .left {
    left: 0;
    transform: translate(0%, -50%);
    object-position: 0% 50%;
    height: 100vh;
  }
  .center {
    left: 50%;
    transform: translate(-50%, -50%);
    object-position: 50% 50%;
    height: 100vh;
  }
  .right {
    right: 0;
    transform: translate(0%, -50%);
    object-position: 100% 50%;
    height: 100vh;
  }
  .iPad {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  } */
`
