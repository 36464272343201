import { useState, useEffect } from "react"

type Props = {}

const Circles = (props: Props) => {
  const [circles, setCircles] = useState([])
  const handleMouseDown = (event) => {
    const { clientX, clientY } = event
    const newCircle = {
      x: clientX,
      y: clientY,
      id: Date.now(),
    }
    setCircles((prevCircles) => [...prevCircles, newCircle])
    setTimeout(() => {
      setCircles((prevCircles) =>
        prevCircles.filter((circle) => circle.id !== newCircle.id)
      )
    }, 1000)
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown)

    return () => {
      window.removeEventListener("mousedown", handleMouseDown)
    }
  }, [])

  return (
    <div>
      {circles.map((circle, index) => (
        <div
          key={circle.id}
          className="circle"
          style={{ left: circle.x, top: circle.y }}
        ></div>
      ))}
    </div>
  )
}

export default Circles
