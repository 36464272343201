//General
import { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"
import { CSSTransition } from "react-transition-group"

//Components
import Amenities1 from "@components/SVG/Amenities1"
import LeftArrow from "@components/SVG/LeftArrow"
import RightArrow from "@components/SVG/RightArrow"
import { amenityData } from "@data/amenityData"
import View360 from "@canvas_components/Amenities/View360/View360"
import BackButton from "@components/SVG/BackArrow"
import * as AmenitiesSVG from "@components/SVG/Amenities"
import { useAnimate } from "@utils/hooks"
import { setTimelineAnimation, timelineAnimation } from "@animations/Amenities"
import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"
import { amenityCameraData } from "@data/amenityCameraData"
import { current } from "immer"

//Types
interface AmenitiesTypes {
  expanded?: boolean
  title?: boolean
  id?: number
  key?: number
  index?: number
}

function findParentKey(obj, targetValue) {
  for (let key in obj) {
    if (typeof obj[key] === "object") {
      let result = findParentKey(obj[key], targetValue)
      if (result !== undefined) {
        return key
      }
    } else if (obj[key] === targetValue) {
      return key
    }
  }
}

export default function Amenities() {
  const gsapRef = useRef()
  const setEmbla = useSocketStore((state) => state.setEmbla)
  const fullScreenRef = useRef(null)
  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })
  const socket = useSocketStore((state) => state.socket)
  const hasAux = useDeviceStore((s) => s.hasAux)
  const isIpad = useDeviceStore((s) => s.isIpad)
  const view360 = useSocketStore((state) => state.view360)
  const syncState = useSocketStore((state) => state.syncState)
  const amenitiesExpanded = useSocketStore((state) => state.amenitiesExpanded)
  const currentAmenityIndex = useSocketStore(
    (state) => state.currentAmenityIndex
  )

  const initialAmenityCameraSettings = {
    position: [-0.5, 0.3, 0.6],
    target: [0, -0.35, 0],
    fov: 75,
    delay: 100,
    duration: 2000,
  }

  const expandedCameraSettings = {
    position: [
      amenityCameraData[findParentKey(amenityData, currentAmenityIndex)]
        .cameraPosition[0],
      amenityCameraData[findParentKey(amenityData, currentAmenityIndex)]
        .cameraPosition[1],
      amenityCameraData[findParentKey(amenityData, currentAmenityIndex)]
        .cameraPosition[2],
    ],
    target: [
      amenityCameraData[findParentKey(amenityData, currentAmenityIndex)]
        .cameraTarget[0],
      amenityCameraData[findParentKey(amenityData, currentAmenityIndex)]
        .cameraTarget[1],
      amenityCameraData[findParentKey(amenityData, currentAmenityIndex)]
        .cameraTarget[2],
    ],
    fov: 75,
    delay: 100,
    duration: 3000,
  }

  useEffect(() => {
    syncState("cameraSettings", initialAmenityCameraSettings)
    syncState("currentAmenityIndex", 0)

    return () => {
      syncState("amenitiesExpanded", false)
      syncState("currentAmenityIndex", 0)
      syncState("view360", { show: false, material: "Pool" })
      syncState("cameraSettings", initialAmenityCameraSettings)
    }
  }, [])

  const handleExpandZoom = () => {
    if (amenitiesExpanded) {
      syncState("amenitiesExpanded", false)
      syncState("cameraSettings", initialAmenityCameraSettings)
      const currentOpacity = fullScreenRef.current.style.opacity
      if (currentOpacity === "1") {
        fullScreenRef.current.style.opacity = 0
      }
    } else {
      syncState("amenitiesExpanded", true)
      syncState("cameraSettings", expandedCameraSettings)
    }
  }

  const handleAmenityCardClick = (index) => {
    handleExpandZoom()
    emblaApi && emblaApi.scrollTo(index)
  }

  //Initiate carousel
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    loop: true,
    speed: 20,
    startIndex: 0,
  })

  const onSelect = useCallback(() => {
    const triggerSelect = () => {
      if (emblaApi && !view360.show) {
        console.log(view360.show)
        const currentOpacity = fullScreenRef.current.style.opacity
        if (currentOpacity === "1") {
          fullScreenRef.current.style.opacity = 0
        }
        const id = emblaApi.selectedScrollSnap()
        syncState("currentAmenityIndex", id)
        const postion =
          amenityCameraData[findParentKey(amenityData, id)].cameraPosition
        const target =
          amenityCameraData[findParentKey(amenityData, id)].cameraTarget
        //Trigger camera move
        syncState("cameraSettings", {
          position: postion,
          target: [target[0], target[1], target[2]],
          duration: 1500,
          fov: 75,
          delay: 100,
        })
        !amenitiesExpanded && syncState("amenitiesExpanded", true)
      }
    }

    setTimeout(() => triggerSelect(), 10)
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        isIpad && onSelect()
      })
    }

    return () => {
      if (emblaApi) {
        emblaApi.off("scroll", () => null)
      }
    }
  }, [emblaApi, onSelect])

  useEffect(() => {
    socket?.on("got-embla-all", ({ data }) => {
      if (emblaApi) {
        emblaApi.scrollTo(data)
      }
    })
  }, [emblaApi, socket])

  useEffect(() => {
    socket?.on("got-embla", ({ data }) => {
      if (emblaApi) {
        !isIpad && emblaApi.scrollTo(data)
      }
    })
  }, [emblaApi, socket])

  function handleLeftArrowClick() {
    if (emblaApi) emblaApi.scrollPrev()
  }
  function handleRightArrowClick() {
    if (emblaApi) emblaApi.scrollNext()
  }

  const nodeRef = useRef(null)
  let AmenitiesSVGComponent = AmenitiesSVG[view360.material]

  const handleFullScreenImageToggle = (index = null) => {
    if (fullScreenRef) {
      if (fullScreenRef?.current.style.opacity === "1") {
        fullScreenRef.current.style.opacity = 0
      } else {
        if (index !== null) {
          fullScreenRef.current.src = `/media/amenities/${index}.webp`
          setTimeout(() => (fullScreenRef.current.style.opacity = 1), 10)
        }
      }
    }
  }

  return (
    <>
      <AmenitiesSection expanded={amenitiesExpanded} ref={gsapRef}>
        {isIpad && (
          <DragToScroll
            className="FadeInStagger2"
            style={{ visibility: "hidden" }}
            expanded={amenitiesExpanded}
          >
            DRAG TO SCROLL →
          </DragToScroll>
        )}
        <AmenitiesBG />
        <CSSTransition
          nodeRef={nodeRef}
          timeout={view360.show ? 3000 : 1000}
          classNames="fade-canvas"
          in={view360.show}
          unmountOnExit
        >
          <CanvasWrapper ref={nodeRef}>
            <div className="OverlayContainer">
              <AmenitiesSVGComponent className="AmenitiesSVGComponent" />
              <BackButton
                className="BackButton"
                click={() =>
                  syncState("view360", {
                    show: false,
                    material: view360.material,
                  })
                }
              />
            </div>
            <div className="CanvasContainer">
              <View360 material={view360.material} />
            </div>
          </CanvasWrapper>
        </CSSTransition>
        {!hasAux && (
          <>
            <StyledLeftArrow
              className="FadeInStagger2"
              style={{ visibility: "hidden" }}
              click={handleLeftArrowClick}
            />
            <StyledRightArrow
              className="FadeInStagger2"
              style={{ visibility: "hidden" }}
              click={handleRightArrowClick}
            />
          </>
        )}

        <ModelContainer expanded={amenitiesExpanded}>
          {/* {!hasAux && (
            <ExpandedSideImageBar expanded={amenitiesExpanded}>
              <ExpandedSideImageTopContainer>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((number) => (
                  <img
                    key={number}
                    src={`/media/amenities/${number}a.webp`}
                    alt={`Image ${number}`}
                    style={{
                      opacity: currentAmenityIndex === number ? 1 : 0,
                    }}
                  />
                ))}
              </ExpandedSideImageTopContainer>
              <ExpandedSideImageBottomContainer>
                {[0, 1, 2, 3, 4, 5, 6, 7].map((number) => (
                  <img
                    key={number}
                    src={`/media/amenities/${number}b.webp`}
                    alt={`Image ${number}`}
                    style={{
                      opacity: currentAmenityIndex === number ? 1 : 0,
                    }}
                  />
                ))}
              </ExpandedSideImageBottomContainer>
            </ExpandedSideImageBar>
          )} */}
        </ModelContainer>
        <FullScreenImage>
          <img
            onClick={() => handleFullScreenImageToggle()}
            src={`/media/amenities/0.webp`}
            alt="The Boardwalk"
            ref={fullScreenRef}
          />
        </FullScreenImage>
        <FooterContainer expanded={amenitiesExpanded}>
          <TitleCard
            onClick={() => {
              handleExpandZoom()
            }}
          >
            <Amenities1 />
          </TitleCard>
          <Embla ref={emblaRef}>
            <EmblaContainer expanded={amenitiesExpanded}>
              {Object.entries(amenityData)
                .filter(([key]) => !key.startsWith("general"))
                .map(([key, value], index) => {
                  return (
                    <EmblaSlide
                      style={{
                        opacity: !amenitiesExpanded
                          ? 0.3
                          : Number(index) === Number(currentAmenityIndex)
                          ? 1
                          : 0.3,
                        transition: "opacity 1s ease-in-out",
                      }}
                      key={index}
                      index={index}
                    >
                      <TitleContainer
                        onClick={() => {
                          handleAmenityCardClick(index)
                        }}
                        expanded={amenitiesExpanded}
                      >
                        <span>the</span>
                        <h2>{value.title}</h2>
                        <p>TAP TO EXPAND →</p>
                      </TitleContainer>
                      <ImageContainer expanded={amenitiesExpanded}>
                        <img
                          onClick={() => {
                            handleFullScreenImageToggle(index)
                            emblaApi && emblaApi.scrollTo(index)
                          }}
                          src={
                            "/media/amenities/" +
                            // value.title.replaceAll(" ", "_") +
                            index +
                            ".webp"
                          }
                          alt="The Boardwalk"
                        />
                      </ImageContainer>
                    </EmblaSlide>
                  )
                })}
            </EmblaContainer>
          </Embla>
        </FooterContainer>
      </AmenitiesSection>
    </>
  )
}

const DragToScroll = styled.div<AmenitiesTypes>`
  position: absolute;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-family: "Gilmer", sans-serif;
  font-weight: 400;
  padding: 0.7rem 2rem 0.7rem 2rem;
  /* bottom: ${(props) => (props.expanded ? "12.8%" : "26.8%")}; */
  bottom: 12.8%;
  right: 3%;
  background-color: ${(props) => props.theme.colors.green};
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 100px;
  z-index: 10;
  transition: bottom 0.4s ease-in;
`

const StyledLeftArrow = styled(LeftArrow)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 3%;
  z-index: 10;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 3%;
  height: auto;
`

const StyledRightArrow = styled(RightArrow)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  right: 3%;
  z-index: 10;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 3%;
  height: auto;
`

const AmenitiesSection = styled.section<AmenitiesTypes>`
  height: 100%;
  width: 100%;
  display: grid;
  transition: grid-template-rows 0.4s ease-in;
  grid-template-rows: minmax(0, 8.5fr) minmax(0, 1.5fr);
  /* grid-template-rows: ${(props) =>
    props.expanded
      ? "minmax(0, 8.5fr) minmax(0, 1.5fr)"
      : "minmax(0, 7fr) minmax(0, 3fr)"}; */

  &button,
  &h3 {
    all: unset;
    cursor: pointer;
  }

  &button:focus {
    outline: none;
  }
`

const FullScreenImage = styled.div<AmenitiesTypes>`
  grid-column: 1 / -1;
  grid-row: 1;
  height: 100%;
  width: 100%;
  z-index: 2;
  pointer-events: none;

  & img {
    height: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 0.4s ease-in;
    z-index: 2;
  }
`

const ModelContainer = styled.div<AmenitiesTypes>`
  grid-column: 1 / -1;
  grid-row: 1;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: grid;
  grid-template-columns: minmax(0, 25fr) minmax(0, 75fr);
`

const ExpandedSideImageBar = styled.div<AmenitiesTypes>`
  transition: transform 0.4s ease-in;
  transform: ${(props) =>
    props.expanded ? "translateX(0%)" : "translateX(-100%)"};
  grid-column: 1;
  grid-row: 1 / -1;
  display: grid;
  grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
  /* background-color: ${(props) => props.theme.colors.green}; */
  overflow: hidden;
`

const ExpandedSideImageTopContainer = styled.div<AmenitiesTypes>`
  grid-row: 1;
  grid-column: 1 / -1;
  overflow: hidden;
  display: grid;
  place-items: center;

  & img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`
const ExpandedSideImageBottomContainer = styled.div<AmenitiesTypes>`
  height: 100%;
  width: 100%;
  grid-row: 2;
  grid-column: 1 / -1;
  overflow: hidden;
  display: grid;
  place-items: center;

  & img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const FooterContainer = styled.div<AmenitiesTypes>`
  grid-column: 1 / -1;
  grid-row: 2;
  height: 100%;
  width: 100%;
  /* background-color: ${(props) => props.theme.colors.green}; */
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: minmax(0, 25fr) minmax(0, 75fr);
`

const TitleCard = styled.div<AmenitiesTypes>`
  grid-column: 1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: grid;
  place-items: center;
  background-color: ${(props) => props.theme.colors.green};

  & svg {
    height: 70%;
    width: 70%;
  }
`

const Embla = styled.div<AmenitiesTypes>`
  grid-column: 2;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const EmblaContainer = styled.div<AmenitiesTypes>`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center start;
  grid-auto-flow: column;
  grid-auto-columns: 60rem;
  /* background-color: ${(props) => props.theme.colors.black}; */
  /* margin-left: -20.5%; */
  /* background-color: ${(props) => props.theme.colors.green}; */
`

const EmblaSlide = styled.div<AmenitiesTypes>`
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  background-color: ${({ index, theme }) =>
    index % 3 === 0
      ? theme.colors.pink
      : index % 2 === 0
      ? theme.colors.green
      : theme.colors.gold};
  overflow: hidden;
`

const TitleContainer = styled.div<AmenitiesTypes>`
  grid-column: 1;
  /* grid-column: ${(props) => (props.expanded ? "1 / -1" : "1")}; */
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: grid;
  overflow: hidden;
  font-weight: 600;
  grid-template-rows: minmax(0, 3fr) minmax(0, 3fr) minmax(0, 3fr);
  grid-template-columns: minmax(0, 1fr) minmax(0, 0.8fr) minmax(0, 8fr) minmax(
      0,
      1fr
    );
  align-items: center;
  justify-content: left;
  overflow: hidden;

  & p {
    grid-row: 3;
    grid-column: 3;
    height: 100%;
    width: 100%;
    font-family: "Gilmer", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.xs};
    color: ${(props) => props.theme.colors.white};
    /* display: ${(props) => (props.expanded ? "none" : "block")}; */
    display: none;
  }

  & span {
    font-family: "Dreamer", serif;
    font-size: ${(props) => props.theme.fontSizes.sm};
    color: ${(props) => props.theme.colors.white};
    transform: rotate(-90deg);
    /* grid-row: ${(props) => (props.expanded ? "1 / -1" : "2")}; */
    grid-row: 1 / -1;
    grid-column: 2;
    text-align: center;
  }

  & h2 {
    width: 100%;
    font-family: "Gilmer", sans-serif;
    font-weight: 400;
    font-size: 2.4rem;
    /* font-size: ${(props) => props.theme.fontSizes.lg}; */
    color: ${(props) => props.theme.colors.white};
    /* grid-row: ${(props) => (props.expanded ? "1 / -1" : "2")}; */
    grid-row: 1 / -1;
    grid-column: 3;
  }
`

const ImageContainer = styled.div<AmenitiesTypes>`
  grid-column: 2;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;

  background-color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  display: grid;
  place-items: center;

  & img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
`

const AmenitiesBG = styled.div`
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  pointer-events: none;
  /* background-color: ${({ theme }) => theme.colors.grey}; */
  z-index: -2;
  overflow: hidden;
`

const CanvasWrapper = styled.div`
  z-index: 11;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: ${(props) => props.theme.colors.grey}; */
  pointer-events: auto;
  display: grid;

  .OverlayContainer {
    height: 10%;
    width: 30%;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    place-self: end;
    display: grid;
    grid-auto-flow: column;
    z-index: 12;
    pointer-events: none;
    column-gap: 2rem;

    padding: 1rem 4rem 1rem 0rem;
    .BackButton {
      height: 50%;
      width: auto;
      pointer-events: auto;
      z-index: 13;
      cursor: pointer;
      place-self: center start;
    }
    .AmenitiesSVGComponent {
      height: 90%;
      width: auto;
      place-self: center end;
    }
  }

  .CanvasContainer {
    height: 100%;
    width: 100%;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    place-self: center;
    z-index: 11;
  }
`
