import residencesData from "../data/residencesData.json"
import residenceFloorPlanColors from "../data/residenceFloorPlanColors.json"
import exposureFacing from "../data/exposureFacing.json"
import { useDeviceStore } from "@state/deviceStore"

export function calculateSlide(favorite: string, apartments?: any) {
  const matchedApartment = apartments.find((residence: any) => {
    const residenceUid =
      residence["building"] === "Tower A"
        ? `${residence["id"]}A`
        : residence["building"] === "Tower B"
        ? `${residence["id"]}B`
        : null

    return residenceUid === favorite
  })

  if (matchedApartment) {
    const {
      floorPlanType = "A-01",
      id = "Example",
      buildingNumber = "100",
      price = 1000000,
      floor = "4",
      exposure = [],
      interiorSquareFootage = 1000,
      terraceSquareFootage = 200,
      bed = "1",
      bath = "1",
    } = matchedApartment

    let color: string = "green"
    residenceFloorPlanColors.some((obj: any) => {
      // Loop through the array of objects
      if (String(bed) in obj) {
        // Check if String(bed) is a key in the current object
        color = obj[String(bed) as keyof typeof obj] // Assign the corresponding color value to the color variable
      } else {
        color = "green"
      }
    })

    if (String(floor) === "PH") {
      color = "grey"
    }

    let FloorPlanName = String(floorPlanType.replace("-", ""))
    const facingDirections = exposure.map(
      (letter: string) =>
        exposureFacing[0][letter as keyof (typeof exposureFacing)[0]]
    )
    const facingString = facingDirections.join(", ")

    return {
      uid: favorite,
      floorPlanType,
      id,
      buildingNumber,
      price,
      floor,
      exposure,
      interiorSquareFootage,
      terraceSquareFootage,
      bed,
      bath,
      color,
      FloorPlanName,
      facingString,
    }
  }

  return null
}
