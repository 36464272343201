import { a, useSpring } from "@react-spring/three"
import useSocketStore from "@state/useSocketStore"
import { amenityData } from "@data/amenityData"
import { useTexture } from "@react-three/drei"
import lifestyleretail3 from "assets/images/gallery/lifestyle/retail/3.jpg"

import map_001 from "/models/Amenities/maps/map_001.jpg"
import map_002 from "/models/Amenities/maps/map_002.jpg"
import map_003 from "/models/Amenities/maps/map_003.jpg"
import map_004 from "/models/Amenities/maps/map_004.jpg"
import map_005 from "/models/Amenities/maps/map_005.jpg"

import { useLoader } from "@react-three/fiber"
import { TextureLoader } from "three"
useLoader.preload(TextureLoader, [map_001, map_002, map_003, map_004, map_005])

type Props = {
  node?: any
  active?: boolean
  texture?: THREE.Texture
  index?: number
  opacity?: any
  key?: number
}

function MeshElement({ node, opacity }: Props) {
  const setAllEmbla = useSocketStore((s) => s.setAllEmbla)

  const texture = useTexture(
    `/models/Amenities/maps/map_${node.name.split("_")[1]}.jpg`
  )
  texture.flipY = false

  // const [{ color }, api] = useSpring(() => ({
  //   color: "#fff",
  // }))

  let dims = calculateBoxDimensions(node.mesh_0.geometry.boundingBox)

  return (
    <>
      <a.mesh geometry={node.mesh_0.geometry}>
        <a.meshBasicMaterial
          // color={color}
          transparent
          map={texture}
          opacity={opacity}
        />
      </a.mesh>
      {(!node.name.startsWith("general") ||
        !node.name.startsWith("cinema")) && (
        <a.mesh
          renderOrder={10}
          onClick={() => {
            if (
              !node.name.includes("general") ||
              !node.name.startsWith("cinema")
            ) {
              setAllEmbla(amenityData[node.name].index)
            }
          }}
          position={node.mesh_0.geometry.boundingSphere.center}
        >
          <boxGeometry args={[dims.x, dims.y, dims.z]} />
          <a.meshBasicMaterial depthTest={false} visible={false} />
        </a.mesh>
      )}
    </>
  )
}

export default MeshElement

function calculateBoxDimensions(box) {
  // extract min and max
  const min = box.min
  const max = box.max
  // calculate lengths
  const lengthX = Math.abs(max.x - min.x)
  const lengthY = Math.abs(max.y - min.y)
  const lengthZ = Math.abs(max.z - min.z)
  // return lengths
  return {
    x: lengthX,
    y: lengthY,
    z: lengthZ,
  }
}
