export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <circle cx="15" cy="15" r="15" fill="#CEC4B5" />
      <line
        x1="9.39107"
        y1="9.50977"
        x2="21.0553"
        y2="21.174"
        stroke="#719086"
        strokeLinecap="round"
      />
      <line
        x1="21.0554"
        y1="9.39265"
        x2="9.39111"
        y2="21.0569"
        stroke="#719086"
        strokeLinecap="round"
      />
    </svg>
  )
}
