export default function SVG({ className, ...props }: any) {
  return (
    <svg
      id="FavoritesShare"
      width="90"
      height="41"
      viewBox="0 0 90 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M69.5 40L20.5 40C9.73045 40 1.00001 31.2696 1.00001 20.5C1 9.73046 9.73045 1.00001 20.5 1.00001L69.5 1C80.2696 1 89 9.73045 89 20.5C89 31.2696 80.2696 40 69.5 40Z"
        fill="#799B90"
        stroke="white"
        strokeWidth="2"
      />
      <path
        className="FavoritesShareHeart"
        d="M46.7305 16.7852C39.6609 17.7889 36.8439 22.733 35.8262 27.6957C38.3523 24.22 41.6782 22.6401 46.7305 22.6401V26.7106L54.0001 19.7591L46.7305 12.8262V16.7852Z"
        fill="#FAF6F6"
      />
      <path
        className="FavoritesShareArrow"
        d="M44.0869 30.1741L43.6938 29.9159C41.8249 28.6867 39.4602 27.2974 37.7089 25.2554C35.8632 23.1042 34.9777 20.8948 35.0004 18.5004C35.0279 15.8268 37.2048 13.6523 39.853 13.6523C41.9547 13.6523 43.3521 14.8571 44.0869 15.7266C44.8217 14.8571 46.2192 13.6523 48.3209 13.6523C50.9691 13.6523 53.1459 15.8268 53.1734 18.4992C53.1979 20.8948 52.3124 23.1029 50.4649 25.2541C48.7136 27.2974 46.3489 28.6867 44.4801 29.9159L44.0869 30.1741Z"
        fill="#FAF6F6"
      />
    </svg>
  )
}
