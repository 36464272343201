import TWEEN from "@tweenjs/tween.js"

const animateCamera = (camera, controls, cameraSettings) => {
  const { position, target, duration, enabled, fov } = cameraSettings
  controls.enabled = false
  const from = {}
  const to = {}

  if (position) {
    from.px = camera.position.x
    from.py = camera.position.y
    from.pz = camera.position.z
    to.px = position.x
    to.py = position.y
    to.pz = position.z
  }

  if (target) {
    from.tx = controls.target.x
    from.ty = controls.target.y
    from.tz = controls.target.z
    to.tx = target.x
    to.ty = target.y
    to.tz = target.z
  }

  if (fov) {
    from.fov = camera.fov
    to.fov = fov
  }

  return (
    new TWEEN.Tween(from)
      .to(to, duration ?? 5000)
      // .easing(TWEEN.Easing.Sinusoidal.In)
      .easing(TWEEN.Easing.Quintic.InOut)
      .onUpdate(function ({ px, py, pz, tx, ty, tz, fov }) {
        if (fov != null) {
          camera.fov = fov
        }
        if (px != null) {
          camera.position.set(px, py, pz)
        }
        if (tx != null) {
          controls.target.set(tx, ty, tz)
        }
      })
      .onComplete(() => {
        // console.log("camera tween complete")
        controls.enabled = enabled
      })
      .start()
  )
}

export default animateCamera
