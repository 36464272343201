import useSocketStore from "@state/useSocketStore"
import { useTransition } from "@react-spring/core"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { CustomBufferMeshElement } from "@canvas_components/ApartmentMesh/ApartmentMesh"
import { Color } from "three"
import { meshUnitData } from "@data/meshUnitData"
import { useEffect, useRef, useMemo } from "react"
import { a } from "@react-spring/three"
import { stringToInt } from "@utils/hooks"

type Props = {
  residences: any[]
}

const ApartmentFinderBlocks = ({ residences }: Props) => {
  const [nodesArr] = React.useState(() => Object.values(residences))

  const { pathname } = useLocation()

  const residencePopups = useSocketStore((s) => s.residencePopups)
  const filteredResults = useSocketStore((state) => state.filteredResults)

  const [activeResidence, setActiveResidence] = React.useState(null)
  const syncState = useSocketStore((s) => s.syncState)
  const activeUID = useSocketStore((s) => s.activeUID)

  React.useEffect(() => {
    if (pathname === "/residences" && !residencePopups.residenceContainerOpen) {
      setActiveResidence(nodesArr)
    } else {
      setActiveResidence(null)
    }
  }, [pathname, residencePopups, activeUID])

  let ids = useMemo(
    () =>
      filteredResults.map((result) => {
        return `${result["id"]}${result["building"].split(" ")[1]}`
      }),
    [filteredResults]
  )

  function clickApartment(e: any) {
    e.stopPropagation()
    let clicked = stringIndex[intIndex.indexOf(meshUnitData.ids[e.face.a])]

    if (!ids.includes(clicked)) return

    updatePopupOptions({
      activeResidence: clicked,
      residenceContainerOpen: true,
    })

    syncState("activeUID", clicked)
    // setActiveUID(clicked)
  }

  function updatePopupOptions(newValues) {
    const updatedPopupOptions = { ...residencePopups, ...newValues }
    syncState("residencePopups", updatedPopupOptions)
  }

  const [shouldDelay, setShouldDelay] = useState(true)

  useEffect(() => {
    if (residencePopups.residenceContainerOpen) {
      setShouldDelay(false)
    } else {
      setTimeout(() => {
        setShouldDelay(true)
      }, 2500)
    }
  }, [residencePopups.residenceContainerOpen])

  const transition = useTransition(activeResidence !== null, {
    from: {
      opacity: 0,
      delay: shouldDelay ? 1000 : 0,
    },
    enter: {
      opacity: 1,
      delay: shouldDelay ? 1000 : 1000,
      config: { duration: 500, clamp: true },
    },
    leave: {
      opacity: 0,
      delay: shouldDelay ? 0 : 300,
      config: { duration: 500, clamp: true },
    },
  })

  const colors = {
    active: new Color("#f2d074"),
    inActive: new Color("#9ba59d"),
  }

  useEffect(() => {
    if (pathname === "/residences") {
      gRef.current.position.y = 0
    } else {
      setTimeout(() => {
        gRef.current.position.y = 10
      }, 700)
    }
  }, [pathname])

  const gRef = useRef() as any

  return (
    <a.group ref={gRef}>
      {transition(
        ({ opacity }, item) =>
          item && (
            <CustomBufferMeshElement
              activeID={activeUID ? stringToInt(activeUID) : null}
              opacity={opacity}
              colors={colors}
              availibleUnits={filteredResults.filter(
                (result) => result["building"] !== "Tower A"
              )}
              meshUnitData={meshUnitData}
              handleClickMesh={clickApartment}
            />
          )
      )}
    </a.group>
  )
}

export default ApartmentFinderBlocks

const intIndex = [
  10011, 10012, 10041, 10042, 10051, 10052, 10061, 10062, 10071, 10072, 10081,
  10082, 10091, 10092, 10101, 10102, 10111, 10112, 10121, 10122, 4011, 4012,
  4021, 4022, 4031, 4032, 4041, 4042, 4051, 4052, 4061, 4062, 4071, 4072, 4081,
  4082, 4091, 4092, 4101, 4102, 4111, 4112, 4121, 4122, 5011, 5012, 5031, 5032,
  5041, 5042, 5051, 5052, 5061, 5062, 5071, 5082, 5091, 5092, 5101, 5102, 5111,
  5112, 5121, 5122, 6011, 6012, 6021, 6022, 6031, 6032, 6041, 6042, 6051, 6052,
  6061, 6062, 6071, 6072, 6081, 6082, 6091, 6092, 6101, 6102, 6111, 6112, 6121,
  6122, 7011, 7012, 7021, 7022, 7031, 7032, 7041, 7042, 7051, 7052, 7061, 7062,
  7071, 7072, 7081, 7082, 7091, 7092, 7101, 7102, 7111, 7112, 8011, 8012, 8021,
  8022, 8031, 8032, 8041, 8042, 8051, 8052, 8061, 8062, 8071, 8072, 8081, 8082,
  8091, 8092, 8101, 8102, 8111, 8112, 8121, 8122, 9011, 9012, 9021, 9022, 9031,
  9032, 9041, 9042, 9051, 9052, 9061, 9062, 9071, 9072, 9081, 9082, 9091, 9092,
  9101, 9102, 9111, 9112, 9121, 9122, 3102, 311, 312, 321, 322, 331, 332, 341,
  342, 351, 352, 361, 362, 371, 372, 381, 382, 392, 411, 412, 421, 422, 431,
  432, 441, 442, 451, 452, 461, 462, 471, 472, 481, 482,
]

const stringIndex = [
  "1001A",
  "1001B",
  "1004A",
  "1004B",
  "1005A",
  "1005B",
  "1006A",
  "1006B",
  "1007A",
  "1007B",
  "1008A",
  "1008B",
  "1009A",
  "1009B",
  "1010A",
  "1010B",
  "1011A",
  "1011B",
  "1012A",
  "1012B",
  "401A",
  "401B",
  "402A",
  "402B",
  "403A",
  "403B",
  "404A",
  "404B",
  "405A",
  "405B",
  "406A",
  "406B",
  "407A",
  "407B",
  "408A",
  "408B",
  "409A",
  "409B",
  "410A",
  "410B",
  "411A",
  "411B",
  "412A",
  "412B",
  "501A",
  "501B",
  "503A",
  "503B",
  "504A",
  "504B",
  "505A",
  "505B",
  "506A",
  "506B",
  "507A",
  "508B",
  "509A",
  "509B",
  "510A",
  "510B",
  "511A",
  "511B",
  "512A",
  "512B",
  "601A",
  "601B",
  "602A",
  "602B",
  "603A",
  "603B",
  "604A",
  "604B",
  "605A",
  "605B",
  "606A",
  "606B",
  "607A",
  "607B",
  "608A",
  "608B",
  "609A",
  "609B",
  "610A",
  "610B",
  "611A",
  "611B",
  "612A",
  "612B",
  "701A",
  "701B",
  "702A",
  "702B",
  "703A",
  "703B",
  "704A",
  "704B",
  "705A",
  "705B",
  "706A",
  "706B",
  "707A",
  "707B",
  "708A",
  "708B",
  "709A",
  "709B",
  "710A",
  "710B",
  "711A",
  "711B",
  "801A",
  "801B",
  "802A",
  "802B",
  "803A",
  "803B",
  "804A",
  "804B",
  "805A",
  "805B",
  "806A",
  "806B",
  "807A",
  "807B",
  "808A",
  "808B",
  "809A",
  "809B",
  "810A",
  "810B",
  "811A",
  "811B",
  "812A",
  "812B",
  "901A",
  "901B",
  "902A",
  "902B",
  "903A",
  "903B",
  "904A",
  "904B",
  "905A",
  "905B",
  "906A",
  "906B",
  "907A",
  "907B",
  "908A",
  "908B",
  "909A",
  "909B",
  "910A",
  "910B",
  "911A",
  "911B",
  "912A",
  "912B",
  "L10B",
  "L1A",
  "L1B",
  "L2A",
  "L2B",
  "L3A",
  "L3B",
  "L4A",
  "L4B",
  "L5A",
  "L5B",
  "L6A",
  "L6B",
  "L7A",
  "L7B",
  "L8A",
  "L8B",
  "L9B",
  "PH1A",
  "PH1B",
  "PH2A",
  "PH2B",
  "PH3A",
  "PH3B",
  "PH4A",
  "PH4B",
  "PH5A",
  "PH5B",
  "PH6A",
  "PH6B",
  "PH7A",
  "PH7B",
  "PH8A",
  "PH8B",
]
