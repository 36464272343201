export default function SVG({ className, click, ...props }: any) {
  return (
    <svg
      width="46"
      height="34"
      viewBox="0 0 46 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
      onClick={() => click()}
    >
      <path
        d="M29.1642 32.6719L16.8358 32.6719C8.08994 32.6719 0.999999 25.5819 0.999999 16.8361C0.999998 8.09018 8.08994 1.00024 16.8358 1.00024L29.1642 1.00024C37.9101 1.00024 45 8.09018 45 16.8361C45 25.5819 37.9101 32.6719 29.1642 32.6719Z"
        fill="#799B90"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M9.89606 15.8007C9.50554 16.1912 9.50554 16.8244 9.89606 17.2149L16.26 23.5789C16.6505 23.9694 17.2837 23.9694 17.6742 23.5789C18.0648 23.1884 18.0648 22.5552 17.6742 22.1647L12.0174 16.5078L17.6742 10.851C18.0648 10.4604 18.0648 9.82727 17.6742 9.43675C17.2837 9.04622 16.6505 9.04622 16.26 9.43675L9.89606 15.8007ZM35.2344 15.5078L10.6032 15.5078L10.6032 17.5078L35.2344 17.5078L35.2344 15.5078Z"
        fill="white"
      />
    </svg>
  )
}
