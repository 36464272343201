// import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {}

export const timelineAnimation = ({ timeline, index }) => {
  timeline.to(".FadeInStagger0", {
    autoAlpha: 1,
    stagger: 0.1,
    delay: 1,
    duration: 0.3,
    ease: "power1.inOut",
  })
  timeline.to(
    ".FadeInStagger",
    {
      duration: 1,
      autoAlpha: 1,
      stagger: 0.1,
      ease: "power3.inOut",
    },
    "<"
  )
}
