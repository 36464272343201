import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import useSocketStore from "@state/useSocketStore"
import globalStyles from "../../../style.global"

function Gallery() {
  const [index, setIndex] = useState(0)
  const currentImg = useRef(0)
  const residencePopups = useSocketStore((state) => state.residencePopups)
  const socket = useSocketStore((s) => s.socket)
  const images = [
    "/media/gallery/residences/gallery--residences-1.webp",
    "/media/gallery/residences/gallery--residences-2.webp",
    "/media/gallery/residences/gallery--residences-3.webp",
    "/media/gallery/residences/gallery--residences-4.webp",
  ]

  function handleImgChange(dir) {
    if (dir) {
      currentImg.current === images.length - 1
        ? currentImg.current = 0
        : currentImg.current = currentImg.current + 1
    } else {
      currentImg.current === 0
        ? currentImg.current = images.length - 1
        : currentImg.current = currentImg.current - 1
    }
    setIndex(currentImg.current)
  }
  useEffect(() => {
    socket?.on("got-galleryChange", (data) => {
      handleImgChange(data)
    })
  }, [])

  return (
    <Wrapper
      style={{
        opacity: residencePopups.residenceContainerOpen ? 1 : 0,
      }}
    >
      {images.map((src, i) => {
        return (
          <img
            key={`img${i}`}
            alt=""
            src={src}
            style={{
              opacity: i === index ? 1 : 0,
            }}
          />
        )
      })}
    </Wrapper>
  )
}

export default Gallery

const Wrapper = styled.div`
  position: absolute;
  z-index: 13;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: ${globalStyles.colors.green};
  transition: opacity 1s ease-out;
  transition-delay: 0.5s;

  & img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    transition: opacity 1s ease-out;
  }
`
