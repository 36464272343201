export const setTimelineAnimation = (timeline) => {
  //   timeline.set(
  //     [".SVGLogo", ".Subtitle"],
  //     {
  //       autoAlpha: 0,
  //     }
  //   )
}

export const timelineAnimation = ({ timeline }) => {
  timeline.to("#BGWrapper", {
    delay: 0.5,
    autoAlpha: 1,
    duration: 3,
    ease: "power1.inOut",
  })
  timeline.to(
    ".SVGLogo path",
    {
      delay: 1,
      autoAlpha: 1,
      duration: 4,
      stagger: 0.3,
      ease: "power4.out",
    },
    "<"
  )
  timeline.to(
    ".Subtitle",
    {
      autoAlpha: 1,
      duration: 1.8,
      delay: 1.5,
      stagger: 0.05,
    },
    "<"
  )
}
