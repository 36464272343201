//AS OF 06/15/2023

const statuses = [
  {
      "id": 33512,
      "value": "Available"
  },
  {
      "id": 33513,
      "value": "Prepping"
  },
  {
      "id": 33514,
      "value": "Pending"
  },
  {
      "id": 33515,
      "value": "Offer"
  },
  {
      "id": 33516,
      "value": "Multiple Offers"
  },
  {
      "id": 33517,
      "value": "Contract Out"
  },
  {
      "id": 33518,
      "value": "Contract Signed"
  },
  {
      "id": 33519,
      "value": "Closed"
  },
  {
      "id": 33520,
      "value": "Courtesy Hold"
  },
  {
      "id": 33521,
      "value": "Sales Rep Hold"
  },
  {
      "id": 33522,
      "value": "Under Reservation"
  },
  {
      "id": 33523,
      "value": "Multiple Reservations"
  },
  {
      "id": 33912,
      "value": "Inventory"
  }
]

export default statuses