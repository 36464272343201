// import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {
  timeline.set(["#LeftSide", "#RightSide", ".LeftSide"], { autoAlpha: 0 })
}

export const timelineAnimation = ({ timeline, index }) => {
  timeline.to("#LeftSide", {
    delay: 0.2,
    autoAlpha: 1,
    duration: 1.4,
    ease: "power1.inOut",
  })
  timeline.to(
    "#RightSide",
    {
      delay: 0.3,
      autoAlpha: 1,
      duration: 1.4,
      ease: "power1.inOut",
    },
    "<"
  )
  timeline.to(
    ".LeftSide",
    {
      delay: 0.4,
      duration: 1.4,
      autoAlpha: 1,
      stagger: 0.1,
    },
    "<"
  )
}
