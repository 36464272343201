import { useEffect, useState } from "react"
import styled from "styled-components"
import { sortBy } from "lodash-es"

import Favourite from "@components/SVG/Residences/SideBarIcons/Favourite"

import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"
import globalStyles from "../../style.global"
import statuses from "@data/inventoryStatuses"

function ApartmentsList({ ...props }) {
  const isIpad = useDeviceStore((state) => state.isIpad)
  const availableFilter = useSocketStore((s) => s.availableFilter)
  const [orderState, setOrderState] = useState("APT")
  const [orderDir, setOrderDir] = useState(true)
  const [activeApt, setActiveApt] = useState(null)
  const favorites = useSocketStore((state) => state.favorites)
  const filteredResults = useSocketStore((state) => state.filteredResults)
  const syncState = useSocketStore((state) => state.syncState)
  const apartments = useDeviceStore((s) => s.apartments)
  const [residencesData, setResidencesData] = useState(apartments)
  const [availableUnits, setAvailableUnits] = useState(apartments)

  const isFave = (apartment) =>
    favorites.some((fav) => fav === apartment["uid"])

  const isActive = (apartment) => {
    return activeApt === apartment
  }

  function trim(arr) {
    return arr.map((obj, i) => {
      return [
        obj["uid"],
        obj["price"],
        obj["bed"],
        obj["bath"],
        obj["exposure"],
        obj["floor"],
        obj["interiorSquareFootage"],
        <Favourite
          onClick={(e) => {
            e.stopPropagation()
            syncState(
              "favorites",
              favorites.includes(obj["uid"])
                ? favorites.filter((item) => item !== obj["uid"])
                : [...favorites, obj["uid"]]
            )
          }}
          active={isFave(obj)}
        />,
        obj,
      ]
    })
  }

  const orderByApartment = sortBy(residencesData, [
    function (o) {
      return parseInt(o["uid"])
    },
    function (o) {
      return parseInt(o["bed"])
    },
    function (o) {
      return parseInt(o["floor"])
    },
  ])

  const orderByPrice = sortBy(residencesData, [
    function (o) {
      return parseInt(o["price"])
    },
    function (o) {
      return parseInt(o["bed"])
    },
    function (o) {
      return parseInt(o["floor"])
    },
  ])

  const orderByBeds = sortBy(residencesData, [
    function (o) {
      return parseInt(o["bed"])
    },
    function (o) {
      return parseInt(o["price"])
    },
    function (o) {
      return parseInt(o["uid"])
    },
  ])

  const orderByBaths = sortBy(residencesData, [
    function (o) {
      return parseInt(o["bath"])
    },
    function (o) {
      return parseInt(o["price"])
    },
    function (o) {
      return parseInt(o["uid"])
    },
  ])

  const orderByAspect = sortBy(residencesData, [
    function (o) {
      return parseInt(o["exposure"])
    },
    function (o) {
      return parseInt(o["price"])
    },
    function (o) {
      return parseInt(o["uid"])
    },
  ])

  const orderByFloor = sortBy(residencesData, [
    function (o) {
      return parseInt(o["floor"])
    },
    function (o) {
      return parseInt(o["price"])
    },
    function (o) {
      return parseInt(o["bed"])
    },
  ])

  const orderByArea = sortBy(residencesData, [
    function (o) {
      return parseInt(o["interiorSquareFootage"])
    },
    function (o) {
      return parseInt(o["price"])
    },
    function (o) {
      return parseInt(o["bed"])
    },
  ])

  const orderTypes = {
    APT: orderDir ? trim(orderByApartment) : trim(orderByApartment).reverse(),
    PRICE: orderDir ? trim(orderByPrice) : trim(orderByPrice).reverse(),
    BEDS: orderDir ? trim(orderByBeds) : trim(orderByBeds).reverse(),
    BATHS: orderDir ? trim(orderByBaths) : trim(orderByBaths).reverse(),
    FLOOR: orderDir ? trim(orderByFloor) : trim(orderByFloor).reverse(),
    AREA: orderDir ? trim(orderByArea) : trim(orderByArea).reverse(),
    AVAILABLE: orderDir ? trim(availableUnits) : trim(availableUnits).reverse(),
  }

  const arrow = orderDir ? `\u2191` : `\u2193`

  const headers = [
    {
      label: (
        <p>
          APT <span>{arrow}</span>
        </p>
      ),
      state: "APT",
    },
    {
      label: (
        <p>
          PRICE $ <span>{arrow}</span>
        </p>
      ),
      state: "PRICE",
    },
    {
      label: (
        <p>
          BEDS <span>{arrow}</span>
        </p>
      ),
      state: "BEDS",
    },
    {
      label: (
        <p>
          BATHS <span>{arrow}</span>
        </p>
      ),
      state: "BATHS",
    },
    {
      label: <p>FACING</p>,
      state: null,
    },
    {
      label: (
        <p>
          FLOOR <span>{arrow}</span>
        </p>
      ),
      state: "FLOOR",
    },
    {
      label: (
        <p>
          SQ FT <span>{arrow}</span>
        </p>
      ),
      state: "AREA",
    },
    {
      label: <Favourite onClick={() => null} active={false} />,
      state: null,
    },
  ]

  useEffect(() => {
    setResidencesData(
      apartments.filter((apt) =>
        filteredResults.some(
          (result) =>
            result["id"] === apt["id"] && result["building"] === apt["building"]
        )
      )
    )
    setAvailableUnits(
      apartments.filter(
        (apt) =>
          apt["isAvailable"] &&
          filteredResults.some(
            (result) =>
              result["id"] === apt["id"] &&
              result["building"] === apt["building"]
          )
      )
    )
  }, [filteredResults, orderState])

  return (
    <TableWrapper>
      <TableContainer>
        <Table {...props}>
          <TableHeader>
            <tr>
              {headers.map((obj, i) => {
                return (
                  <th
                    key={`header${i}`}
                    className={orderState === obj.state ? "filteredBy" : ""}
                    onClick={() => {
                      if (orderState === obj.state) {
                        obj.state && setOrderDir(!orderDir)
                      } else {
                        obj.state && setOrderState(obj.state)
                      }
                    }}
                  >
                    {obj.label}
                  </th>
                )
              })}
            </tr>
          </TableHeader>
          <TableBody>
            {orderTypes[orderState].map((apt, i) => (
              <tr
                key={i}
                onClick={() => {
                  setActiveApt(apt[apt.length - 1]["uid"])
                  setTimeout(() => {
                    syncState("residencePopups", {
                      activeResidence: apt[apt.length - 1]["uid"],
                      residenceContainerOpen: true,
                    })
                  }, 800)
                  setTimeout(() => {
                    setActiveApt(null)
                  }, 1600)
                }}
                className={
                  isActive(apt[apt.length - 1]["uid"])
                    ? "active padTopBottom"
                    : " padTopBottom"
                }
              >
                {headers.map((txt, i) => {
                  let value = apt[i]

                  if (i === 0) {
                    if (apt[i].endsWith("A")) {
                      value = `${apt[i].slice(0, -1)} - Tower A`
                    } else if (apt[i].endsWith("B")) {
                      value = `${apt[i].slice(0, -1)} - Tower B`
                    }
                  }

                  if (i === 1) {
                    const number = Number(apt[i] / 100).toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                      }
                    )
                    value = number
                    const statusId = apt[apt.length - 1].status
                    let statusName = null
                    if (
                      Number(statusId) === Number(33512) ||
                      Number(statusId) === Number(33912)
                    ) {
                    } else {
                      statusName = statuses.find(
                        (status) => status.id === statusId
                      )
                    }
                    const tax = apt[apt.length - 1]["realEstateTax"]
                    const commonCharges = apt[apt.length - 1]["commonCharges"]

                    value = `${number}${
                      statusName !== null ? ` - ${statusName.value}` : ""
                    } ${
                      tax !== null && !isNaN(tax)
                        ? `\n\n RE Tax: $${Number(tax).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}`
                        : ""
                    }${
                      commonCharges !== null && !isNaN(commonCharges)
                        ? `\n\n Common Charges: $${Number(
                            commonCharges
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}`
                        : ""
                    }`
                  }

                  if (i === 5 && apt[i] === "3") {
                    value = "LANAI"
                  }
                  return <td key={`column${i}`}>{value}</td>
                })}
              </tr>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  )
}

export default ApartmentsList

const TableWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: minmax(0, 1.2fr) minmax(0, 10fr) minmax(0, 1.2fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 10fr) minmax(0, 1fr);
  /* transition: opacity 1s ease-in; */
`
const TableContainer = styled.div`
  grid-column: 2;
  grid-row: 2;
  height: 100%;
  width: 100%;
  /* position: relative;
  top: 5%;
  left: 10%;
  width: 80%;
  height: 90%;
  max-height: 90%; */
  overflow-y: scroll;
  touch-action: pan-y;
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  touch-action: pan-y;
  border: 0.14rem solid ${globalStyles.colors.white};
  .active {
    background-color: ${globalStyles.colors.green}66;
  }
  .filteredBy {
    & span {
      opacity: 1;
    }
  }

  & svg {
    transform: scale(0.8);
  }
`

const TableHeader = styled.thead`
  color: ${globalStyles.colors.white};
  background-color: ${globalStyles.colors.pink};
  height: 4rem;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  top: 0;
  position: sticky;
  z-index: 2;
  border: 0.2rem solid ${globalStyles.colors.white};

  & th {
    font-weight: 100;
    cursor: pointer;
    pointer-events: all;
    /* min-width: 14rem;
    max-width: 14rem; */

    & span {
      opacity: 0.3;
      transition: opacity 0.5s ease-in;
    }
  }
`

const TableBody = styled.tbody`
  max-height: 100%;
  text-align: center;
  font-weight: 100;
  position: relative;
  touch-action: pan-y;

  tr {
    background-color: ${globalStyles.colors.green};
    color: ${globalStyles.colors.white};
    border-bottom: 0.14rem solid ${globalStyles.colors.white};
    height: 3.2rem;
    line-height: 1.2rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    transition: background-color 0.5s ease-in-out;
    touch-action: pan-y;
    white-space: break-spaces;

    & td {
      touch-action: pan-y;
      pointer-events: all;
      cursor: pointer;
      padding: 0.7rem 0rem;
    }
  }
`
