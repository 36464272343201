import { useEffect, useLayoutEffect, useRef } from "react"
import { gsap } from "gsap"
import { useStore } from "@state/store"
import useSocketStore from "@state/useSocketStore"
import { useLocation } from "react-router-dom"

export function stringToInt(string) {
  return parseInt(
    string.replace("A", 1).replace("B", 2).replace("L", 3).replace("PH", 4)
  )
}

export function useOnClickOutside(ref, handler, ignoreRefs = []) {
  useEffect(
    () => {
      const listener = (event) => {
        // console.log(event.target.id)
        // Do nothing if clicking ref's element or descendent elements
        if (
          !ref.current ||
          // event.target.id === "ignore" ||
          ignoreRefs.some((ignoreRef) =>
            ignoreRef.current?.contains(event.target)
          ) ||
          ref.current.contains(event.target)
        ) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export const useAnimate = ({
  gsapRef,
  timelineAnimation,
  setTimelineAnimation,
  index = 0,
}) => {
  const timeline = useStore((state) => state.timeline)
  const setTimeline = useStore((state) => state.setTimeline)

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({ defaults: { duration: 1 } })
      setTimeline(tl)
    }, gsapRef)
    return () => ctx.revert()
  }, [])
  useLayoutEffect(() => {
    const fullTimeline = () => {
      setTimelineAnimation(timeline)
      timeline.add(timelineAnimation({ index, timeline }))
      timeline.play()
    }
    timeline && fullTimeline()
  }, [timeline])
}

export const InactivityDetector = () => {
  const room = useSocketStore((s) => s.room)

  const timeoutRef = useRef(null)
  const emitSocketEvent = useSocketStore((s) => s.emitSocketEvent)
  const location = useLocation()

  const handleNavigation = () => {
    emitSocketEvent("navigation", {
      path: "/",
      room: room,
    })
  }

  const startInactivityTimer = () => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(
      handleNavigation,
      // 10000
      3600000
    ) // 1 hour in milliseconds
  }

  const handleInteraction = () => {
    startInactivityTimer()
  }

  const handleRouteChange = () => {
    clearTimeout(timeoutRef.current)
    startInactivityTimer()
  }

  useEffect(() => {
    const events = ["mousedown", "mousemove", "keydown", "touchstart"]
    const handleEvent = () => {
      handleInteraction()
    }

    for (const event of events) {
      document.addEventListener(event, handleEvent)
    }

    startInactivityTimer()

    return () => {
      clearTimeout(timeoutRef.current)
      for (const event of events) {
        document.removeEventListener(event, handleEvent)
      }
    }
  }, [])

  useEffect(() => {
    handleRouteChange()
  }, [location.pathname])

  return null
}
