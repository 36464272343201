import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import Gallery from "@components/AuxResidence/Gallery"
import Views from "@components/AuxResidence/Views"
import ErrorMessage from "@pages/ErrorMessage"

import { useDeviceStore } from "@state/deviceStore"
import useSocketStore from "@state/useSocketStore"
import residencesData from "@data/residencesData.json"

function AuxResidences() {
  const auxScreen = useDeviceStore((s) => s.auxScreen)
  const [currentApt, setCurrentApt] = useState(null)
  const residencePopups = useSocketStore((state) => state.residencePopups)
  console.log(currentApt?.uid, "currentApt", currentApt)
  const src = `/media/residences/views/${currentApt?.uid}.webp`

  useEffect(() => {
    if (residencePopups.activeResidence) {
      setCurrentApt(
        residencesData.find(
          (residence) => residence.uid === residencePopups.activeResidence
        )
      )
    } else {
      setCurrentApt(null)
    }
  }, [residencePopups])

  return (
    <Wrapper>
      <ErrorMessage />
      {auxScreen === "left" ? <Gallery /> : <Views src={src} />}
    </Wrapper>
  )
}

export default AuxResidences

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
