//General Imports
import { Link, NavLink, useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import useSocketStore from "@state/useSocketStore"

//Components
import PrimaryLogo from "@components/SVG/PrimaryLogo"
import FavouriteIcon from "@components/SVG/Favourites"
import RoomIcon from "@components/SVG/Room"
import { routes } from "../../routes/routes"
import { useEffect, useRef, useState } from "react"
import RoomSelector from "@components/RoomSelector/RoomSelector"

//Types
interface NavTypes {
  home?: boolean
  page?: string
}

export default function Nav() {
  const [selectorOpen, setSelectorOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { socket, room, emitSocketEvent, setRoomTaken } = useSocketStore()
  let urlCheck = false

  useEffect(() => {
    socket?.on("got-navigation", (payload: string) => {
      navigate(payload)
    })
  }, [socket])

  useEffect(() => {
    setSelectorOpen(false)
    setRoomTaken(false)
  }, [room, location.pathname])

  useEffect(() => {
    urlCheck = location.pathname === "/"
  }, [location.pathname])

  return (
    <>
      <Header home={urlCheck}>
        <NavBar>
          <Logo
            to="/"
            onClick={() => {
              emitSocketEvent("navigation", {
                path: "/",
                room: room,
              })
              emitSocketEvent("refresh", {
                room: room,
              })
            }}
          >
            <PrimaryLogo className="NavLogo" />
          </Logo>
          <PagesList>
            {routes.map((route) => {
              if (route.isOnNavbar) {
                return (
                  <NavLink
                    key={route.path}
                    to={route.path}
                    style={{ textTransform: "uppercase" }}
                    className={({ isActive, isPending }) =>
                      isPending
                        ? "pending PageLink"
                        : isActive
                        ? "active PageLink"
                        : " PageLink"
                    }
                    onClick={() =>
                      emitSocketEvent("navigation", {
                        path: route.path,
                        room: room,
                      })
                    }
                  >
                    {route.name}
                  </NavLink>
                )
              }
            })}
          </PagesList>
          <Favourites
            title="/favorites"
            page={location.pathname}
            to="/favorites"
            onClick={() => {
              emitSocketEvent("navigation", {
                path: "/favorites",
                room: room,
              })
            }}
          >
            <FavouriteIcon className="PageLink" />
          </Favourites>
        </NavBar>
      </Header>
      <RoomWrapper>
        <RoomInnerWrap>
          <RoomSelector open={selectorOpen} setOpen={setSelectorOpen} />
          <Room
            room={room}
            onClick={() => {
              setSelectorOpen(true)
            }}
            id="roomBtn"
          >
            <RoomIcon className="RoomIcon" />
            <p className="roomNumber">{room ? room.substring(4) : "N"}</p>
          </Room>
        </RoomInnerWrap>
      </RoomWrapper>
    </>
  )
}

const Header = styled.header<NavTypes>`
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1 / -1;
  opacity: ${(props) => (props.home ? "0" : "1")};
  transition: opacity 1s ease-in-out;
  pointer-events: ${(props) => (props.home ? "none" : "auto")};
  z-index: 11;

  a {
    text-decoration: none;
    color: inherit;
  }
`

const NavBar = styled.nav<NavTypes>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.green};
  display: grid;
  overflow: hidden;
  grid-template-columns:
    minmax(0, 0.2fr) minmax(0, 2fr) minmax(0, 0.5fr) minmax(0, 4fr) minmax(
      0,
      0.5fr
    )
    minmax(0, 0.2fr) minmax(0, 0.2fr);
  align-items: center;
`

const Logo = styled(Link)<NavTypes>`
  grid-column: 2;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center left;
  z-index: 1;

  & svg {
    height: 70%;
    width: 70%;
  }

  & path {
    fill: ${(props) => props.theme.colors.grey};
  }
`

const PagesList = styled.ul<NavTypes>`
  color: ${(props) => props.theme.colors.grey};
  font-size: clamp(1rem, 2vw, 2rem);
  font-family: "Gilmer Medium", sans-serif;
  grid-column: 4;
  padding-right: 2rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .active {
    color: ${(props) => props.theme.colors.pink};
  }
`

const Favourites = styled(Link)<NavTypes>`
  grid-column: 5;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 1;

  & #favourites-heart {
    fill: ${(props) =>
      props.page === props.title
        ? props.theme.colors.pink
        : props.theme.colors.grey};
    transition: 1s;
  }

  & svg {
    height: 60%;
    width: auto;

    & path {
      stroke: ${(props) =>
        props.page === props.title
          ? props.theme.colors.pink
          : props.theme.colors.grey};
      transition: 1s;
    }
  }
`

const Room = styled.div<{ room: string }>`
  grid-column: 6 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 15;
  cursor: pointer;
  font-family: "Gilmer Medium", sans-serif;
  transition: color 0.4s;
  pointer-events: auto;
  color: ${(props) =>
    props.room ? props.theme.colors.grey : props.theme.colors.pink};

  & svg {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 60%;
    width: 100%;
    & path {
      transition: stroke 0.4s;
      /* fill: ${(props) => props.theme.colors.pink}; */
      stroke: ${(props) =>
        props.room ? props.theme.colors.grey : props.theme.colors.pink};
    }
  }

  & p {
    text-align: center;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    font-size: 1.4rem;
  }
`

const RoomWrapper = styled.div`
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1 / -1;
  z-index: 9999;
  pointer-events: none;
`

const RoomInnerWrap = styled.div`
  z-index: 9999;
  height: 100%;
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-columns:
    minmax(0, 0.2fr) minmax(0, 2fr) minmax(0, 0.5fr) minmax(0, 4fr) minmax(
      0,
      0.5fr
    )
    minmax(0, 0.2fr) minmax(0, 0.2fr);
  align-items: center;
  pointer-events: none;
`
