import { calculateSlide } from "./calculateSlide"

export function GetInitialActiveFavorite(favorites: any, apartments) {
  let validResidences = favorites.filter(
    (favorite: any) => calculateSlide(favorite, apartments) !== null
  )

  return validResidences[0]
}

export default GetInitialActiveFavorite
