import React from "react"
import styled from "styled-components"
import { useStore } from "@state/store"

type Props = {}

const ControlsCatcher = (props: Props) => {
  const canvasReference = React.useRef<any>(null)
  const setcontrolsCatcher = useStore((s) => s.setcontrolsCatcher)

  React.useEffect(() => {
    setcontrolsCatcher(canvasReference.current)
    return () => setcontrolsCatcher(null)
  }, [canvasReference])

  return <EventElementWr ref={canvasReference}></EventElementWr>
}

export default ControlsCatcher

const EventElementWr = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  cursor: pointer;
  position: absolute;
  z-index: 0;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  /* border: #04adfc9f solid 3px; */
  /* background-color: red; */
`
