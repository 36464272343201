//General
import React, { useCallback, useEffect } from "react"
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"

//Components
import BackArrow from "@components/SVG/BackArrow"
import { Link } from "react-router-dom"
import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"

//Types
interface StartTypes {}

export default function GalleryAmenities() {
  const syncState = useSocketStore((state) => state.syncState)
  const socket = useSocketStore((state) => state.socket)
  const room = useSocketStore((state) => state.room)
  const emitSocketEvent = useSocketStore((state) => state.emitSocketEvent)
  const setEmbla = useSocketStore((state) => state.setEmbla)
  const isIpad = useDeviceStore((s) => s.isIpad)

  const galleryNumbers = [
    "The Outdoor Kitchen",
    "The Outdoor Lobby",
    "The Pool Cabana",
    "The Porte Cochere",
    "The Billiard Room",
    "The Card Room",
    "The Club Room",
    "The Coworking Space",
    "The Dining Room",
    "The Fitness Centre",
    "Ariel View",
  ]

  //Initiate carousel
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    loop: true,
    speed: 20,
  })

  const onSelect = useCallback(
    (eventName) => {
      if (emblaApi) {
        const id = emblaApi.selectedScrollSnap()
        syncState("currentAmenityIndex", id)
        setEmbla(id)
      }
    },
    [emblaApi]
  )

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect)
    }
  }, [emblaApi, onSelect])

  useEffect(() => {
    socket.on("got-embla", ({ data }) => {
      // console.log(data)
      if (emblaApi) {
        // console.log("current id is: ", emblaApi.selectedScrollSnap())
        // console.log("incoming id is: ", data)
        if (data !== emblaApi.selectedScrollSnap()) {
          emblaApi.scrollTo(data)
        }
      }
    })
  }, [emblaApi])

  return (
    <>
      <Link
        to="/gallery"
        onClick={() => {
          emitSocketEvent("navigation", {
            path: "/gallery",
            room: room,
          })
        }}
      >
        <StyledBackArrow />
      </Link>
      {isIpad && <DragToScroll>DRAG TO SCROLL →</DragToScroll>}
      <Embla ref={emblaRef}>
        <EmblaContainer>
          {galleryNumbers.map((label, index) => (
            <EmblaSlide key={index}>
              <FullImage
                src={`/media/gallery/amenities/gallery--amenities-${
                  index + 1
                }.webp`}
                alt=""
              />
              <div className="ImageLabel">
                <p>{label}</p>
              </div>
            </EmblaSlide>
          ))}
        </EmblaContainer>
      </Embla>
    </>
  )
}

const DragToScroll = styled.div<StartTypes>`
  position: absolute;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-family: "Gilmer", sans-serif;
  padding: 0.7rem 2rem 0.7rem 2rem;
  bottom: 3%;
  right: 3%;
  background-color: ${(props) => props.theme.colors.green};
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 100px;
  z-index: 3;
  font-weight: 400;
`

const StyledBackArrow = styled(BackArrow)<StartTypes>`
  position: absolute;
  bottom: 3%;
  left: 3%;
  z-index: 3;
  width: 10rem;
  height: 4rem;
`

const Embla = styled.section<StartTypes>`
  height: 100%;
  width: 100%;
  & img {
    pointer-events: none;
  }
`

const EmblaContainer = styled.div<StartTypes>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
`

const EmblaSlide = styled.div<StartTypes>`
  flex: 0 0 100%;
  min-width: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.black};
  display: grid;
  place-items: center;
  grid-template-columns: minmax(0, 7.5fr) minmax(0, 1.4fr) minmax(0, 0.5fr);
  grid-template-rows: minmax(0, 0.5fr) minmax(0, 0.5fr) minmax(0, 9fr);

  .ImageLabel {
    height: 100%;
    width: 100%;
    grid-column: 2;
    grid-row: 2;
    background-color: ${(props) => props.theme.colors.green};
    border: 0.2rem solid ${(props) => props.theme.colors.white};
    display: grid;
    place-items: center;
    & p {
      font-size: 1vw;
      text-align: center;
      font-family: "Gilmer", sans-serif;
      padding: 0.7rem 2rem 0.7rem 2rem;
      color: ${(props) => props.theme.colors.white};
      display: grid;
      place-items: center;
    }
  }
`

const FullImage = styled.img<StartTypes>`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`
