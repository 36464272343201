import { create } from "zustand"
import { combine } from "zustand/middleware"
import io from "socket.io-client"
// @ts-ignore
import { localIP } from "@data/local_IP"
import { produce } from "immer"
import { initialState } from "./initialState"

const socket = io(localIP, { autoConnect: false })

export interface SocketPayload {
  room: string
  data: any
}

interface SocketStoreState {
  // socket fn
  socket: any
  room: "room1" | "room2" | "room3" | null
  connectSocket: (any) => void
  disconnectSocket: (room: "room1" | "room2" | "room3" | null) => void
  emitSocketEvent: (eventName: any, data: any) => void

  // get/set states
  path: string
  setPath: (v: boolean) => void
  socketSetPath: (data: string) => void
  roomTaken: boolean
  setRoomTaken: (v: boolean) => void
  roomStatus: {
    room1: boolean
    room2: boolean
    room3: boolean
    attempted: boolean
  } | null
}

const mutations = <SocketStoreState>(set) => {
  socket.on("connected", (data) => {
    // console.log(data)
  })

  socket.on("joinedRoom", (data) => {
    set({ room: data.room })
  })

  socket.on("switchedRoom", (data) => {
    // console.log(data.payload)
    set({ room: data.room })
  })

  socket.on("roomTaken", (data) => {
    // console.log(data)
    set({ roomStatus: data })
    set({ roomTaken: true })
  })

  socket.on("disconectAll", (data) => {
    // console.log(data)
    socket.emit("leaveRoom", data)
    set({ socket: null })
    set({ room: null })
    socket.disconnect()
  })

  socket.on("test", (data) => {
    // console.log(data)
  })
  socket.on("got-embla", (data) => {
    // console.log(data)
  })

  socket.on("got-refresh", (data) => {
    const { room } = useSocketStore.getState()
    set({ path: "/" })
    socket.emit("leaveRoom", room)
    socket.disconnect()
    setTimeout(() => {
      window.location.reload()
    }, 200)
  })

  socket.on("got-syncState", ({ data }: any) => {
    // console.log("RECEIVED SYNC STATE")
    set((state) =>
      produce(state, (draftState) => {
        const { stateToUpdate, payload } = data
        draftState[stateToUpdate] = payload
      })
    )
  })

  return {
    // socket
    socket,
    room: null,
    connectSocket: (data) => {
      set({ socket })
      socket.connect()
      socket.emit("joinRoom", data)
    },
    disconnectSocket: (room) => {
      socket.emit("leaveRoom", room)
      set({ socket: null })
      set({ room: null })
      socket.disconnect()
    },
    emitSocketEvent: (eventName, data) => {
      if (socket.connected) {
        socket.emit(eventName, data)
      }
    },

    // states
    path: "/",
    setPath: (v) => set({ path: v }),
    socketSetPath: (path) => {
      const { room } = useSocketStore.getState()
      socket.emit("navigation", { room, data: path })
      set({ path })
    },
    roomTaken: false,
    setRoomTaken: (bool) => {
      set({ roomTaken: bool })
    },
    roomStatus: null,

    setEmbla: (data) => {
      const { room } = useSocketStore.getState()
      socket.emit("embla", { room, data })
    },

    setAllEmbla: (data) => {
      const { room } = useSocketStore.getState()
      socket.emit("embla-all", { room, data })
    },

    setCameraMove: (data) => {
      const { room } = useSocketStore.getState()
      socket.emit("camera-moved", { room, data })
    },

    setCameraPos: (data) => {
      const { room } = useSocketStore.getState()
      socket.emit("cameraPos", { room, data })
    },
    syncState: (stateToUpdate, payload) => {
      const { room } = useSocketStore.getState()
      socket.emit("syncState", { room, data: { stateToUpdate, payload } })
      set((state) =>
        produce(state, (draftState) => {
          draftState[stateToUpdate] = payload
        })
      )
    },
  }
}

const useSocketStore = create(combine(initialState, mutations))
export default useSocketStore
