export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <line
        x1="1.55623"
        y1="1.04492"
        x2="16.5195"
        y2="16.0082"
        stroke="white"
        strokeLinecap="round"
      />
      <line
        x1="16.5195"
        y1="0.707107"
        x2="1.55625"
        y2="15.6704"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  )
}
