export const amenityData = {
  mainentrance_001: {
    title: "Porte Cochere",
    index: 0,
  },
  lobbylounge_001: {
    title: "Lobby Lounge",
    index: 1,
  },
  coworkingspace_001: {
    title: "Coworking Space",
    index: 2,
  },
  billiardroom_001: {
    title: "Billiard Room",
    index: 3,
  },
  clublounge_001: {
    title: "Club Lounge",
    index: 4,
  },
  diningroom_001: {
    title: "Dining Room",
    index: 5,
  },
  cardroom_002: {
    title: "Card Room",
    index: 6,
  },
  outdoorkitchen_003: {
    title: "Outdoor Kitchen",
    index: 7,
  },
  fitnesscentre_002: {
    title: "Fitness Centre",
    index: 8,
  },
  outdoorlobby_001: {
    title: "Outdoor Lobby",
    index: 9,
  },
  outdoorpool_002: {
    title: "Outdoor Pool",
    index: 10,
  },

  generalmain_005: {
    title: "General",
    index: 11,
  },
  generalmain_003: {
    title: "General",
    index: 12,
  },
  generalmain_004: {
    title: "General",
    index: 13,
  },
}
