//General
import styled from "styled-components"

//Components
import ResidencesTitle from "@components/SVG/ResidencesTitle"
import AmenitiesTitle from "@components/SVG/AmenitiesTitle"
import ViewsTitle from "@components/SVG/ViewsTitle"
import { Link } from "react-router-dom"
import { setTimelineAnimation, timelineAnimation } from "@animations/Gallery"
import { useAnimate } from "@utils/hooks"
import { useRef } from "react"
import useSocketStore from "@state/useSocketStore"

export default function Gallery() {
  const gsapRef = useRef()
  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })
  const room = useSocketStore((s) => s.room)
  const emitSocketEvent = useSocketStore((s) => s.emitSocketEvent)
  return (
    <GallerySection ref={gsapRef}>
      <BackgroundContainer>
        <Background
          style={{ opacity: 0 }}
          className="FadeInStagger0"
          src="/media/gallery/gallery--1.webp"
          alt="The Boardwalk"
        />
      </BackgroundContainer>
      <ResidencesContainer
        className="FadeInStagger"
        style={{ opacity: 0 }}
        to="/gallery/residences"
        onClick={() =>
          emitSocketEvent("navigation", {
            path: "/gallery/residences",
            room: room,
          })
        }
      >
        <HeaderImageContainer>
          <HeaderImage
            className="FadeInStagger"
            style={{ opacity: 0 }}
            src="/media/gallery/gallery--2.webp"
            alt="The Boardwalk"
          />
        </HeaderImageContainer>
        <TitleContainer className="FadeInStagger" style={{ opacity: 1 }}>
          <ResidencesTitle />
        </TitleContainer>
        <View className="FadeInStagger" style={{ opacity: 1 }} color="green">
          VIEW →
        </View>
      </ResidencesContainer>
      <AmenitiesContainer
        className="FadeInStagger2"
        style={{ opacity: 0 }}
        to="/gallery/amenities"
        onClick={() =>
          emitSocketEvent("navigation", {
            path: "/gallery/amenities",
            room: room,
          })
        }
      >
        <HeaderImageContainer>
          <HeaderImage
            className="FadeInStagger2"
            style={{ opacity: 0 }}
            src="/media/gallery/gallery--3.webp"
            alt="The Boardwalk"
          />
        </HeaderImageContainer>
        <TitleContainer className="FadeInStagger2" style={{ opacity: 0 }}>
          <AmenitiesTitle />
        </TitleContainer>
        <View className="FadeInStagger2" style={{ opacity: 0 }} color="pink">
          VIEW →
        </View>
      </AmenitiesContainer>
      <ViewsContainer
        className="FadeInStagger3"
        style={{ opacity: 0 }}
        to="/gallery/views"
        onClick={() =>
          emitSocketEvent("navigation", {
            path: "/gallery/views",
            room: room,
          })
        }
      >
        <HeaderImageContainer>
          <HeaderImage
            className="FadeInStagger3"
            style={{ opacity: 0 }}
            src="/media/gallery/gallery--4.webp"
            alt="The Boardwalk"
          />
        </HeaderImageContainer>
        <TitleContainer className="FadeInStagger3" style={{ opacity: 0 }}>
          <ViewsTitle />
        </TitleContainer>
        <View className="FadeInStagger3" style={{ opacity: 0 }} color="green">
          VIEW →
        </View>
      </ViewsContainer>
    </GallerySection>
  )
}

const GallerySection = styled.section`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(0, 6fr) minmax(0, 30fr) minmax(0, 6fr) minmax(0, 30fr)
    minmax(0, 6fr) minmax(0, 30fr) minmax(0, 6fr);
  grid-template-rows: repeat(24, minmax(0, 1fr));
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.grey};

  & img {
    pointer-events: none;
  }

  & a {
    text-decoration: none;
  }
`

const BackgroundContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
`
const Background = styled.img`
  transform: scale(110%);
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: 0;
`

const ResidencesContainer = styled(Link)`
  aspect-ratio: calc(473 / 692);
  width: 100%;
  grid-column-start: 2;
  grid-row-start: 2;
  z-index: 1;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-rows:
    minmax(0, 5fr) minmax(0, 0.2fr) minmax(0, 2fr)
    minmax(0, 0.2fr) minmax(0, 0.5fr) minmax(0, 0.2fr);
`

const AmenitiesContainer = styled(Link)`
  aspect-ratio: calc(473 / 692);
  width: 100%;
  grid-column-start: 4;
  grid-row-start: -3;
  align-self: end;
  z-index: 1;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-rows:
    minmax(0, 5fr) minmax(0, 0.2fr) minmax(0, 2fr)
    minmax(0, 0.2fr) minmax(0, 1fr) minmax(0, 0.2fr);
`

const ViewsContainer = styled(Link)`
  aspect-ratio: calc(473 / 692);
  width: 100%;
  grid-column-start: 6;
  grid-row-start: 3;
  z-index: 1;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-rows:
    minmax(0, 5fr) minmax(0, 0.2fr) minmax(0, 2fr)
    minmax(0, 0.2fr) minmax(0, 1fr) minmax(0, 0.2fr);
`

const HeaderImageContainer = styled.div`
  grid-row: 1;
  height: 100%;
  width: 100%;
  align-self: start;
`
const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`

const TitleContainer = styled.div`
  grid-row: 3;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 1rem 3rem;

  & svg {
    height: 70%;
    width: 100%;
  }
`

const View = styled.div`
  grid-row: 4;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilmer", sans-serif;
  color: ${(props) => props.theme.colors[`${props.color}`]};
  font-size: clamp(1rem, 1vw, 2rem);
  font-weight: 500;
`
