import styled from "styled-components"

import globalStyles from "../../style.global"

function TheTeamSlide({ ...props }) {
  return (
    <TeamSlideContainer {...props}>
      <Section>
        <img src="/media/start/b2kLogo.svg" alt="logo" />
        <p>
          B2K Development is a fully integrated real estate development firm,
          boasting a diverse portfolio of properties. The team is comprised of
          highly-skilled development, construction, and management professionals
          who work together seamlessly, using their extraordinary collective
          ability to reimagine what’s possible and apply experienced know-how to
          develop, build, and manage new communities and commercial properties.
        </p>
        <p>
          In recent years, B2K’s real estate portfolio has grown exponentially.
          This is largely attributed to their ability to anticipate the needs of
          the market, identify undiscovered opportunities, and their capacity to
          be fluid and responsive to real estate’s ever-changing landscape.
          B2K’s integrated approach is also critical, as it allows for the
          expertise and hands-on involvement required at every stage of each
          project’s lifecycle.
        </p>
      </Section>
      <Section>
        <img
          src="/media/start/douglasLogo.svg"
          alt="logo"
          style={{
            transform: "scale(1.2)",
          }}
        />
        <p>
          Douglas Elliman Development Marketing, a division of Douglas Elliman
          Realty, offers unmatched expertise in sales, leasing, and marketing
          for new developments throughout New York, New Jersey, Florida,
          California, Massachusetts, and Texas. The company’s new development
          hybrid platform matches highly experienced new development experts
          with skilled brokerage professionals who provide unparalleled
          expertise and real time market intelligence to its clients. The firm
          is heralded for its achievements in record breaking sales throughout
          each of its regions. Drawing upon decades of experience and
          market-specific knowledge, Douglas Elliman Development Marketing
          offers a multidisciplinary approach that includes comprehensive
          in-house research, planning and design, marketing, and sales. Through
          a strategic global alliance with Knight Frank Residential, the world’s
          largest privately-owned property consultancy, the company markets
          properties to audiences in 53 countries, representing an over $87
          billion global new development portfolio.
        </p>
      </Section>
      <Section>
        <img src="/media/start/ifLogo.svg" alt="logo" />
        <p>
          IF Studio is an award-winning branded content and graphic design
          agency specializing in image driven categories of business. Visual,
          artful story-tellers who believe in the limitless possibilities of
          emotional engagement.
        </p>
      </Section>
    </TeamSlideContainer>
  )
}

export default TheTeamSlide

const TeamSlideContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.grey};
  transform: scale(1.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
`

const Section = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & img {
    width: 30%;
  }

  & p {
    text-align: center;
    line-height: 1.8rem;
    font-size: 1.3rem;
    color: ${globalStyles.colors.black};
    @media (max-width: 1025px) {
      font-size: 1rem;
    }
  }
`
