//General
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

//Components
import { ToggleGroup, ToggleGroupItem } from "@radix-ui/react-toggle-group"
import * as Dialog from "@radix-ui/react-dialog"
import * as Slider from "@radix-ui/react-slider"
import * as Checkbox from "@radix-ui/react-checkbox"
import ResidencePopup from "@components/ResidencePopup"
import { setTimelineAnimation, timelineAnimation } from "@animations/Residences"

//SVGs
import SelectArrow from "@components/SVG/SelectArrow"
import Refresh from "@components/SVG/Favorites/FavoritesRefresh"
import ToggleTableOpen from "@components/SVG/Residences/SideBarIcons/TableOpen"
import ToggleTableClose from "@components/SVG/Residences/SideBarIcons/TableClose"
import ToggleIcon from "@components/ToggleIcon/ToggleIcon"

//Data
import residencesData from "@data/residencesData.json"
import { useAnimate } from "@utils/hooks"
import useSocketStore from "@state/useSocketStore"
import ApartmentListPopUp from "@components/ApartmentListPopUp/ApartmentListPopUp"
import { useDeviceStore } from "@state/deviceStore"
import globalStyles from "../style.global"

//Types
interface ResidencesTypes {
  value?: string
  floor?: string[]
}

interface Residence {
  id: string
  building: string
  price: number
  uid: string
  bed: string
  floor: string
  exposure: string[]
  // Include other properties as needed
}

export default function Residences() {
  const gsapRef = useRef()
  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation })
  const [localPrice, setLocalPrice] = useState([0, 0])

  const orderState = useSocketStore((s) => s.availableFilter)
  const [tableVisible, setTableVisible] = useState(false)
  const isIpad = useDeviceStore((s) => s.isIpad)

  // socket store
  const syncState = useSocketStore((state) => state.syncState)
  const residencePopups = useSocketStore((state) => state.residencePopups)

  //Stores
  const filteredResults = useSocketStore((state) => state.filteredResults)
  const filterOptions = useSocketStore((state) => state.filterOptions)

  const apartments = useDeviceStore((state) => state.apartments)
  const matchingResidences =
    orderState === "AVAILABLE"
      ? apartments
          .filter((residence) => residence["isAvailable"])
          .map((residence) => ({
            id: residence["id"],
            building: residence["building"],
            status: residence["status_id"],
          }))
      : apartments.map((residence) => ({
          id: residence["id"],
          building: residence["building"],
          status: residence["status_id"],
        }))

  useEffect(() => {
    const isFiltering =
      filterOptions.beds.length > 0 ||
      filterOptions.floor.length > 0 ||
      filterOptions.exposure.length > 0 ||
      filterOptions.priceMin !== filterOptions.initialPriceMin ||
      filterOptions.priceMax !== filterOptions.initialPriceMax

    const filteredApartments = apartments.filter(
      (residence) =>
        matchingResidences.some(
          (matchingResidence) =>
            matchingResidence.id === residence["id"] &&
            matchingResidence.building === residence["building"]
        ) &&
        (filterOptions.beds.includes(residence["bed"]) ||
          filterOptions.beds.length === 0) &&
        (filterOptions.floor.includes(residence["floor"]) ||
          filterOptions.floor.length === 0) &&
        (filterOptions.exposure.some((exp) =>
          residence["exposure"].includes(exp)
        ) ||
          filterOptions.exposure.length === 0) &&
        residence["price"] / 100 >= filterOptions.priceMin &&
        residence["price"] / 100 <= filterOptions.priceMax
    )

    const newFilteredResults = isFiltering
      ? filteredApartments.map((residence) => ({
          id: residence["id"],
          building: residence["building"],
          status: residence["status_id"],
        }))
      : matchingResidences

    syncState("filteredResults", newFilteredResults)
  }, [
    filterOptions.floor,
    filterOptions.beds,
    filterOptions.exposure,
    filterOptions.priceMin,
    filterOptions.priceMax,
    orderState,
    apartments,
  ])

  const initialResidencesCameraSettings = {
    position: [-0.5, 0.4, 0.7],
    target: [0, -0.2, 0],
    fov: 75,
    delay: 100,
    duration: 2000,
  }

  const [maxPriceBucket, setMaxPriceBucket] = useState(3000000)

  let priceBuckets = {
    "$875,000 - $1,249,999": [875000, 1249999],
    "$1,250,000 - $1,499,999": [1250000, 1499999],
    "$1,500,000 - $1,999,999": [1500000, 1999999],
    "$2,000,000 - $2,499,999": [2000000, 2499999],
    "$2,500,000 - $2,999,999": [2500000, 2999999],
    "$3,000,000 +": [3000000, maxPriceBucket],
  }

  useEffect(() => {
    if (apartments !== null) {
      const prices = apartments.map((residence) => residence["price"])
      const minPrice = Math.min(...prices) / 100
      const maxPrice = Math.max(...prices) / 100
      updateFilterOptions({
        priceMin: minPrice,
        priceMax: maxPrice,
        initialPriceMin: minPrice,
        initialPriceMax: maxPrice,
      })
      setLocalPrice([minPrice, maxPrice])
      setMaxPriceBucket(maxPrice)
    }
  }, [apartments])

  useEffect(() => {
    syncState("cameraSettings", initialResidencesCameraSettings)
    return () => {
      syncState("residencePoupups", {
        activeResidence: null,
        residenceContainerOpen: false,
        residenceFloorPlanOpen: false,
        residenceViewOpen: false,
      })
      updateFilterOptions({
        dropModelOpacity: false,
        floorFilterOpen: false,
        priceFilterOpen: false,
        floor: [],
        beds: [],
        exposure: [],
        priceMin: filterOptions.priceMin,
        priceMax: filterOptions.priceMax,
        initialPriceMin: filterOptions.priceMin,
        initialPriceMax: filterOptions.priceMax,
      })
      syncState("cameraSettings", initialResidencesCameraSettings)
    }
  }, [])

  //Values for filter options
  const floorList = {
    PENTHOUSE: "PH",
    "FLOOR 10": "10",
    "FLOOR 9": "9",
    "FLOOR 8": "8",
    "FLOOR 7": "7",
    "FLOOR 6": "6",
    "FLOOR 5": "5",
    "FLOOR 4": "4",
    LANAI: "3",
  }

  const bedsList = ["1", "2", "3", "4"]

  const exposureList = ["N", "S", "E", "W"]

  function updateFilterOptions(newValues) {
    const updatedFilterOptions = { ...filterOptions, ...newValues }
    syncState("filterOptions", updatedFilterOptions)
  }

  function outsideClick(e) {
    if (!e.target.dataset.selector) {
      // updateFilterOptions({
      //   floorFilterOpen: false,
      //   priceFilterOpen: false,
      // })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener("click", outsideClick)
    }, 200)

    return () => {
      window.removeEventListener("click", outsideClick)
    }
  }, [filterOptions, apartments])

  function handleTableToggle() {
    if (tableVisible) {
      setTableVisible(false)
    } else {
      setTableVisible(true)
    }
  }

  return (
    <>
      <ResidencesSection ref={gsapRef}>
        {isIpad && (
          <ToggleTableButton data-selector="selector">
            <ToggleTableOpen
              data-selector="selector"
              onClick={() => handleTableToggle()}
              style={{
                opacity: tableVisible ? 0 : 1,
                pointerEvents: tableVisible ? "none" : "auto",
              }}
            />

            <ToggleTableClose
              data-selector="selector"
              onClick={() => handleTableToggle()}
              style={{
                opacity: tableVisible ? 1 : 0,
                pointerEvents: tableVisible ? "auto" : "none",
              }}
            />
            <SeeAvailable data-selector="selector">
              <ToggleIcon
                data-selector="selector"
                cue={orderState === "APT"}
                onClick={() => {
                  syncState(
                    "availableFilter",
                    orderState === "AVAILABLE" ? "APT" : "AVAILABLE"
                  )
                }}
              />
              <p
                data-selector="selector"
                style={{
                  opacity: orderState !== "AVAILABLE" ? 1 : 0,
                }}
              >
                SWITCH TO <br />
                AVAILABLE UNITS
              </p>
              <p
                data-selector="selector"
                style={{
                  opacity: orderState === "AVAILABLE" ? 1 : 0,
                }}
              >
                SWITCH TO <br />
                ALL UNITS
              </p>
            </SeeAvailable>
          </ToggleTableButton>
        )}
        {residencePopups.activeResidence !== null && (
          <ResidencePopup uid={residencePopups.activeResidence} />
        )}
        {/* ---------------- Apartments Container ---------------- */}
        <ResidenceFinderContainer></ResidenceFinderContainer>
        <ResidenceFinderBG />

        {/* ---------------- Filter Bar ---------------- */}
        <FilterBar
          data-selector="selector"
          className="FadeInStagger0"
          style={{ visibility: "hidden" }}
        >
          {/* ---------------- Floor Dropdown ---------------- */}
          <FloorText
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
          >
            <p data-selector="selector">FLOOR </p>
          </FloorText>
          <FloorSelectContainer
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
            onClick={() =>
              updateFilterOptions({
                floorFilterOpen: !filterOptions.floorFilterOpen,
              })
            }
          >
            <p data-selector="selector">SELECT</p>
            <SelectArrow data-selector="selector" />
          </FloorSelectContainer>
          {/* ---------------- Popup for Floor Checkboxes ---------------- */}
          {filterOptions.floorFilterOpen && (
            <FloorCheckboxesPopup data-selector="selector">
              {/* ---------------- Actual Slider ---------------- */}
              {Object.entries(floorList).map(([name, value], index) => (
                <FloorCheckboxContainer
                  data-selector="selector"
                  key={index}
                  value={value}
                  floor={filterOptions.floor}
                  onClick={() =>
                    filterOptions.floor.includes(value)
                      ? updateFilterOptions({
                          floor: filterOptions.floor.filter((v) => v !== value),
                          dropModelOpacity: true,
                        })
                      : updateFilterOptions({
                          floor: [...filterOptions.floor, value],
                          dropModelOpacity: true,
                        })
                  }
                >
                  <CheckboxRoot
                    data-selector="selector"
                    key={index}
                    checked={filterOptions.floor.includes(value)}
                    onCheckedChange={() => null}
                  >
                    <p data-selector="selector">{name}</p>
                  </CheckboxRoot>
                </FloorCheckboxContainer>
              ))}
            </FloorCheckboxesPopup>
          )}

          {/* ---------------- Beds Buttons ---------------- */}
          <BedsText
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden", zIndex: 9 }}
          >
            <p data-selector="selector">BEDS</p>
          </BedsText>
          <StyledBedsToggleGroup
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
            type="multiple"
            value={filterOptions.beds}
          >
            {bedsList.map((item, index) => (
              <StyledToggleItem
                data-selector="selector"
                value={item}
                key={index}
                onClick={() =>
                  filterOptions.beds.includes(item)
                    ? updateFilterOptions({
                        beds: filterOptions.beds.filter((v) => v !== item),
                        dropModelOpacity: true,
                      })
                    : updateFilterOptions({
                        beds: [...filterOptions.beds, item],
                        dropModelOpacity: true,
                      })
                }
              >
                {item}
              </StyledToggleItem>
            ))}
          </StyledBedsToggleGroup>
          {/* ---------------- Exposure Buttons ---------------- */}
          <ExposureText
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
          >
            <p data-selector="selector">EXPOSURE</p>
          </ExposureText>
          <StyledExposureToggleGroup
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
            type="multiple"
            value={filterOptions.exposure}
          >
            {exposureList.map((item, index) => (
              <StyledToggleItem
                data-selector="selector"
                value={item}
                key={index}
                onClick={() =>
                  filterOptions.exposure.includes(item)
                    ? updateFilterOptions({
                        exposure: filterOptions.exposure.filter(
                          (v) => v !== item
                        ),
                        dropModelOpacity: true,
                      })
                    : updateFilterOptions({
                        exposure: [...filterOptions.exposure, item],
                        dropModelOpacity: true,
                      })
                }
              >
                {item}
              </StyledToggleItem>
            ))}
          </StyledExposureToggleGroup>
          {/* ---------------- Price Slider ---------------- */}
          <PriceText
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
          >
            <p>PRICE</p>
          </PriceText>
          <PriceSelectContainer
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
            onClick={() =>
              updateFilterOptions({
                priceFilterOpen: !filterOptions.priceFilterOpen,
              })
            }
          >
            <p data-selector="selector">SELECT</p>
            <SelectArrow data-selector="selector" />
          </PriceSelectContainer>
          {/* ---------------- Popup for Slider ---------------- */}
          {filterOptions.priceFilterOpen && (
            <PriceCheckboxesPopup data-selector="selector">
              {/* ---------------- Actual Slider ---------------- */}
              {Object.entries(priceBuckets).map(([name, value], index) => (
                <FloorCheckboxContainer
                  data-selector="selector"
                  key={index}
                  value={name}
                  floor={filterOptions.floor}
                  onClick={() => {
                    if (
                      filterOptions.priceMin ===
                        filterOptions.initialPriceMin &&
                      filterOptions.priceMax === filterOptions.initialPriceMax
                    ) {
                      updateFilterOptions({
                        priceMin: value[0],
                        priceMax: value[1],
                      })
                    } else if (
                      filterOptions.priceMin === value[0] &&
                      filterOptions.priceMax === value[1]
                    ) {
                      updateFilterOptions({
                        priceMin: filterOptions.initialPriceMin,
                        priceMax: filterOptions.initialPriceMax,
                      })
                    } else if (value[0] > filterOptions.priceMax) {
                      updateFilterOptions({
                        priceMax: value[1],
                      })
                    } else if (value[1] < filterOptions.priceMin) {
                      updateFilterOptions({
                        priceMin: value[0],
                      })
                    } else if (
                      value[0] <= filterOptions.priceMax &&
                      value[1] >= filterOptions.priceMax
                    ) {
                      // If bucket values are above the priceMin and priceMax range is deselected
                      updateFilterOptions({
                        priceMax: value[0] - 1,
                      })
                    } else if (
                      value[1] >= filterOptions.priceMin &&
                      value[0] <= filterOptions.priceMin
                    ) {
                      // If bucket values are below the priceMin and priceMax range is deselected
                      updateFilterOptions({
                        priceMin: value[1] + 1,
                      })
                    } else if (
                      value[0] > filterOptions.priceMin &&
                      value[1] < filterOptions.priceMax
                    ) {
                      // If bucket values are inside the priceMin and priceMax range is deselected
                      updateFilterOptions({
                        priceMax: value[0] - 1,
                      })
                    }
                  }}
                >
                  <CheckboxRoot
                    data-selector="selector"
                    key={index}
                    checked={
                      filterOptions.priceMin === value[0] &&
                      filterOptions.priceMax === value[1]
                    }
                    onCheckedChange={() => null}
                  >
                    <p
                      style={{
                        color:
                          filterOptions.priceMin <= value[0] &&
                          filterOptions.priceMax >= value[1] &&
                          !(
                            filterOptions.priceMin ===
                              filterOptions.initialPriceMin &&
                            filterOptions.priceMax ===
                              filterOptions.initialPriceMax
                          )
                            ? globalStyles.colors.gold
                            : globalStyles.colors.grey,
                      }}
                      data-selector="selector"
                    >
                      {name}
                    </p>
                  </CheckboxRoot>
                </FloorCheckboxContainer>
              ))}
            </PriceCheckboxesPopup>
          )}

          {/* ---------------- Refresh Button ---------------- */}
          <ResetContainer
            data-selector="selector"
            className="FadeInStagger"
            style={{ visibility: "hidden" }}
          >
            <Refresh
              data-selector="selector"
              onClick={() => {
                updateFilterOptions({
                  floorFilterOpen: false,
                  priceFilterOpen: false,
                  floor: [],
                  beds: [],
                  exposure: [],
                  priceMin: filterOptions.initialPriceMin,
                  priceMax: filterOptions.initialPriceMax,
                })
                setLocalPrice([
                  filterOptions.initialPriceMin,
                  filterOptions.initialPriceMax,
                ])
              }}
            />
          </ResetContainer>
        </FilterBar>
      </ResidencesSection>
      <ApartmentListPopUp
        data-selector="selector"
        tableVisible={tableVisible}
        setTableVisible={setTableVisible}
      />
    </>
  )
}

const ResidencesSection = styled.section`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(0, 92fr) minmax(0, 8fr);
  font-family: "Dreamer Bold", serif;
  color: ${({ theme }) => theme.colors.grey};
  font-size: clamp(1rem, 1vw, 2rem);
  z-index: 2;

  & button,
  fieldset,
  input {
    all: unset;
    cursor: pointer;
  }

  & button:focus {
    outline: none;
  }
`

const ToggleTableButton = styled.div`
  grid-column: 1 / -1;
  z-index: 11;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: grid;
  place-items: center;
  row-gap: 1rem;
  grid-template-columns:
    minmax(0, 0.2fr) minmax(0, 2fr) minmax(0, 0.5fr) minmax(0, 4fr) minmax(
      0,
      0.5fr
    )
    minmax(0, 0.2fr) minmax(0, 0.2fr);
  grid-template-rows: minmax(0, 1fr) minmax(0, 10fr);

  & svg {
    transition: opacity 1s;
    grid-column: 6 / -1;
    grid-row: 1;
    width: 55%;
    height: auto;
  }
`

const ResidencePopupContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 8;
`

const ResidenceFinderBG = styled.div`
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 1;
  pointer-events: none;
  z-index: -2;
  overflow: hidden;
`

const ResidenceFinderContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 1;
  pointer-events: none;
  z-index: 2;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  gap: 100px;

  &h2,
  p {
    pointer-events: none;
  }
`

const FilterBar = styled.div`
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 2;
  background-color: ${({ theme }) => theme.colors.green};
  display: grid;
  z-index: 13;
  grid-template-columns:
    minmax(0, 0.2fr) minmax(0, 1fr) minmax(0, 1.5fr) minmax(0, 0.2fr)
    minmax(0, 1fr) minmax(0, 2.5fr) minmax(0, 0.2fr) minmax(0, 1.5fr)
    minmax(0, 2.5fr) minmax(0, 0.2fr) minmax(0, 1fr) minmax(0, 1.5fr) minmax(
      0,
      0.2fr
    )
    minmax(0, 1fr)
    minmax(0, 0.2fr);
  grid-template-rows: minmax(0, 0.3fr) minmax(0, 1fr) minmax(0, 0.3fr);
  text-align: center;
  place-items: center;

  & p {
    height: 100%;
    width: 100%;
  }
`

const FloorText = styled.div`
  grid-column: 2;
  grid-row: 2;
`

const FloorSelectContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-column: 3;
  grid-row: 2;

  display: grid;
  place-items: center;
  align-content: center;
  z-index: 13;
  border-radius: 50px;
  box-sizing: border-box;
  padding: 0.4rem 3rem;
  border: 3px solid ${({ theme }) => theme.colors.gold};
  text-align: center;
  cursor: pointer;

  p {
    display: grid;
    place-items: center;
    color: ${({ theme }) => theme.colors.gold};
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    width: 100%;
  }

  svg {
    grid-column: 2;
    grid-row: 1;
    height: 100%;
    width: auto;
  }
`

const FloorCheckboxesPopup = styled.div`
  min-height: 30vh;
  min-height: 30dvh;
  width: 11vw;
  display: grid;
  place-items: center;
  z-index: 13;
  border-radius: 50px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.green};
  border: 3px solid ${({ theme }) => theme.colors.gold};
  text-align: center;
  font-size: clamp(1rem, 1vw, 2rem);
  position: absolute;
  left: 8vw;
  bottom: 10vh;
  bottom: 10dvh;
  overflow: hidden;
  padding: 1vw 1vw;
`

const PriceCheckboxesPopup = styled.div`
  min-height: 30vh;
  min-height: 30dvh;
  width: 16vw;
  display: grid;
  place-items: center;
  z-index: 13;
  border-radius: 50px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.green};
  border: 3px solid ${({ theme }) => theme.colors.gold};
  text-align: center;
  font-size: clamp(1rem, 1vw, 2rem);
  position: absolute;
  right: 6.4%;
  bottom: 10vh;
  bottom: 10dvh;
  overflow: hidden;
  padding: 1vw 1vw;
`

const FloorCheckboxContainer = styled.div<ResidencesTypes>`
  width: 100%;
  min-height: 5vh;
  min-height: 5dvh;
  /* transition: 0.4s; */

  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  display: grid;
  place-items: center;
  color: ${({ value, floor, theme }) =>
    floor.includes(value) ? theme.colors.gold : theme.colors.grey};

  p {
    font-size: clamp(1rem, 1vw, 2rem);
    text-align: center;
    height: 100%;
    width: 100%;
  }

  &:first-child {
    border-top: none;
  }
`
const CheckboxRoot = styled(Checkbox.Root)`
  height: 100%;
  width: 100%;
  background-color: pink;
  all: unset;
`

const BedsText = styled.div`
  grid-column: 5;
  grid-row: 2;
`

const StyledBedsToggleGroup = styled(ToggleGroup)`
  height: 100%;
  width: 100%;
  grid-column: 6;
  grid-row: 2;
  display: flex;
  justify-content: space-evenly;
  z-index: 13;
  align-items: center;
  border: 3px solid ${({ theme }) => theme.colors.gold};
  border-radius: 100px;

  & button {
    padding: 1vw;
  }
`

const StyledToggleItem = styled(ToggleGroupItem)`
  height: 100%;
  width: 100%;

  background-color: pink;

  & button {
    padding: 3rem;
  }

  &[data-state="on"] {
    color: ${({ theme }) => theme.colors.gold};
  }
  &[data-state="off"] {
    color: ${({ theme }) => theme.colors.grey};
  }
`

const ExposureText = styled.div`
  grid-column: 8;
  grid-row: 2;
`

const StyledExposureToggleGroup = styled(ToggleGroup)`
  height: 100%;
  width: 100%;
  grid-column: 9;
  grid-row: 2;
  display: flex;
  justify-content: space-evenly;
  z-index: 13;
  align-items: center;
  border: 3px solid ${({ theme }) => theme.colors.gold};
  border-radius: 100px;

  & button {
    padding: 1vw;
    color: ${({ theme }) => theme.colors.gold};
  }
`

const PriceText = styled.div`
  grid-column: 11;
  grid-row: 2;
`

const PriceSelectContainer = styled.div`
  grid-column: 12;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  align-content: center;
  z-index: 13;
  border-radius: 50px;
  box-sizing: border-box;
  padding: 0.4rem 3rem;
  border: 3px solid ${({ theme }) => theme.colors.gold};
  text-align: center;
  cursor: pointer;

  p {
    display: grid;
    place-items: center;
    color: ${({ theme }) => theme.colors.gold};
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    width: 100%;
  }

  svg {
    grid-column: 2;
    grid-row: 1;
    height: 100%;
    width: auto;
  }
`

const PriceSlider = styled.div`
  grid-column: 9 / 13;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 5fr) minmax(0, 1fr);
  column-gap: 1vw;
  place-items: center;
  z-index: 13;
  border-radius: 50px;
  box-sizing: border-box;
  padding: 0.4rem 3rem;
  background-color: ${({ theme }) => theme.colors.green};
  border: 3px solid ${({ theme }) => theme.colors.gold};
  text-align: center;
  transform: translateY(-8vh);
  transform: translateY(-8dvh);
  font-size: clamp(1rem, 1vw, 2rem);
`

const MinPrice = styled.div`
  grid-column: 1;
`
const MaxPrice = styled.div`
  grid-column: 3;
`

const SliderRoot = styled(Slider.Root)`
  grid-column: 2;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 100%;
`

const SliderTrack = styled(Slider.Track)`
  background-color: ${({ theme }) => theme.colors.grey};
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
`

const SliderRange = styled(Slider.Range)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 9999px;
  height: 100%;
`

const SliderThumb = styled(Slider.Thumb)`
  display: block;
  width: 0.7vw;
  height: 0.7vw;
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const ResetContainer = styled.div`
  grid-column: 14;
  grid-row: 2;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;

  cursor: pointer;

  & svg {
    height: 100%;
    width: 100%;
    z-index: 13;
    pointer-events: auto;
  }
`

const DialogOverlay = styled(Dialog.Overlay)`
  background-color: var(--blackA9);
  position: fixed;
  inset: 0;
  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
`

const SeeAvailable = styled.div`
  grid-column: 6 / -1;
  grid-row: 2 / 3;
  place-self: start;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: start center;
  grid-auto-flow: row;
  grid-template-rows: minmax(0, 0.2fr) minmax(0, 1fr) minmax(0, 4fr);
  z-index: 10;
  pointer-events: none;
  cursor: pointer;
  font-size: 1.5rem;
  /* padding: 0rem 3rem; */
  color: ${globalStyles.colors.green};
  /* display: flex;
  flex-direction: row;
  gap: 2%; */
  font-weight: 600;

  & p {
    padding: 0rem 0.3rem;
    grid-row: 2;
    grid-column: 1 / -1;
    width: 80%;
    height: 100%;
    display: grid;
    place-items: start center;
    text-align: center;
    line-height: 2rem;
    font-size: 1.3rem;
    /* position: absolute;
    top: 50%;
    left: 115%;
    transform: translateY(-50%); */
    font-weight: 500;
    transition: opacity 0.5s ease-in;
    /* white-space: nowrap; */
  }
`
