import { useEffect, useMemo, useRef } from "react"
import { BufferAttribute } from "three"
import UnitsMaterial from "./UnitsMaterial"
import { stringToInt } from "@utils/hooks"

export const CustomBufferMeshElement = ({
  colors,
  meshUnitData,
  availibleUnits,
  handleClickMesh,
  opacity,
  activeID,
}) => {
  // .map((result) =>

  const parentRef = useRef<any>()

  const { positions, normals, ids, indices, uvs } = useMemo(() => {
    return {
      positions: new Float32Array(meshUnitData.positions),
      uvs: new Float32Array(meshUnitData.uvs),
      normals: new Float32Array(meshUnitData.normals),
      ids: new Uint16Array(meshUnitData.ids),
      indices: new Uint16Array(meshUnitData.indices),
    }
  }, [meshUnitData])

  useEffect(() => {
    const bufferGeometry = parentRef.current.geometry
    const newArr = []
    const attrib = bufferGeometry.getAttribute("position")
    const count = attrib.count

    for (let i = 0; i < count; i++) {
      const status = availibleUnits.find((unit) => {
        // console.log(
        //   stringToInt(`${unit["id"]}${unit["building"].split(" ")[1]}`)
        // )
        return (
          stringToInt(`${unit["id"]}${unit["building"].split(" ")[1]}`) ===
          meshUnitData.ids[i]
        )
      })?.status

      // console.log(i, status)

      if (status === 33512) {
        newArr.push(1)
      } else if (status === 33912) {
        newArr.push(1)
      } else if (status === undefined) {
        newArr.push(3)
      } else {
        newArr.push(2)
      }
    }

    const array = new Uint16Array(newArr)

    const attribute = new BufferAttribute(array, 1)
    bufferGeometry.setAttribute("IDAtt", attribute)
  }, [availibleUnits])

  return (
    <>
      <mesh
        onClick={handleClickMesh}
        ref={parentRef}
        rotation={[-Math.PI / 2, 0, 0]}
      >
        <bufferGeometry>
          <bufferAttribute
            attach={`attributes-position`}
            array={positions}
            count={positions.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach={`attributes-uv`}
            array={uvs}
            count={uvs.length / 2}
            itemSize={2}
          />
          <bufferAttribute
            attach={`attributes-normal`}
            array={normals}
            count={normals.length / 3}
            itemSize={3}
          />
          <bufferAttribute
            attach={`index`}
            array={indices}
            count={indices.length}
            itemSize={1}
          />

          <bufferAttribute
            attach={`attributes-ids`}
            array={ids}
            count={ids.length}
            itemSize={1}
          />
        </bufferGeometry>
        <UnitsMaterial activeID={activeID} opacity={opacity} colors={colors} />
        {/* <meshBasicMaterial color={"black"} /> */}
      </mesh>
    </>
  )
}
