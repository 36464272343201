// import SplitType from "split-type"

export const setTimelineAnimation = (timeline) => {}

export const timelineAnimation = ({ timeline, index }) => {
  timeline.to(
    ".FadeInStagger",
    {
      duration: 1,
      autoAlpha: 1,
      stagger: 0.3,
      ease: "power3.inOut",
    },
    "<"
  )
  // timeline.to(
  //   ".FadeInStagger2",
  //   {
  //     duration: 0.4,
  //     delay: 1,
  //     autoAlpha: 1,
  //     stagger: 0.3,
  //     ease: "power3.inOut",
  //   },
  //   "<"
  // )
}
