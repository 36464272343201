export default function SVG({ className, ...props }: any) {
  return (
    <svg
      id="FavoritesRefresh"
      width="90"
      height="41"
      viewBox="0 0 90 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M69.5 40L20.5 40C9.73045 40 1.00001 31.2696 1.00001 20.5C1 9.73046 9.73045 1.00001 20.5 1.00001L69.5 1C80.2696 1 89 9.73045 89 20.5C89 31.2696 80.2696 40 69.5 40Z"
        fill="#799B90"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M47.8278 16.6856C47.8819 16.6585 47.9424 16.6223 48.008 16.569C48.1548 16.4497 48.2653 16.3018 48.2976 16.077C48.3441 15.7513 48.1774 15.4864 47.9205 15.3309C47.5327 15.0959 46.6677 15 45 15C41.1337 15 38 18.1337 38 22C38 25.8659 41.1337 29 45 29C48.8659 29 52 25.8659 52 22C52 21.6484 51.7148 21.3636 51.3636 21.3636C51.012 21.3636 50.7273 21.6484 50.7273 22C50.7273 25.1627 48.1627 27.7273 45 27.7273C41.8369 27.7273 39.2727 25.1627 39.2727 22C39.2727 18.8369 41.8369 16.2727 45 16.2727C45.8018 16.2727 46.3996 16.2978 46.8101 16.3431C46.9779 16.3614 47.1076 16.3829 47.199 16.4052C47.232 16.4131 47.2571 16.4207 47.2738 16.4266C47.2774 16.4278 47.279 16.4286 47.279 16.4282C47.2746 16.4259 47.2682 16.4227 47.2607 16.4183C47.2122 16.3889 47.1612 16.3427 47.1155 16.2699C47.0467 16.161 47.0177 16.0269 47.0364 15.8949C47.0503 15.7951 47.0873 15.7155 47.135 15.6519C47.1593 15.6189 47.1823 15.5958 47.205 15.5775C47.2249 15.5612 47.2396 15.5524 47.2631 15.5409C46.9477 15.696 46.818 16.0774 46.9731 16.3928C47.1282 16.7078 47.5097 16.8375 47.8251 16.6824L47.8278 16.6856Z"
        fill="#FBF8F8"
      />
      <path
        d="M44.2097 13.1387L47.7812 16.472V15.5291L44.2097 18.8625C43.9307 19.1225 43.9307 19.5446 44.2097 19.805C44.4883 20.065 44.9405 20.065 45.2196 19.805L48.7911 16.4716C49.0696 16.2112 49.0696 15.7891 48.7911 15.5287L45.2196 12.1953C44.9405 11.9349 44.4883 11.9349 44.2093 12.1953C43.9302 12.4553 43.9302 12.8774 44.2093 13.1378L44.2097 13.1387Z"
        fill="#FBF8F8"
      />
    </svg>
  )
}
