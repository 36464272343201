export const floorPlanIndex = {
  Plan0: "1001A1001B",
  Plan1: "401A601A701A801A901A401B601B701B801B901B",
  Plan2: "402A602A702A802A902A402B602B702B802B902B",
  Plan3: "403A503A603A703A803A903A403B503B603B703B803B903B",
  Plan4: "404A504A604A704A804A904A1004A404B504B604B704B804B904B1004B",
  Plan5: "405A505A605A705A805A905A1005A405B605B705B805B905B1005B",
  Plan6: "406A606A706A806A906A1006A406B506B606B706B806B906B1006B",
  Plan7: "407A507A607A707A807A907A1007A407B607B707B807B907B1007B",
  Plan8: "408A608A708A808A908A1008A408B508B608B708B808B908B1008B",
  Plan9: "409A509A609A709A809A909A1009APH5A409B509B609B709B809B909B1009BPH5B",
  Plan10: "410A510A610A710A810A910A1010APH6A410B510B610B710B810B910B1010BPH6B",
  Plan11: "411A511A611A811A911A1011APH7A411B511B611B811B911B1011BPH7B",
  Plan12: "412A512A612A812A912A1012APH8A412B512B612B812B912B1012BPH8B",
  Plan13: "501A",
  Plan14: "501B",
  Plan15: "505B",
  Plan16: "506A",
  Plan17: "711A",
  Plan18: "711B",
  Plan19: "L10B",
  Plan20: "L1A",
  Plan21: "L1B",
  Plan22: "L2A",
  Plan23: "L2B",
  Plan24: "L3A",
  Plan25: "L3B",
  Plan26: "L4A",
  Plan27: "L4B",
  Plan28: "L5A",
  Plan29: "L5B",
  Plan30: "L6A",
  Plan31: "L6B",
  Plan32: "L7AL9B",
  Plan33: "L7B",
  Plan34: "L8A",
  Plan35: "L8B",
  Plan36: "PH1APH1B",
  Plan37: "PH2APH2B",
  Plan38: "PH3APH3B",
  Plan39: "PH4APH4B",
}
