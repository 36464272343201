export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="54"
      height="40"
      viewBox="0 0 54 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M1 20C1 9.50659 9.50659 1 20 1H33.8776C44.371 1 52.8776 9.50659 52.8776 20C52.8776 30.4934 44.371 39 33.8776 39H20C9.50659 39 1 30.4934 1 20Z"
        stroke="#D0CBBC"
        strokeWidth="2"
      />
      <path
        d="M28.4146 27.4126C28.3647 27.46 28.3146 27.5077 28.2643 27.5555L27.3908 28.3725L26.5171 27.5555C26.4658 27.5067 26.4147 27.4581 26.3639 27.4097C24.2636 25.4111 22.5563 23.7864 21.3599 22.262C20.1411 20.7091 19.5 19.318 19.5 17.8129C19.5 15.3563 21.3094 13.5 23.6147 13.5C24.9233 13.5 26.1683 14.1249 27.0025 15.1527L27.3907 15.6311L27.779 15.1527C28.613 14.1249 29.8579 13.5 31.1667 13.5C33.4721 13.5 35.2814 15.3561 35.2814 17.8129C35.2814 19.318 34.6404 20.7092 33.4216 22.2621C32.2245 23.7873 30.5162 25.4128 28.4146 27.4126Z"
        fill="#D0CBBC"
        id="favourites-heart"
        stroke="#D0CBBC"
      />
    </svg>
  )
}
