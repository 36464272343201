export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="54"
      height="40"
      viewBox="0 0 54 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M1 20C1 9.50659 9.50659 1 20 1H33.8776C44.371 1 52.8776 9.50659 52.8776 20C52.8776 30.4934 44.371 39 33.8776 39H20C9.50659 39 1 30.4934 1 20Z"
        stroke="#D0CBBC"
        strokeWidth="2"
      />
    </svg>
  )
}
