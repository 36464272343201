import View360Label from "@canvas_components/Amenities/View360Label/View360Label"
import useSocketStore from "@state/useSocketStore"
import { useLocation } from "react-router-dom"
import { Vector3 } from "three"

const Labels = () => {
  const syncState = useSocketStore((state) => state.syncState)

  const { pathname } = useLocation()

  const handleClick360 = (e, area) => {
    e.stopPropagation()
    if (pathname === "/amenities") {
      setTimeout(() => syncState("view360", { show: true, material: area }), 1)
    }
  }

  return (
    <>
      <View360Label
        active={pathname === "/amenities" ? "active" : null}
        pos={new Vector3(-0.06, -0.03, -0.05)}
        scale={new Vector3(0.011, 0.011, 0.011)}
        area="Lobby"
        click={handleClick360}
      />
      <View360Label
        active={pathname === "/amenities" ? "active" : null}
        pos={new Vector3(0.09, -0.03, -0.26)}
        scale={new Vector3(0.011, 0.011, 0.011)}
        area="BBQ"
        click={handleClick360}
      />
      <View360Label
        active={pathname === "/amenities" ? "active" : null}
        pos={new Vector3(-0.051, -0.025, 0.38)}
        scale={new Vector3(0.011, 0.011, 0.011)}
        area="Entrance"
        click={handleClick360}
      />
      <View360Label
        active={pathname === "/amenities" ? "active" : null}
        pos={new Vector3(-0.39, -0.025, 0.35)}
        scale={new Vector3(0.011, 0.011, 0.011)}
        area="Pool"
        click={handleClick360}
      />
    </>
  )
}

export default Labels
