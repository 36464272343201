import MeshElement from "@canvas_components/Amenities/MeshElement"
import { useTransition } from "@react-spring/core"
import useSocketStore from "@state/useSocketStore"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useRef } from "react"

type Props = {
  nodes?: any[]
  amenityMaps?: any
  opacity?: any
}

const AmenityModel = ({ nodes }: Props) => {
  const { pathname } = useLocation()

  const view360 = useSocketStore((state) => state.view360)
  const [elements, setElements] = useState(null)

  useEffect(() => {
    if (location.pathname === "/amenities" && !view360.show) {
      setElements(nodes)
    } else {
      setElements(null)
    }
  }, [pathname, view360])

  const transition = useTransition(elements, {
    from: { opacity: 0 },
    enter: { opacity: 1, delay: 1000 },
    leave: {
      opacity: 0,
      delay: 0,
      config: { duration: 500, clamp: true },
    },
  })

  const gRef = useRef() as any

  useEffect(() => {
    if (pathname === "/amenities") {
      gRef.current.position.y = 0
    } else {
      setTimeout(() => {
        gRef.current.position.y = 10
      }, 700)
    }
  }, [pathname])

  return (
    <group ref={gRef}>
      {transition(
        ({ opacity }, item) =>
          item && <MeshElement opacity={opacity} node={item} />
      )}
    </group>
  )
}

export default AmenityModel
