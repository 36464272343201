export default function SVG({ className, ...props }: any) {
  return (
    <svg
      width="54"
      height="40"
      viewBox="0 0 54 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M20 1H33.8776C44.371 1 52.8776 9.50659 52.8776 20C52.8776 30.4934 44.371 39 33.8776 39H20C9.50658 39 1 30.4934 1 20C1 9.50659 9.50659 1 20 1Z"
        fill="#719086"
        stroke="#D0CBBC"
        strokeWidth="2"
      />
      <path
        d="M20.6366 28.0314L35.4858 13.1821C36.0714 12.5965 36.0714 11.6464 35.4858 11.0608C34.8998 10.4748 33.9501 10.4752 33.3645 11.0608L18.5152 25.91C17.9292 26.4961 17.9292 27.4453 18.5152 28.0314C19.1008 28.6169 20.0506 28.6174 20.6366 28.0314Z"
        fill="#D0CBBC"
      />
      <path
        d="M18.0604 13.182L32.9097 28.0312C33.4952 28.6168 34.4454 28.6168 35.031 28.0312C35.617 27.4452 35.6166 26.4955 35.031 25.9099L20.1818 11.0607C19.5957 10.4746 18.6464 10.4746 18.0604 11.0607C17.4749 11.6462 17.4744 12.596 18.0604 13.182Z"
        fill="#D0CBBC"
      />
    </svg>
  )
}
