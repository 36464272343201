//@ts-ignore
import { GLTFLoader, DRACOLoader, MeshoptDecoder } from "three-stdlib"
import { useLoader } from "@react-three/fiber"

let dracoLoader: DRACOLoader 

function extensions(useDraco, useMeshopt, extendLoader) {
  return (loader) => {
    if (extendLoader) {
      extendLoader(loader)
    }

    if (useDraco) {
      if (!dracoLoader) {
        dracoLoader = new DRACOLoader()
      }

      dracoLoader.setDecoderPath(
        typeof useDraco === "string" ? useDraco : "/draco/"
      )
      loader.setDRACOLoader(dracoLoader)
    }

    if (useMeshopt) {
      loader.setMeshoptDecoder(
        typeof MeshoptDecoder === "function" ? MeshoptDecoder() : MeshoptDecoder
      )
    }
  }
}

const useGLTF = (
  path: string,
  useDraco?: boolean,
  useMeshOpt?: boolean,
  extendLoader?: (loader: GLTFLoader) => void
) => {
  if (useDraco === undefined) {
    useDraco = true
  }
  if (useMeshOpt === undefined) {
    useMeshOpt = true
  }

  const gltf = useLoader(
    GLTFLoader,
    path,
    extensions(useDraco, useMeshOpt, extendLoader)
  )
  return gltf as any
}

useGLTF.preload = (path, useDraco = true, useMeshOpt = true, extendLoader) =>
  useLoader.preload(
    GLTFLoader,
    path,
    extensions(useDraco, useMeshOpt, extendLoader)
  )

useGLTF.clear = (input) => useLoader.clear(GLTFLoader, input)

export { useGLTF }
