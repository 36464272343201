//General
import styled from "styled-components"
import useEmblaCarousel from "embla-carousel-react"

//SVGs
import Start1 from "@components/SVG/Start1"
import Start2 from "@components/SVG/Start2"
import Start3 from "@components/SVG/Start3"
import Start4 from "@components/SVG/Start4"
import Start5 from "@components/SVG/Start5"
import { useCallback, useEffect, useLayoutEffect, useRef } from "react"
import { useAnimate } from "@utils/hooks"
import { setTimelineAnimation, timelineAnimation } from "@animations/Home"
import { useStore } from "@state/store"
import useSocketStore from "@state/useSocketStore"
import { useDeviceStore } from "@state/deviceStore"

import TheTeamSlide from "@components/AboutSlides/TheTeamSlide"

//Types
interface StartTypes {}

export default function Home() {
  const timeline = useStore((state) => state.timeline)
  const syncState = useSocketStore((state) => state.syncState)
  const socket = useSocketStore((s) => s.socket)
  const setEmbla = useSocketStore((s) => s.setEmbla)
  const isIpad = useDeviceStore((s) => s.isIpad)
  //Initiate carousel
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    containScroll: "trimSnaps",
    loop: true,
    draggable: true,
    speed: 20,
  })
  const gsapRef = useRef()
  let index = 0
  useLayoutEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", () => {
        index = emblaApi.selectedScrollSnap()
        timeline.clear()
        timeline.add(timelineAnimation({ timeline, index }))
        timeline.play()
      })
    }
  }, [emblaApi])

  useAnimate({ gsapRef, timelineAnimation, setTimelineAnimation, index })

  const onSelect = useCallback(
    (eventName) => {
      if (emblaApi) {
        const id = emblaApi.selectedScrollSnap()
        syncState("currentAmenityIndex", id)
        setEmbla(id)
      }
    },
    [emblaApi]
  )

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect)
    }
  }, [emblaApi, onSelect])

  useEffect(() => {
    socket?.on("got-embla", ({ data }) => {
      // console.log(data)
      if (emblaApi) {
        // console.log("current id is: ", emblaApi.selectedScrollSnap())
        // console.log("incoming id is: ", data)
        if (data !== emblaApi.selectedScrollSnap()) {
          emblaApi.scrollTo(data)
        }
      }
    })
  }, [emblaApi])

  return (
    <StartSection ref={gsapRef}>
      {isIpad && <DragToScroll>DRAG TO SCROLL →</DragToScroll>}
      <Embla ref={emblaRef}>
        <EmblaContainer>
          <EmblaSlide className="EmblaSlide" color="green">
            <InfoDiv color="green">
              <TitleText>
                <Start1 className="SVGTitle0" />
              </TitleText>
              <Paragraph className="Paragraph0">
                Introducing The Boardwalk, a first-of-its-kind condominium on
                New York’s iconic and historic Long Beach. Situated along 3.5
                miles of pristine Atlantic shoreline, elegantly designed
                interiors—and more than 40,000 square feet of resort-inspired
                amenities—await to herald a new level of coastal living.
              </Paragraph>
            </InfoDiv>
            <ImageContainer>
              <SlideImage
                className="SlideImage0"
                src="/media/start/start--1.webp"
                alt="The Boardwalk"
              />
            </ImageContainer>
          </EmblaSlide>

          <EmblaSlide className="EmblaSlide" color="gold">
            <InfoDiv color="gold">
              <TitleText style={{ gridRow: "1 / -1" }}>
                <Start2 className="SVGTitle1" />
              </TitleText>
              {/* <Paragraph className="Paragraph1">
                Introducing The Boardwalk, a first-of-its-kind condominium on
                New York’s iconic and historic Long Beach. Situated along 3.5
                miles of pristine Atlantic shoreline, elegantly designed
                interiors—and more than 30,000 square feet of resort-inspired
                amenities—await to herald a new level of coastal living.
              </Paragraph> */}
            </InfoDiv>
            <ImageContainer>
              <TheTeamSlide className="SlideImage1" />
            </ImageContainer>
          </EmblaSlide>

          <EmblaSlide className="EmblaSlide" color="pink">
            <InfoDiv color="pink">
              <TitleText>
                <Start3 className="SVGTitle2" />
              </TitleText>
              <Paragraph className="Paragraph2">
                Long Beach is known for its white sandy beach, calming surf, and
                iconic boardwalk where residents experience coastal living at
                its finest—while benefiting from being less than an hour commute
                to Manhattan.
              </Paragraph>
            </InfoDiv>
            <ImageContainer>
              <SlideImage
                className="SlideImage2"
                src="/media/start/start--2.webp"
                alt="The Boardwalk"
              />
            </ImageContainer>
          </EmblaSlide>

          <EmblaSlide className="EmblaSlide" color="green">
            <InfoDiv color="green">
              <TitleText>
                <Start4 className="SVGTitle3" />
              </TitleText>
              <Paragraph className="Paragraph3">
                With over 40,000 square feet of resort inspired indoor and
                outdoor amenities, owners have their pick of lifestyle-enhancing
                experiences dedicated to wellness, recreation and connection.
              </Paragraph>
            </InfoDiv>
            <ImageContainer>
              <SlideImage
                className="SlideImage3"
                src="/media/start/start--3.webp"
                alt="The Boardwalk"
              />
            </ImageContainer>
          </EmblaSlide>

          <EmblaSlide className="EmblaSlide" color="gold">
            <InfoDiv color="gold">
              <TitleText>
                <Start5 className="SVGTitle4" />
              </TitleText>
              <Paragraph className="Paragraph4">
                The refined interiors epitomize modern coastal charm. The
                open-plan living spaces, expansive private terraces and
                panoramic views offer seamless indoor-outdoor living.
              </Paragraph>
            </InfoDiv>
            <ImageContainer>
              <SlideImage
                className="SlideImage4"
                src="/media/start/start--4.webp"
                alt="The Boardwalk"
              />
            </ImageContainer>
          </EmblaSlide>
        </EmblaContainer>
      </Embla>
    </StartSection>
  )
}

const StartSection = styled.section<StartTypes>`
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.black};
`

const DragToScroll = styled.div<StartTypes>`
  position: absolute;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-family: "Gilmer", sans-serif;
  padding: 0.7rem 2rem 0.7rem 2rem;
  bottom: 3%;
  right: 3%;
  background-color: ${(props) => props.theme.colors.green};
  border: 2px solid ${(props) => props.theme.colors.white};
  border-radius: 100px;
  z-index: 3;
  font-weight: 400;
`

const Embla = styled.div<StartTypes>`
  height: 100%;
  width: 100%;
`

const EmblaContainer = styled.div<StartTypes>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
`

const EmblaSlide = styled.div<StartTypes>`
  display: grid;
  place-items: center;
  grid-template-columns: minmax(0, 3fr) minmax(0, 7fr);
  flex: 0 0 100%;
  min-width: 0;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors[`${props.color}`]};

  .SVGTitle0 path,
  .SVGTitle1 path,
  .SVGTitle2 path,
  .SVGTitle3 path,
  .SVGTitle4 path {
    visibility: hidden;
  }
  .SVGParagraph0,
  .SVGParagraph1,
  .SVGParagraph2,
  .SVGParagraph3,
  .SVGParagraph4 {
    visibility: hidden;
  }
  .SVGTitle1 {
    height: 60%;
  }
  /* .SlideImage0,
  .SlideImage1,
  .SlideImage2,
  .SlideImage3 {
    visibility: hidden;
  } */
`

const InfoDiv = styled.div<StartTypes>`
  overflow: hidden;
  height: 100%;
  width: 100%;
  grid-column: 1;
  grid-row: 1 / -1;
  background-color: ${(props) => props.theme.colors[`${props.color}`]};
  color: ${(props) => props.theme.colors.white};
  display: grid;
  place-items: center;
  grid-template-rows: minmax(0, 3fr) minmax(0, 1fr);
  grid-auto-flow: row;
  text-align: center;
  row-gap: 2rem;
  padding: 10rem 5rem;
`

const TitleText = styled.div<StartTypes>`
  height: 100%;
  width: 100%;
  display: grid;
  place-self: end center;
  place-items: center;
  padding: 1rem;
  overflow: hidden;

  & svg {
    height: 100%;
    width: auto;
  }
`

const Paragraph = styled.p<StartTypes>`
  place-self: start center;
  height: 100%;
  width: 70%;
  font-family: "Gilmer", sans-serif;
  font-weight: 100;
  padding: 1rem;
  /* padding: 1rem 4rem; */
  font-size: clamp(1rem, 0.8vw, 2rem);
  visibility: hidden;
  display: grid;
  place-items: center;

  & p {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
  }
`

const ImageContainer = styled.div<StartTypes>`
  height: 100%;
  width: 100%;
  grid-column: 2;
  grid-row: 1 / -1;
  overflow: hidden;
`

const SlideImage = styled.img<StartTypes>`
  height: 101%;
  width: 100%;
  object-fit: cover;
  transform: scale(1.2);
`
