import { Canvas } from "@react-three/fiber"
import { Suspense } from "react"
import { TextureLoader } from "three"
import { BackSide } from "three"
import Camera360Controls from "./360CameraControls"

const View360 = ({ material }) => {
  const materials = {
    Pool: "/models/Amenities/360_Pool.webp",
    BBQ: "/models/Amenities/360_BBQ.webp",
    Entrance: "/models/Amenities/360_Entrance.webp",
    Lobby: "/models/Amenities/360_Lobby_lounge.webp",
  }

  const materialToLoad =
    materials[material] || "/models/Amenities/360_Pool.webp"
  const map = new TextureLoader().load(materialToLoad)

  return (
    <Suspense fallback={null}>
      <Canvas dpr={2}>
        <Camera360Controls />
        <mesh scale={[-1, 1, 1]}>
          <sphereGeometry args={[500, 60, 40]} />
          <meshBasicMaterial map={map} side={BackSide} toneMapped={false} />
        </mesh>
      </Canvas>
    </Suspense>
  )
}

export default View360
