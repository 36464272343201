import { extend, useThree } from "@react-three/fiber"
import { OrbitControls, PerspectiveCamera } from "@react-three/drei"
import React from "react"
import { useCameraSockets } from "@lib/useCameraSockets"

extend({ OrbitControls })

function Camera360Controls() {
  return (
    <React.Fragment>
      <SocketCamera />
    </React.Fragment>
  )
}

const SocketCamera = () => {
  const {
    camera,
    gl: { domElement },
  } = useThree()

  const { camRef, isIpad } = useCameraSockets("360")

  return (
    <>
      {isIpad ? (
        <OrbitControls
          args={[camera, domElement]}
          enableZoom={false}
          autoRotate={false}
          rotateSpeed={-0.3}
        />
      ) : (
        <PerspectiveCamera makeDefault ref={camRef} />
      )}
    </>
  )
}

export default Camera360Controls
