import { a } from "@react-spring/three"
import * as THREE from "three"
//@ts-ignore
import vertex from "./glsl/vertex.glsl"
//@ts-ignore
import fragment from "./glsl/fragment.glsl"
import { extend } from "@react-three/fiber"
import { DoubleSide, Texture } from "three"

import shaderMaterial from "@lib/dreiShaderMaterial"
// import { shaderMaterial } from "../../lib/dreiShaderMaterial"

type Props = {
  colors: any
  opacity: any
  activeID: number
  // unitsTextures: any
}

const UnitsShader = shaderMaterial(
  {
    uActiveColor: new THREE.Color(),
    uActiveId: null,
    uInActiveColor: new THREE.Color(),
    uOpacity: 0,
  },
  vertex,
  fragment,
  () => void 0
)

extend({ UnitsShader })

//@ts-ignore
const AnimatedUnitsShader = a("unitsShader")

const UnitsMaterial = ({ colors, opacity, activeID }: Props) => {
  return (
    // @ts-ignore
    <AnimatedUnitsShader
      transparent
      uActiveId={activeID}
      uActiveColor={colors.active}
      uInActiveColor={colors.inActive}
      uOpacity={opacity}
      side={DoubleSide}
    />
  )
}

export default UnitsMaterial
