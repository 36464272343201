import { useFrame, useThree } from "@react-three/fiber"
import { useDeviceStore } from "@state/deviceStore"
import useSocketStore from "@state/useSocketStore"
import React, { useEffect } from "react"

export const useCameraSockets = (id) => {
  const camRef = React.useRef(null)
  const isIpad = useDeviceStore((state) => state.isIpad)
  const socket = useSocketStore((state) => state.socket)
  const setCameraPos = useSocketStore((state) => state.setCameraPos)

  const camera = useThree((state) => state.camera)

  useEffect(() => {
    if (socket) {
      if (!isIpad) {
        const handler = ({ data }) => {
          if (camRef.current && data && data.rotation) {
            camRef.current.rotation.copy(data.rotation)
            camRef.current.position.copy(data.position)
          }
        }

        socket.on(`got-cameraPos${id}`, handler)

        return () => {
          socket.off("got-cameraPos", handler)
        }
      }
    }
  }, [camRef, isIpad, socket])

  useFrame(() => {
    if (isIpad) {
      setCameraPos({
        rotation: camera.rotation,
        position: camera.position,
        id: id,
      })
    }
  })

  return { camRef: camRef, isIpad: isIpad }
}
