import styled from "styled-components"

import useSocketStore from "@state/useSocketStore"

function Views({ src }) {
  const residencePopups = useSocketStore((state) => state.residencePopups)

  return (
    <Wrapper
      style={{
        opacity: residencePopups.residenceContainerOpen  ? 1 : 0,
      }}
    >
      <img src={src} alt="" />
    </Wrapper>
  )
}

export default Views

const Wrapper = styled.div`
  position: absolute;
  z-index: 13;
  width: 100%;
  height: 100%;
  top: 0;
  transition: opacity 1s ease-out;
  transition-delay: .5s;

  & img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`
